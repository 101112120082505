const defaultState = {
  routing: {},
  errors: {
    hasFatalError: false,
  },
  user: {
    profile: {
      name: {},
    },
  },
  notifications: { list: [] },
};

export default defaultState;
