/* global window */
import { createStore, compose, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootReducer from '../rootReducer';
import defaultState from './defaultState';
import rootSaga from '../rootSaga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  defaultState,
  // enhancers
  compose(
    applyMiddleware(thunk, sagaMiddleware, createLogger()),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);

if (module.hot) {
  module.hot.accept('../rootReducer', () => {
    const nextRootReducer = require('../rootReducer').default;
    store.replaceReducer(nextRootReducer);
  });
}

sagaMiddleware.run(rootSaga);

export default store;
