import React from 'react';
import { Switch, Route, Redirect, Location, matchPath } from 'react-router-dom';
import gql from 'graphql-tag';
import { css } from 'react-emotion';
import TransactionManagement from './Management';
import GridView from './GridView/GridView';
import Returns from './Returns';

type Props = {
  sodUser: object,
};

const Transactions = ({ sodUser }: Props) => {
  return (
    <Switch>
      <Route exact path="/transactions">
        <GridView />
      </Route>
      <Route
        exact
        path="/transactions/:creditHolderId/:creditHolderType/:purchaseId/returns"
        render={routerProps => <Returns styles={styles} {...routerProps} />}
      />
      <Route
        exact
        path="/transactions/:creditHolderId/:creditHolderType"
        render={routerProps => (
          <TransactionManagement styles={styles} {...routerProps} />
        )}
      ></Route>
      {/* If path incomplete, redirect to transactions. This cleans up URL,
      versus default Switch case to transactions. */}
      <Redirect to="/transactions" />
    </Switch>
  );
};

const styles = {
  subAppContainer: css`
    display: flex;
    background-color: white;
    padding: 4rem;
  `,
  subAppContainerReceiptVariant: css`
    background-color: white;
    padding: 2rem 4rem 4rem 4rem;
  `,
  subAppContainerDetailsVariant: css`
    background-color: white;
    padding: 4rem 4rem 4rem 4rem;
  `,
  subAppHeaderWithBreadcrumb: css`
    padding: 1.825rem 0 2.5rem 0;
  `,
  subAppHeaderWrapperWithPrintButton: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  reportButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
    padding-top: 2rem;
  `,
  paperMargin: css`
    margin-bottom: 2rem;
  `,
  saveWrapper: css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3rem;
  `,
  cancel: css`
    margin-right: 3rem;
    cursor: pointer;
  `,
};

export default Transactions;
