import React from 'react'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { DatePicker as MaterialDatePicker } from 'material-ui-pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import CalendarToday from '@material-ui/icons/CalendarToday'
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core'

const { prussian, slate, leaf, jungle, dragon } = colors

const getMaxSelectableDate = () =>
  new Date(new Date().setFullYear(new Date().getFullYear() + 1))

const materialTheme = createMuiTheme({
  overrides: {
    // @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: prussian,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      },
    },
    MuiPickersDay: {
      day: {
        color: prussian,
      },
      isSelected: {
        backgroundColor: jungle,
        '&:hover': {
          backgroundColor: leaf,
        },
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: jungle,
        textTransform: 'capitalize',
      },
      dialog: {
        minHeight: '200px',
      },
    },
    // Needed to override default red
    MuiFormHelperText: {
      error: { color: `${dragon} !important` },
    },
  },
})

export const DatePicker = ({
  label,
  name,
  value,
  onChange,
  classes,
  minDate,
  maxDate,
  error,
}) => {
  return (
    <MuiThemeProvider theme={materialTheme}>
      <MaterialDatePicker
        keyboard={false}
        fullWidth
        disablePast
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        okLabel="Select"
        minDate={minDate || undefined}
        maxDate={maxDate === null ? getMaxSelectableDate() : maxDate}
        error={error} // Error message handled in parent component        
        helperText={ !value ? 'You missed this field.' : null} // Clear Mui Pickers default messages
        format="MMM dd, yyyy"
        InputProps={{
          classes: {
            underline: classes.underline,
            error: classes.error,
          },
          endAdornment: (
            <InputAdornment position="end">
              <CalendarToday style={{ color: slate }} />
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          style: { color: slate, fontFamily: 'Proxima Nova' },
        }}
      />
    </MuiThemeProvider>
  )
}

const styles = {
  underline: {
    '&:after': {
      borderBottomColor: prussian,
    },
  },
  error: {
    '&$error:after': {
      borderBottomColor: dragon,
    },
  },
}

export default withStyles(styles)(DatePicker)
