import React from 'react';
import { Grid, Paper, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles/index';

import GoogleIcon from '../assets/GoogleLogo';
import PeachjarLogo from '../assets/PeachjarLogo';
import { styles } from './login.css.js';

import config from '../config';

const LoginCard = ({ classes }) => (
  <Paper className={classes.notLoggedInPaper}>
    <Grid item xs={12} className={classes.item}>
      <div style={{ width: 245 }}>
        <PeachjarLogo />
        <p className={classes.portalSubtitle}>Internal Portal</p>
      </div>
    </Grid>
    <Divider />
    <Grid item xs={12} className={classes.item}>
      <h5>Login with your Peachjar account</h5>
    </Grid>
    <Grid item xs={12} className={classes.buttonContainer}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        href={config.REACT_APP_GOOGLE_AUTH_URL}
      >
        <GoogleIcon />
        Sign in with Google
      </Button>
    </Grid>
  </Paper>
);

export default withStyles(styles)(LoginCard);
