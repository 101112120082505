import React, { useContext } from 'react';
import idx from 'idx'
import SortableTable from "@peachjar/ui/dist/lib/components/SortableTable/SortableTable2"
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { components } from '@peachjar/components'
import config from '../../../config'
import { GlobalSearchContext } from '../../globalSearchContext';
const {
  Buttons: { ButtonSecondarySmall },
} = components

const {  
  REACT_APP_API_BASE_URL
} = config

const TableDistrictsOrSchools = () => {
  const {
    results,
    classes,
    history
  } = useContext(GlobalSearchContext);


  const items =  results.map(result => {    
    const { location : { state  }} = result;    
    return {
      ...result,
      state,
    }
  })

  return (
    <SortableTable 
    columnCount={5}
    isFiltered={false}
    items={items}
    initialSort={{field: 'name', direction: 'asc'}}      
    thead={({ asSortableLabel }) => {
      return (
        <TableRow>
            <TableCell>{asSortableLabel('ID', 'id')}</TableCell>
            <TableCell>{asSortableLabel('Type', 'groupType')}</TableCell>
            <TableCell>{asSortableLabel('State', 'state')}</TableCell>
            <TableCell>{asSortableLabel('Name', 'name')}</TableCell>                                   
            <TableCell>&nbsp;</TableCell>
        </TableRow>
      )
    }}
    tbody={(item) => {
      const uid = idx(item, (_) => _.id) || ''
      const districtId = idx(item, (_) => _.districtId) || ''
      const schoolId = idx(item, (_) => _.schoolId) || ''
      const groupType = idx(item, (_) => _.groupType) || ''
      const parentId = idx(item, (_) => _.parentId) || ''
      const parentType = idx(item, (_) => _.parentType) || ''
      const parentName = idx(item, (_) => _.parentName) || ''            
      const state = idx(item, (_) => _.state) || ''
      const name = idx(item, (_) => _.name) || ''      
      const id = schoolId || districtId;           
      
      return (
      <TableRow className={classes.row} key={uid}>
        <TableCell className={classes.cell}>{id}</TableCell>
        <TableCell className={classes.cell}><span className={classes.capitalizeFirstCharacter}>{groupType}</span></TableCell>
        <TableCell className={classes.cell}>{state}</TableCell>
        <TableCell className={classes.cell}>{name} <span className={classes.districtName}>{parentId && parentName && `(${parentName} #${parentId})`}</span></TableCell>                       
        <TableCell className={classes.cellRight}>
          <ButtonSecondarySmall
            onClick={() => {   
              let urlToOpen;           
              if(groupType === 'district'){
                urlToOpen = `${REACT_APP_API_BASE_URL}/mgmt/district/${districtId}`;
              }
              else {
                if(parentId){
                  urlToOpen = `${REACT_APP_API_BASE_URL}/mgmt/district/${parentId}/school/${schoolId}`;
                }
                else {
                  urlToOpen = `${REACT_APP_API_BASE_URL}/mgmt/school/${schoolId}`;  
                }                
              }
              window.open(urlToOpen, '_blank')
            }}
          >
            <span className={classes.capitalizeFirstCharacter}>{`Manage ${groupType}`}</span>
          </ButtonSecondarySmall>
        </TableCell>
      </TableRow>
      )
    }}
    />      
  )
}

export default TableDistrictsOrSchools;