import NonStopFlyersPage from './index';
import generateSubappConfig from '../App/generateSubappConfig';
import ROUTES from '../routes.config';

const nonStopFlyersAppConfig = generateSubappConfig({
  path: ROUTES.nonStopFlyers,
  isLive: true,
  navItemConfig: {
    label: 'Non Stop Flyers',
  },
  appConfig: {
    Component: NonStopFlyersPage,
  },
});

export default nonStopFlyersAppConfig;
