import Modal from '@peachjar/ui/dist/lib/components/Modal/Modal'
import ModalHeader from '@peachjar/ui/dist/lib/components/Modal/ModalHeader'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import Typo from '@peachjar/ui/dist/lib/typography/TypographyRedux'
import { css } from 'emotion'
import React, { Fragment, useState } from 'react'
import { Flyer } from '../../api/Flyer'
import AgeIcon from '../../assets/AgeIcon'
import GradeIcon from '../../assets/GradeIcon'
import LocationOn from '../../assets/LocationOn'
import FlyerCategoryTag from './FlyerCategoryTag'
import Pagination from './Pagination'
import getValidHref from '../../utils/getValidHref'
import InteractiveFlyer from './InteractiveFlyer'
import InteractiveFlyerPrimaryCTA from './InteractiveFlyerPrimaryCTA'
import { LINK_ICONS } from './FeaturedLinks'

type Props = {
  flyer: Flyer
  open: boolean
  onClose: () => void
}

export default function InteractiveFlyerModal({
  flyer,
  open,
  onClose
}: Props) {
  const isOrgUser = false
  const categorySet: Flyer['ownerHierarchyType'] = isOrgUser ? 'organization' : 'district'
  const featuredLinks: any[] = []
  const pageCount = flyer.flyerPages.length
  const [currentPageNumber, setActivePage] = useState(1)
  const activePage = flyer.flyerPages[currentPageNumber - 1]

  // const range = useMemo(() => {
  //   if (isOrgUser) {
  //     if (campaign.minTargetAge !== null
  //       && campaign.minTargetAge !== ""
  //       && (campaign.minTargetAge === campaign.maxTargetAge)) {
  //       return `Age ${campaign.minTargetAge}`
  //     }
  //     return `Age ${campaign.minTargetAge || ages[0]} - ${campaign.maxTargetAge || ages[ages.length - 1]}`
  //   }

  //   if (campaign.minTargetGrade !== null
  //     && campaign.minTargetGrade !== ""
  //     && (campaign.minTargetGrade === campaign.maxTargetGrade)) {
  //     return `Grade ${campaign.minTargetGrade}`
  //   }
  //   return `Grade ${campaign.minTargetGrade || grades[0].label} - ${campaign.maxTargetGrade || grades[grades.length - 1].label}`
  // }, [campaign.maxTargetAge, campaign.maxTargetGrade, campaign.minTargetAge, campaign.minTargetGrade, isOrgUser])

  const [highlightedLink, highlightLink] = useState<string>()

  return (
    <Modal
      open={open}
      close={onClose}
      width="md"
    >
      <ModalHeader>
        Preview
      </ModalHeader>
      <div className={styles.container}>
        <Typo.DarkMuted.Paragraph translate='' style={{ marginBlockEnd: '24px' }}>
          Hover over flyer to preview links. Be sure to test each link.
        </Typo.DarkMuted.Paragraph>
        <div className={styles.body}>
          <div className={styles.flyerColumn}>
            <div className={styles.flyerFrame}>
              <InteractiveFlyer
                flyer={flyer}
                key={`page${currentPageNumber}`}
                pageNumber={currentPageNumber}
                flyerImage={activePage.jpegUrl}
                highlightedLink={highlightedLink}
                highlightLink={highlightLink}
              />
            </div>
            <Pagination
              activePageNumber={currentPageNumber}
              pageCount={pageCount}
              onPageChange={setActivePage}
            />
          </div>
          <div className={styles.detailsColumn}>
            <div className={styles.detailsContainer}>
              <Typo.DarkBold.Paragraph translate=''>
                {flyer.title}
              </Typo.DarkBold.Paragraph>
              {/* <div className={styles.categories}>
                {flyer.categories?.map(category => (
                  <FlyerCategoryTag key={category} set={categorySet} code={category} />
                ))}
              </div> */}
              <div className={styles.links}>
                {flyer.eventLocation && flyer.eventLocation.address.length > 0 && <>
                  <LocationOn />
                  <Typo.Dark.Paragraph translate=''>
                    {flyer.eventLocation}
                  </Typo.Dark.Paragraph>
                </>}
                {isOrgUser ? <AgeIcon /> : <GradeIcon />}
                {/* <Typo.Dark.Paragraph translate=''>{range}</Typo.Dark.Paragraph> */}
                {featuredLinks.map(link => {
                  const Icon = LINK_ICONS[link.type] || LINK_ICONS['learn-more']
                  return <Fragment key={link.id}>
                    <Icon
                      width="24"
                      height="24"
                    />
                    <Typo.DarkLink.Paragraph
                      translate=''
                      data-testid={`flyer-link-${link.id}`}
                      data-type={link.type}
                      href={getValidHref(link.value, link.type)}
                      onMouseEnter={() => highlightLink(link.id)}
                      onMouseLeave={() => highlightLink(undefined)}
                      rel="noopener noreferrer"
                      target={link.type === "Email" || link.type === "Call" ? "_self" : "_blank"}
                    >
                      {link.value}
                    </Typo.DarkLink.Paragraph>
                  </Fragment>
                })}
              </div>
            </div>
            {featuredLinks.length > 0 && (
              <div style={{ marginBottom: '32px' }}>
                <InteractiveFlyerPrimaryCTA cta={featuredLinks[0]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal >
  )
}

const styles = {
  container: css`
    padding: 32px 40px;
  `,
  body: css`
    display: flex;
    gap: 24px;
  `,
  detailsColumn: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  detailsContainer: css`
    flex-grow: 1;
    padding: 16px;
  `,
  links: css`
    display: grid;
    grid-template-columns: 24px 1fr;
    padding-block: 16px;
    gap: 16px;
  `,
  categories: css`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  `,
  flyerColumn: css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 540px;
    align-items: center;
  `,
  flyerFrame: css`
    outline: 1px dashed ${colors.stone};
    outline-offset: 2px;
    width: 100%;
  `,
}