import React, { useState } from 'react'
import idx from 'idx'
import { css } from 'emotion'
import { components, colors } from '@peachjar/components'
import { withRouter } from 'react-router-dom'
import { Grid, withStyles } from '@material-ui/core'
import GlobalSearchContainer from './components/GlobalSearchContainer';
import noResultsSVG from '../assets/turtle.svg'
import SearchSvg from '../Search/components/SearchSvg'
import SubappHeader from '../App/components/SubappHeader'
import { GlobalSearchContext } from './globalSearchContext.js'
import { SEARCH_TYPES } from './utils/search-types';
import { 
  TableDistrictsOrSchools, 
  TableFlyers, 
  TableOrganizations, 
  TableUsers
} from './components/Tables';

const {
  LoadingIndicators: { SpinnerLarge },
} = components

const styles = (theme) => ({
  pageContainer: {
    padding: '32px 16px',
    '& .MuiTable-root': {
      tableLayout: 'auto',
      '& .MuiTableRow-root': {        
        '&:nth-of-type(odd)': {
          backgroundColor: "#FFF",
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '& .MuiTableCell-root': {
          paddingTop: '8px',
          paddingBottom: '8px',
        }        
      }
    },
    
  },
  page: {
    background: 'white',
    width: '100%',
    minHeight: 500,
    marginTop: 0,    
    padding: `${theme.spacing(2)}px ${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(6)}px`,    
  },
  row: {
    backgroundColor: colors.platinum,
  },
  cell: {
    textAlign: 'left',
  },
  capitalizeFirstCharacter: {
    textTransform: 'capitalize'
  },
  districtName: {
    color: colors.slate
  },
  cellEmail: {
    width: 'auto',
  },
  cellRight: {
    textAlign: 'right',
  },
  table: {
    borderRadius: '6px',
    border: `1px solid ${colors.silver}`,    
  },
  spinnerContainer: {
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
})

const DEFAULT_PLACEHOLDER = 'Select a type to start your search';

const GlobalSearchPage = ({ classes, history }) => {
  const [results, setResults] = useState([])
  const [hasSearched, setHasSearched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputType, setInputType] = useState(null);
  const [placeholder, setPlaceholder] = useState(DEFAULT_PLACEHOLDER);  

  const onSearch = (results, loading) => {
    
    const key = Object.keys(results)[0];
    setResults(idx(results[key], (_) => _.items) || null);    
    setIsLoading(loading)    
    setHasSearched(true)
  }

  const renderTable = () => {        
    switch(inputType.description) {
      case SEARCH_TYPES.districtOrSchool: 
        return <TableDistrictsOrSchools/>;
      break;
      case SEARCH_TYPES.organization:
        return <TableOrganizations/>
      break;
      case SEARCH_TYPES.flyer: 
       return <TableFlyers/>
      break;
      case SEARCH_TYPES.user: 
       return <TableUsers/>
      break;      
    }  
  }

  const hasResults =
    results && Array.isArray(results) && results.length >= 1    
  
  const contextState = {
    history,
    classes,
    DEFAULT_PLACEHOLDER,
    inputType,
    setInputType,
    placeholder, 
    setPlaceholder,
    onSearch,
    setIsLoading,  
    setResults,  
    results,
    setHasSearched,
    hasSearched
  };
  
  return (
    <GlobalSearchContext.Provider value={contextState}>
      <Grid container className={classes.pageContainer}>
        <SubappHeader>Search</SubappHeader>
        <div className={classes.page}>
          <div className={cn.searchContainer}>
            <GlobalSearchContainer />
          </div>          
          {!hasResults && hasSearched && !isLoading && (
            <div className={cn.noResults}>
              <img src={noResultsSVG} alt="" />
              <p className={cn.noResultsTxt}>
                Uh oh! We can't find the {inputType.description.toLowerCase()} you are looking for. Please try
                again.
              </p>
            </div>
          )}
          {isLoading && (
            <div className={classes.spinnerContainer}>
              <SpinnerLarge />
            </div>
          )}
          {!hasResults && !hasSearched && !isLoading && (
            <div className={cn.illustration}>              
              <SearchSvg />                            
            </div>
          )}          
          {hasResults && !isLoading && renderTable()}
        </div>
      </Grid>
    </GlobalSearchContext.Provider>
  )
}

const cn = {
  headline: css`
    font-size: 19px;
  `,    
  noResults: css`
    text-align: center;
    padding-bottom: 60px;
  `,
  noResultsTxt: css`
    font-size: 16px;
    padding: 20px 0;
    margin: 0 auto;
    width: 370px;
  `,
  searchContainer: css`
    margin-bottom: 40px;
  `,
  illustration: css`
    text-align: center;    
  `,
}

export default withStyles(styles)(withRouter(GlobalSearchPage))
