import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import {
  StylesProvider,
  createGenerateClassName,
  MuiThemeProvider,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import store from '../_middleware/store/store';
import apolloClient from '../_middleware/fetch/apolloClient';
import contentTheme from './app.content.css.theme';
import App from './App';

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: 'internal',
});

const AppWrapper = () => (
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <StylesProvider generateClassName={generateClassName}>
        <MuiThemeProvider theme={contentTheme}>
          <BrowserRouter>
            <div>
              <CssBaseline />
              <App />
            </div>
          </BrowserRouter>
        </MuiThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  </Provider>
);

export default AppWrapper;
