import React from 'react';
import { css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import InfoPopover from '../../shared/components/InfoPopover';

const { jungle } = colors;
const { Note, Paragraph } = elements.typography;

const styles = {
  dealLink: css`
    display: flex;
    justify-content: start;
    align-items: center;
    color: ${jungle};

    &:hover, &:hover > * {
      color: #C5E8C9;
      cursor: pointer;
    }
  `,
  dealIcon: css`
    font-size: 13px;
  `,
  dealPopoverContent: css`
    padding: 16px 25px;
  `,
};

const DealLink = () => (
  <div className={`ml-2 ${styles.dealLink}`}>
    <LocalOfferIcon className="mr-1" fontSize="inherit" />
    <Note className={styles.dealLink}>Deal</Note>
  </div>
);

const DealPopoverContent = ({ deals }) => (
  <div className={styles.dealPopoverContent}>
    <div><Note muted>Deal(s):</Note></div>
    <>
      {deals.map(deal => (
        <div>
          <Note muted>&bull; </Note>
          <Note>{deal.name}</Note>
          <Note muted>({deal.description} {deal.creditsSaved})</Note>
        </div>
      ))}
    </>
  </div>
);

const DealTooltip = ({ deals }) => {
  return (
    <InfoPopover content={<DealLink />} popoverContent={<DealPopoverContent deals={deals} />} />
  );
};

export default DealTooltip;
