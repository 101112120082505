import React, { useContext, useEffect } from 'react';
import { css } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import idx from 'idx'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import SortableTable from "@peachjar/ui/dist/lib/components/SortableTable/SortableTable2"
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { components } from '@peachjar/components'
import config from '../../../config'
import { GlobalSearchContext } from '../../globalSearchContext';
import { setUserMasquerading } from '../../../Login/user.duck';
const {
  Buttons: { ButtonSecondarySmall },
  Notifications: { notifySuccess, notifyError },
} = components

const {
  REACT_APP_AUTH_URL,
  REACT_PORTAL_DASHBOARD_URL,
  REACT_APP_ACCOUNTS_APP_URL,
} = config

const IS_MASKED_KEY = 'pjim'

export const  renderUserTypeCell = (role) => {
  let newUserType;
  switch(role) {
    case 'org-member':
    case 'org_member':
      newUserType = 'Org Member';
    break;
    case 'school':
      newUserType = 'School Staff';
    break;
    case 'district':
      newUserType = 'District Staff';
    break;
    case 'guardian':
    case 'parent':
      newUserType = 'Parent';
    break;
    case 'volunteer':
      newUserType = 'Volunteer';
    break;
  }
  return newUserType;
}

const TableUsers = ({ masquerading, onSuccess, onError, onSetUserMasquerading }) => {  
  const {
    results,
    classes    
  } = useContext(GlobalSearchContext);

  useEffect(()=>{
    if(masquerading === null && localStorage.getItem('pjimuid') !== null){      
      localStorage.setItem(IS_MASKED_KEY, 'false')
      localStorage.removeItem('pjimuid')
    }
  }, [masquerading])


  const userMasqueradingId = masquerading && masquerading.legacyId || null;  

  const masqueradeAsUser = async (uuid, user_type, email, user_id) => {
    const identityString = `${email} - ${user_id} (${user_type})`
    const response = await fetch(`${REACT_APP_AUTH_URL}/masquerade`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        subject: '' + uuid,
      }),
      credentials: 'include',
    })

    const json = await response.json()

    const uid = idx(json, (_) => _.uid) || null
    const isMasked = idx(json, (_) => _.isMasquerading) || false

    if (uid && isMasked) {
      localStorage.setItem(IS_MASKED_KEY, 'true')
      localStorage.setItem('pjimuid', identityString)
      const identifiers = idx(json, (_) => _.identifiers);
      const legacyId = idx(json, (_) => _.legacyId);
      const metadata = idx(json, (_) => _.metadata);
      const masquerading = {
        ...identifiers,
        legacyId,
        hierarchyType: metadata.hierarchyType,
      }
      onSetUserMasquerading(masquerading);
      let urlToOpen = REACT_PORTAL_DASHBOARD_URL
      if (user_type === 'family' || user_type === 'parent') {
        urlToOpen = `${REACT_APP_ACCOUNTS_APP_URL}/parent/account-information`
      }

      window.open(urlToOpen, '_blank')      
    }
  }

  const unmaskMe = async () => {
    const response = await fetch(`${REACT_APP_AUTH_URL}/stop-masquerading`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
    })

    const json = await response.json()

    if (json.isMasquerading === false) {
      localStorage.setItem(IS_MASKED_KEY, 'false')
      localStorage.removeItem('pjimuid')
      onSetUserMasquerading(null);
      onSuccess()      
    } else {
      onError()
    }
  }
 

  return (
    <SortableTable 
    columnCount={6}
    isFiltered={false}
    items={results}
    thead={({ asSortableLabel }) => {
      return (
        <TableRow>
            <TableCell>{asSortableLabel('ID', 'userId')}</TableCell>
            <TableCell>{asSortableLabel('First Name', 'firstName')}</TableCell>
            <TableCell>{asSortableLabel('Last Name', 'lastName')}</TableCell>
            <TableCell>{asSortableLabel('Email Address', 'email')}</TableCell>              
            <TableCell>{asSortableLabel('User Type', 'hierarchyType')}</TableCell>              
            <TableCell>&nbsp;</TableCell>
        </TableRow>
      )
    }}
    tbody={(item) => {
      const firstName = idx(item, (_) => _.firstName) || ''
      const lastName = idx(item, (_) => _.lastName) || ''
      const userId = idx(item, (_) => _.userId) || ''
      const email = idx(item, (_) => _.email) || ''
      const hierarchyType = idx(item, (_) => _.hierarchyType) || ''        
      const role = idx(item, (_) => _.role) || ''
      const uid = idx(item, (_) => _.uid) || null
      const isOldEmail = idx(item, (_) => _.isOldEmail) || false
            
      return (
      <TableRow className={classes.row} key={uid}>
        <TableCell className={classes.cell}>{userId}</TableCell>
        <TableCell className={classes.cell}>{firstName}</TableCell>
        <TableCell className={classes.cell}>{lastName}</TableCell>
        <TableCell className={classes.cell}>{email} {isOldEmail ? <span className={cn.oldEmail}>(old)</span> : null}</TableCell>
        <TableCell className={classes.cell}>{renderUserTypeCell(role)}</TableCell>
        <TableCell className={classes.cellRight}>
          { isOldEmail ? null :
            <ButtonSecondarySmall
              disabled={userMasqueradingId !== null && userMasqueradingId !== userId}
              onClick={async() =>
                userMasqueradingId === userId ?
                await unmaskMe() :
                await masqueradeAsUser(uid, hierarchyType, email, userId)
              }
            >
              { userMasqueradingId === userId ? 'Stop Masquerading' : 'Masquerade'}
            </ButtonSecondarySmall>
          }
        </TableCell>
      </TableRow>
      )
    }}
    />      
  )
}

const cn = {
  oldEmail: css`
    color: ${colors.slate};
  `
}

const mapStateToProps = (state) => ({    
  masquerading: state.user.masquerading
});

const mapDispatchToProps = (dispatch) => ({
  onSuccess: () => dispatch(notifySuccess('You are no longer masquerading.')),
  onError: () =>
    dispatch(notifyError('Something went wrong. Please try again.')),
  onSetUserMasquerading: (user) => dispatch(setUserMasquerading(user))    
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableUsers))
