import * as React from 'react';
import { css } from 'emotion';
import { components } from '@peachjar/components';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';

import Sidebar from './components/Sidebar';
import { apps, sidebarSections } from './appConfig';
import Logout from '../Logout';
import AuthMe from './components/AuthMe';
import Brandbar from './components/Brandbar';

const { SubappContainer } = components;
const { NotificationContainer } = components.Notifications;

const App = () => (
  <AuthMe>
    <div className={classNames.app}>
      <NotificationContainer />
      <Brandbar />
      <div className={classNames.contentWrapper}>
        <Sidebar sections={sidebarSections} />
        <div className={classNames.columnContainer}>
          <SubappContainer className={classNames.subappContainer}>
            <Switch>
              {/*
                ** TODO: permissions/roles **
                // if fragmentOnUser NOT provided
                    // render Route using path and Component
                // if fragmentOnUser provided
                    // render Route using path and Component, but with fragment passed as "user" prop to Component
                    // NOTE: must also handle putting fragment in query somewhere else
                    // NOTE: should use "filter" if not built in to Apollo yet
              */}
              {apps.map(({ path, Component }) => (
                <Route key={path} path={path} component={Component} />
              ))}
              <Redirect exact from="/" to="/search" />
              <Route path="/logout" component={Logout} />
              <Route component={() => 'ErrorPage'} />
            </Switch>
          </SubappContainer>
        </div>
      </div>
    </div>
  </AuthMe>
);

const classNames = {
  app: css`
    height: 100vh;
    overflow: hidden;
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
  `,
  columnContainer: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;
  `,
  contentWrapper: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    overflow: auto;
  `,
  subappContainer: css`
    & > div {
      max-width: 1400px;
      width: 100%
    }
  `,
};

export default withRouter(App);
