import React from 'react';

export default function SearchSvg(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      style={{ enableBackground: 'new 0 0 500 500' }}
      xmlSpace="preserve"
      width={500}
      height={500}
      {...props}
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n\t.st0{fill:#DFE1E7;}\n\t.st1{fill:#F0F2F6;}\n\t.st2{fill:#9EA3B7;}\n\t.st3{fill:#FFEFC8;}\n\t.st4{fill:#FCC35D;}\n\t.st5{fill:#FCFCFD;}\n\t.st6{fill:#F36E21;}\n\t.st7{fill:#47506B;}\n\t.st8{fill:#757D99;}\n\t.st9{opacity:0.5;fill:#FFFFFF;enable-background:new    ;}\n\t.st10{fill:#FAA918;}\n\t.st11{fill:#2D344B;}\n\t.st12{fill:#FFFFFF;}\n\t.st13{fill:none;stroke:#47506B;stroke-width:11;stroke-miterlimit:10;}\n\t\n\t\t.st14{opacity:0.46;fill:none;stroke:#757D99;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;enable-background:new    ;}\n\t.st15{opacity:0.2;clip-path:url(#SVGID_4_);fill:#757D99;enable-background:new    ;}\n\t.st16{opacity:0.17;clip-path:url(#SVGID_6_);fill:#47506B;enable-background:new    ;}\n\t.st17{fill:none;stroke:#47506B;stroke-width:2;stroke-miterlimit:10;}\n\t.st18{fill:#FFE6AA;}\n\t.st19{fill:#FFCDAB;}\n',
        }}
      />
      <path
        id="Fill-1"
        className="st0"
        d="M458.1,342.1c14.3,11.1-77.3,2.2-200.3,15.4c-120,12.8-209.9,1.3-218.2-16.5
      	c-3.8-8.1,94.7-17,218.2-16.5C383.1,325.3,417.2,310.6,458.1,342.1"
      />
      <path
        id="Fill-3"
        className="st1"
        d="M372.7,338.6H115.3c-7.5,0-13.7-6.1-13.7-13.7V120.3c0-2,1.7-3.6,3.6-3.6h276
      	c2.8,0,5.1,2.3,5.1,5.1V325C386.4,332.5,380.3,338.6,372.7,338.6"
      />
      <path
        id="Fill-5"
        className="st2"
        d="M387.6,128.1h-286v-8.8c0-5.3,4.3-9.7,9.7-9.7h267c5.1,0,9.3,4.2,9.3,9.3L387.6,128.1L387.6,128.1z
      	"
      />
      <path
        id="Fill-7"
        className="st1"
        d="M115.9,119.4c0,1.4-1,2.4-2.2,2.4s-2.2-1.1-2.2-2.4s1-2.4,2.2-2.4S115.9,118.1,115.9,119.4"
      />
      <path
        id="Fill-9"
        className="st3"
        d="M123.7,119.4c0,1.4-1,2.4-2.2,2.4s-2.2-1.1-2.2-2.4s1-2.4,2.2-2.4S123.7,118.1,123.7,119.4"
      />
      <path
        id="Fill-11"
        className="st4"
        d="M131.4,119.4c0,1.4-1,2.4-2.2,2.4s-2.2-1.1-2.2-2.4s1-2.4,2.2-2.4S131.4,118.1,131.4,119.4"
      />
      <path
        id="Fill-19"
        className="st5"
        d="M261.7,204.7H128.1c-2.7,0-4.8-1.8-4.8-3.9v-0.7c0-2.1,2.1-3.9,4.8-3.9h133.6
      	c2.7,0,4.8,1.8,4.8,3.9v0.7C266.7,202.9,264.4,204.7,261.7,204.7"
      />
      <path
        id="Fill-23"
        className="st5"
        d="M325,217.3H128.5c-2.9,0-5.2-1.8-5.2-3.9v-0.7c0-2.1,2.3-3.9,5.2-3.9H325c2.9,0,5.2,1.8,5.2,3.9
      	v0.7C330.2,215.5,327.9,217.3,325,217.3"
      />
      <path
        id="Fill-25"
        className="st5"
        d="M290.8,230.7H129.2c-3.3,0-5.9-1.8-5.9-3.9v-0.7c0-2.1,2.6-3.9,5.9-3.9h161.6
      	c3.3,0,5.9,1.8,5.9,3.9v0.7C296.8,229,294.1,230.7,290.8,230.7"
      />
      <path
        id="Fill-27"
        className="st5"
        d="M230.5,243.6H127.1c-2.1,0-3.8-1.8-3.8-3.9V239c0-2.1,1.7-3.9,3.8-3.9h103.4
      	c2.1,0,3.8,1.8,3.8,3.9v0.7C234.3,241.8,232.6,243.6,230.5,243.6"
      />
      <path
        id="Fill-31"
        className="st5"
        d="M272.3,281H127.1c-2.1,0-3.8-1.8-3.8-3.9v-0.7c0-2.1,1.7-3.9,3.8-3.9h145.2c2.1,0,3.8,1.8,3.8,3.9
      	v0.7C276.2,279.3,274.5,281,272.3,281"
      />
      <path
        id="Fill-33"
        className="st5"
        d="M338.5,294.5H130.8c-4.2,0-7.5-1.8-7.5-3.9V290c0-2.1,3.3-3.9,7.5-3.9h207.7
      	c4.2,0,7.5,1.8,7.5,3.9v0.7C346.2,292.8,342.7,294.5,338.5,294.5"
      />
      <path
        id="Fill-35"
        className="st5"
        d="M317.8,309.3H130.1c-3.8,0-6.8-1.8-6.8-3.9v-0.7c0-2.1,3-3.9,6.8-3.9h187.7c3.8,0,6.8,1.8,6.8,3.9
      	v0.7C324.7,307.5,321.6,309.3,317.8,309.3"
      />
      <path
        className="st3"
        d="M180.7,382.9c0-0.5,0.2-0.9,0.3-1.3c1-2,16.8-23.8,17.8-27.4c1-3.7,1.1-12.9,1.1-12.9l12.2,2
      	c0,2.1-2,11.5-4.3,14.7c-2.4,3.2-24.4,24.4-25,25.5c-0.2,0.5-0.2,1.4-0.2,2.5c0,1.6,0,3.5-1,3.3
      	C180.5,388.9,180.3,385.2,180.7,382.9z"
      />
      <path
        className="st3"
        d="M231.4,340.4l5.4,49.8c0.4,0.3,0.9,0.6,1.7,1.2c1.7,1.2,3.4,2.6,2.8,3.1c-0.8,0.7-3.4-1-4.9-2.1
      	c-0.6-0.4-1.1-0.8-1.1-0.8l-16.1-49.1L231.4,340.4z"
      />
      <path
        className="st6"
        d="M180.6,383.6c0.2,1.2,0.6,2.8,1.3,3c0.4,0,0.6-0.1,0.8-0.2c0,1.6,0,3.5-1,3.3
      	C180.5,389.6,180.2,385.9,180.6,383.6z"
      />
      <path
        className="st2"
        d="M221.9,275.8c0,0-4,13.8-8.2,18.1c-4.1,4.2-8.9,3.4-13.9,0c-5.1-3.4-2.9-11.1-2.9-11.1s-1.8-5.4,2.1-10.9
      	c3.7-5.6,10.2-2.5,13.9-3.7s1.9-3.3,4.6-5.8c2.6-2.5,5.5-1.3,5.5-1.3L221.9,275.8z"
      />
      <path
        className="st3"
        d="M197.7,243c0,0-0.2,5.1,0.8,15s3.3,20,5.5,24.1s4.4,6.6,7.2,4.6c5.3-3.6,2.7-8.1,2.7-8.1s-5.8-5.1-8.6-13.7
      	s-5.9-21.7-5.9-21.7C199.3,241.9,197.7,243,197.7,243z"
      />
      <path
        className="st3"
        d="M241.1,265.1c4.3-5.8,8.5-17.7,8.5-17.7l1.3,0.4c0,0-1.6,11-5.2,19.8c-3.7,8.9-10.7,18.8-13.1,21.2
      	c-2.3,2.4-4.5,0.5-5.3-1.6c-1.2-2.7-1-5.3,0.2-7C229,278.1,236.9,271,241.1,265.1z"
      />
      <path
        className="st3"
        d="M217.4,269.4c-0.7,3.1,1.5,5.3,2,7c0.5,1.8-0.2,2.8-0.7,3.5s-3.9-0.3-3.9-0.3s2.9,10.1,5.7,9.9
      	c2.7-0.3,6-8.4,6-8.4s-3.3-0.2-3.8-0.7c-0.3-0.6-0.1-1.6,0.3-2.3c0.4-0.7,2.7,0.3,3.8-0.9c1.2-1.2,0.1-4.8,0-8.3
      	c0.1-3.5-1.8-4.2-3.4-4.3C220.6,264.4,218.2,266.4,217.4,269.4z"
      />
      <path
        className="st2"
        d="M216,267.8c-0.5,4.5,1.9,5.5,1.9,5.5s0.4-1.6,2.5-4.4s3.5-3.1,3.5-3.1s1,0.4,1.9,1.8s1,3.6,1,3.6s1.8-2.7,1-6.1
      	c-0.6-3.6-4.1-4-4.1-4C220.5,260.2,216.5,263.2,216,267.8z"
      />
      <path
        className="st7"
        d="M228.8,286.6c-1.8-3.3-0.7-7-0.7-7l-3.2,1.6c0,0-0.9,6.4-4.5,6.4s-4.2-7.4-4.2-7.4l-2.8-1.8
      	c0,0-0.6,4.1-2.7,6.3s-4.9,1.1-4.9,1.1s1.4,7.4,1.5,12.9s-1.3,12.9-1.3,12.9s6.4,4.4,12.1,4.2c5.7-0.2,8.4-3.7,8.4-3.7
      	s0.5-6.5,1.9-11.7c1.3-5.2,4.5-11.1,4.5-11.1S230.4,289.3,228.8,286.6z"
      />
      <path
        className="st8"
        d="M231,329.2c-1.2-8.4-4.4-17.9-4.4-17.9s-5.2,3.4-10.7,3.2c-5.5-0.2-9.6-4.6-9.6-4.6s-4.7,3.7-7.5,11.7
      	c-2.9,8-1.3,21.5-1.3,21.5s7.8,3.1,18.2,3.3c10.4,0.1,17.2-2.3,17.2-2.3S232.2,337.6,231,329.2z"
      />
      <path
        className="st3"
        d="M199.4,241.9c0,0-1.4-1.4-3.1-1.6c-1.7-0.3-1.5-4-2.5-3.7s-0.9,3,0.1,4.9s3.8,2.6,4.9,2
      	C199.8,243,199.4,241.9,199.4,241.9z"
      />
      <path
        className="st3"
        d="M249.3,247.9c0,0,0.8-1.7,2.4-2.4s0-4.1,1.1-4.1s2.1,2.6,1.7,4.7c-0.4,2.1-2.8,3.6-4.2,3.2
      	C249.3,249,249.3,247.9,249.3,247.9z"
      />
      <path
        className="st5"
        d="M358.9,166.6H130.1c-2.7,0-4.9-2.2-4.9-4.9v-9.8c0-2.7,2.2-4.9,4.9-4.9h228.7c2.7,0,4.9,2.2,4.9,4.9v9.8
      	C363.7,164.4,361.5,166.6,358.9,166.6z"
      />
      <path
        className="st9"
        d="M221.4,231.3c-32.4-22.4-40.5-66.7-18.2-99.1c9.9-14.4,24.2-24,39.8-28.2c-26.4-4.4-54.2,6.3-70.4,29.8
      	c-22.4,32.4-14.2,76.8,18.2,99.1c18,12.4,39.7,15.4,59.3,10.1C240.2,241.3,230.4,237.5,221.4,231.3z"
      />
      <g>
        <path
          className="st10"
          d="M383.7,385.9c0.2,1.4,8.9-0.2,9-0.9c0.1-0.8,0-3.4,0-3.4l-3.2-0.2C389.4,381.4,383.5,384.8,383.7,385.9z"
        />
        <path
          className="st10"
          d="M431.2,383.9c0,0-0.7,2.1,0,2.9c0.7,0.7,8.7,2,9.1,1.3s-6.5-4.5-6.5-4.5L431.2,383.9z"
        />
        <path
          className="st11"
          d="M394.2,301.5c0,0.5-5.3,80.8-5.3,80.8l4.1,0.5c0,0,11.9-65,15.8-64.8c4,0.1,22.3,66.9,22.3,66.9l3-0.4
        		L423,303.2l-17.3-5.6L394.2,301.5z"
        />
        <path
          id="SVGID_1_"
          className="st8"
          d="M373.5,276.5c-3.2-11.5-2.2-33.5-2.2-33.5l12,13.2c0,0-3.5,15.6-2.3,22s8.9,17.3,8.9,17.3
        		l-1.5,1.2C388.4,296.8,376.6,288.1,373.5,276.5z"
        />
        <path
          className="st8"
          d="M425.2,308.4l-20.8-5.6l-11.4,6.1c0,0,0.6-13.2-0.2-23.6c-0.9-10.4-3.3-32-1.4-33.9c0.6-0.6,3.7-1,7.8-1.1
        		c4.1-0.1,9.1,0,13.5,0.4c4.7,0.4,8.5,1.1,9.4,1.9C424.7,255.4,425.2,308.4,425.2,308.4z"
        />
        <path
          className="st12"
          d="M412.6,250.8c-1.1,1.3-2.9,3.9-4.2,8.6c-2.3,8-5.3,21.8-5.3,21.8s-2.9-16-3.3-22.6c-0.2-4,0.2-6.9,1.3-8.1
        		C405.2,250.3,408.2,250.4,412.6,250.8z"
        />
        <path
          className="st4"
          d="M401.9,233.6c-1.4,1.4-3.6,12.8-2.1,13.7s4.3-0.5,4.8,0.6c0.4,1.1-0.2,3.1-0.2,3.1s-1.4,1.3,0.4,1.8
        		s3.9-1.7,3.9-1.7s-0.5-3.4,0.3-5.7c0.7-2.4,2.2-4.7,2-7.7C410.7,234.7,405.8,229.7,401.9,233.6z"
        />
        <path
          className="st6"
          d="M399.1,234.1c0.4,1.7,9.2,1.1,9.2,1.1s-1.2,4.8-0.7,5c0.5,0.3,1.1-0.9,1.8,0.2c0.7,1.1-1.3,3.9-1.3,3.9
        		l0.7,1.2c0,0,2.2-1.7,3-5.1c0.8-3.2,0.7-3.9,0-5.2c-0.7-1.2-1.3-1.1-1.3-1.1s1.8-0.9-1.5-2.6C405.7,230.2,398.7,232.4,399.1,234.1z
        		"
        />
      </g>
      <path
        id="SVGID_2_"
        className="st8"
        d="M372.6,263.2c5.5-5.6,20.2-12.4,20.2-12.4l-1.4,11c0,0-12,3.3-15.5,6c-3.5,2.8-5.9,10.7-5.9,10.7
      	l-1.6-0.4C368.3,278.2,367,268.8,372.6,263.2z"
      />
      <circle className="st13" cx="232.8" cy="172.6" r="71.3" />
      <path
        className="st14"
        d="M249.4,242c-38.2,9.5-76.9-13.8-86.3-52c-9.5-38.2,13.8-76.9,52-86.3"
      />
      <rect
        x="295.7"
        y="203.9"
        transform="matrix(0.5506 -0.8348 0.8348 0.5506 -46.1192 353.7863)"
        className="st7"
        width="19.6"
        height="31.7"
      />
      <path
        className="st7"
        d="M420.3,315l-105.7-69.7c-4-2.6-5.1-8-2.5-12l8.6-13c2.6-4,8-5.1,12-2.5l105.7,69.7c4,2.6,5.1,8,2.5,12l-8.6,13
      	C429.7,316.5,424.3,317.6,420.3,315z"
      />
      <path
        className="st4"
        d="M388.4,294.4c0,0-0.3,1.6,0.3,2.5c0.7,0.9,6.2,4.9,7.3,3.4c1.1-1.4-4.6-5.1-4.6-5.1s1.5,0,1.7-0.8
      	c0.2-0.7-3.3,0-3.3,0H388.4z"
      />
      <g>
        <g>
          <defs>
            <path
              id="SVGID_3_"
              d="M420.8,320.4l-108.7-71.7c-4-2.6-5.1-8-2.5-12l12.5-18.9c2.6-4,8-5.1,12-2.5L442.8,287c4,2.6,5.1,8,2.5,12
            				l-12.5,18.9C430.1,321.9,424.8,323,420.8,320.4z"
            />
          </defs>
          <clipPath id="SVGID_4_">
            <use xlinkHref="#SVGID_3_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st15"
            d="M437.9,308.2c-1.7,2.5-6.1,2.5-9.9,0l-109.4-72.1c-3.8-2.5-5.5-6.6-3.9-9.1l6.3-9.5c1.7-2.5,6.1-2.5,9.9,0
          			l109.4,72.1c3.8,2.5,5.5,6.6,3.9,9.1L437.9,308.2"
          />
        </g>
      </g>
      <path
        className="st2"
        d="M320.8,249.4l-8.8-5.8c-2.5-1.7-3.2-5.1-1.6-7.6l12-18.3c1.7-2.5,5.1-3.2,7.6-1.6l8.8,5.8L320.8,249.4z"
      />
      <path
        className="st0"
        d="M434.5,284.8l5.1,3.3c4.6,3,5.9,9.3,2.8,13.9l-6.6,10.1c-3.4,5.2-10.5,6.7-15.7,3.2l-4-2.6L434.5,284.8z"
      />
      <g>
        <g>
          <defs>
            <path
              id="SVGID_5_"
              d="M420.8,320.4l-108.7-71.7c-4-2.6-5.1-8-2.5-12l12.5-18.9c2.6-4,8-5.1,12-2.5L442.8,287c4,2.6,5.1,8,2.5,12
            				l-12.5,18.9C430.1,321.9,424.8,323,420.8,320.4z"
            />
          </defs>
          <clipPath id="SVGID_6_">
            <use xlinkHref="#SVGID_5_" style={{ overflow: 'visible' }} />
          </clipPath>
          <path
            className="st16"
            d="M437.9,308.2c-1.7,2.5-6.1,2.5-9.9,0l-109.4-72.1c-3.8-2.5-5.5-6.6-3.9-9.1l6.3-9.5c1.7-2.5,6.1-2.5,9.9,0
          			l109.4,72.1c3.8,2.5,5.5,6.6,3.9,9.1L437.9,308.2"
          />
        </g>
      </g>
      <path
        className="st2"
        d="M417.3,260.2c-2,2.2-1.8,9.3,2.5,14.4c4.3,5.2,8.3,6.3,9,9.2c0.6,2.8-0.9,20.1-0.9,20.1l1.8,0.9
      	c0,0,7-17.7,6.2-22.9s-10.2-20.3-12.1-22C421.8,258.1,419,258.3,417.3,260.2z"
      />
      <path
        className="st4"
        d="M427.8,306.8c-0.2,0.1-5.1,1.3-4.3,2c0.8,0.7,2.6,1,4.1,0.6c1.4-0.4,1.8-1.9,1.8-1.9L427.8,306.8z"
      />
      <path
        className="st8"
        d="M417.5,254.5c-2,2.7-1.9,11.1,2.4,17.3c4.3,6.1,8.3,7.7,8.9,11s-1,24.1-1,24.1l1.8,1c0,0,7.1-21.2,6.3-27.6
      	c-0.8-6.4-10.1-24.4-12-26.3C422,252,419.2,252.2,417.5,254.5z"
      />
      <path
        className="st0"
        d="M149.5,166.6h-19.4c-2.7,0-4.9-2.2-4.9-4.9v-9.8c0-2.7,2.2-4.9,4.9-4.9h19.4V166.6z"
      />
      <g>
        <circle className="st17" cx="135.5" cy="156.3" r="3.7" />
        <rect
          x="138.9"
          y="157.9"
          transform="matrix(0.5506 -0.8348 0.8348 0.5506 -69.8814 187.6872)"
          className="st7"
          width={1}
          height="1.7"
        />
        <path
          className="st7"
          d="M142.4,161.8l-2.6-1.7c-0.4-0.3-0.5-0.8-0.2-1.2l0,0c0.3-0.4,0.8-0.5,1.2-0.2l2.6,1.7c0.4,0.3,0.5,0.8,0.2,1.2
        		l0,0C143.4,161.9,142.8,162,142.4,161.8z"
        />
      </g>
      <path
        className="st18"
        d="M306.5,276.7c0-0.2,4.3-5.9,6.3-12.3s3.6-14.4,3.6-14.4s-4.2-5.6-3.6-6.2c0.6-0.6,5.6,4.7,6,6.2
      	s-0.9,14.8-1.2,18.7s-7.4,20.9-7.4,20.9l-2.7-1.1L306.5,276.7z"
      />
      <path
        className="st7"
        d="M321.2,389.5l-2.4,0.1c0,0-3-25.9-5-29.7c-2.1-3.8-10.4-21.8-10.4-21.8s1.7,14.6,0.2,21.5
      	c-1.6,6.8-8,30.2-8,30.2l-2.2,0.2c0,0,0.5-19.1,0.4-26.8c-0.1-6.4-2.5-21.8-2.3-31.2c0-1.9,0.2-3.5,0.4-4.8c0.6-2.7,1.8-4.9,3-6.4
      	c2.2-2.8,4.8-3.9,4.8-3.9l16.2,1.5c0,0,8.1,34.3,8.2,38.5C324.2,361.1,321.2,389.5,321.2,389.5z"
      />
      <polygon
        className="st8"
        points="301.1,322.4 310.2,323.5 309,330.3 304.3,333.8 299.7,330.9 "
      />
      <path
        className="st8"
        d="M296.8,321.6l-2.1,9l-3.2,1.4c0-1.9,0.2-3.5,0.4-4.8c0.6-2.7,1.8-4.9,3-6.4L296.8,321.6z"
      />
      <ellipse className="st19" cx={309} cy={273} rx="6.3" ry="6.3" />
      <polygon
        className="st19"
        points="311.6,277.3 313.7,284.9 307.6,285.5 308,278.3 "
      />
      <path
        className="st8"
        d="M305,281.6c1.2-0.3,5.2-0.7,10.2,1.7c5,2.5,7.5,5.5,8.1,9.7s-3.8,5.7-3.8,5.7s-2.5,9.2-3.1,12.8
      	c-0.6,3.6-0.4,7.1-0.4,7.1s0,0.9-9,0.7c-9-0.2-8.2-2.1-8.2-2.1s0.3-14.4,0.2-18.6c-0.1-4.2-0.3-11.7-0.3-11.7L305,281.6z"
      />
      <path
        className="st4"
        d="M309.9,264.8c-2.6-2.3-6.3-3.2-10-0.3c-2.1,1.6-3.3,4.7-4,7.1c-0.6,2-0.3,4.1-1.8,5.1c-1,0.6-3.6,2.9-4.6,4.6
      	c-0.7,1.3,0.3,4.7,0,5.7c-0.4,1.2-2.3,3.4-2.3,4.6c0,2.3,2.3,2.3-3.4,8c-4,4-0.1,7,1.1,8c1,0.8,0.8,4.9,1.4,6.1
      	c0.9,1.8,2.3,3.4,4.4,3.9c2.2,0.6,4.1-0.2,5.5-1.4c1-0.8,2.1-1.2,3.3-1.3c2.4-0.1,5.1-1.3,7.6-4.9c4.3-6.1,3.2-12.6,1.7-16.6
      	c-0.9-2.6-0.7-5.5,0.6-7.9c0.9-1.6,1.8-3.6,2.6-5.9C314,273.8,313.9,268.3,309.9,264.8z"
      />
      <path
        className="st10"
        d="M309.9,264.8c-2.6-2.3-0.1,4-3.9,7c-2.1,1.6-1.2,5-1.9,7.5c-0.6,2,0.6,8.3-0.9,9.3c-1,0.6-2.8,4.9-3.7,6.5
      	c-0.7,1.3-3.4,1.8-3.7,2.8c-0.4,1.2-0.9,4.7,0,5.6c1.6,1.6,6.5,2.8-0.9,6.5c-5.1,2.5-2.2,2.8-0.9,3.7c1,0.8-0.6,0.7,0,1.9
      	c0.9,1.8-5.2,1.4-3.2,2c2.2,0.6,4.1-0.2,5.5-1.4c1-0.8,2.1-1.2,3.3-1.3c2.4-0.1,5.1-1.3,7.6-4.9c4.3-6.1,3.2-12.6,1.7-16.6
      	c-0.9-2.6-0.7-5.5,0.6-7.9c0.9-1.6,1.8-3.6,2.6-5.9C314,273.8,313.9,268.3,309.9,264.8z"
      />
      <path
        className="st18"
        d="M316.9,285.1c-1.9,1.3-6.4,9.3,0.1,9.7c6.5,0.5,20-5.7,22.3-6.8c2.3-1.1,14.6-12.3,14.6-12.3s6.7-0.6,7.1-1.5
      	c0.4-1-6.7-1.5-6.7-1.5s1.5-2.5,0.5-2.5s-2.8,4.3-2.8,4.3s-12.1,6.3-15.4,7.4C324.5,285.8,317.8,284.5,316.9,285.1z"
      />
      <path
        className="st6"
        d="M238,392.1c0.4-0.1,0.5-0.4,0.5-0.8c1.7,1.2,3.4,2.6,2.8,3.1c-0.8,0.7-3.4-1-4.9-2.1
      	C237,392.4,237.6,392.3,238,392.1z"
      />
      <path
        className="st7"
        d="M319.1,388.9c0,0-1,2.6-0.4,3.3c0.6,0.6,5.8,0.4,5.9-0.1s-3.8-2.9-3.8-2.9L319.1,388.9z"
      />
      <path
        className="st7"
        d="M293.4,389.6c-0.3,1-0.6,2.9,0,3.2c0.6,0.3,6.1-0.1,6-0.6c-0.1-0.6-4.2-2.7-4.2-2.7L293.4,389.6z"
      />
    </svg>
  );
}
