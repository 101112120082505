import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'emotion';
import leftArrowSVG from '../../assets/icon_chevron_left.svg';
import leftArrowLightGreySVG from '../../assets/icon_chevron_light_grey.svg';

const MiniBreadcrumb = ({ linkTo, text }) => {
  const [arrowIcon, setIcon] = useState(leftArrowSVG);
  return (
    <div className={cn.breadcrumb}>
      <Link 
        className={cn.breadcrumbLink}
        to={linkTo}
        onMouseEnter={() => setIcon(leftArrowLightGreySVG)}
        onMouseLeave={() => setIcon(leftArrowSVG)}
      >
        <img src={arrowIcon} className={cn.arrowIcon} />
        {text}
      </Link>
    </div>
  );
};

const cn = {
  breadcrumb: css`
    margin-top: 14px;
  `,
  breadcrumbLink: css`
    font-size: 13px;
    color: #2d344b;

    &:hover {
      text-decoration: none;
      color: #9ea3b7;
    }
  `,
  arrowIcon: css`
    margin-top: -2px;
    padding-right: 8px;
  `,
};

export default MiniBreadcrumb;
