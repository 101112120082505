import idx from 'idx';
import { createSelector } from 'reselect';

export const CHECK_AUTH_STATUS = 'auth/CHECK_AUTH_STATUS';
export const AUTH_SUCCESS = 'auth/LOGIN_SUCCESS';
export const AUTH_FAIL = 'auth/AUTH_FAIL';
export const LOGOUT = 'auth/LOGOUT';
export const SET_USER_MASQUERADING = 'auth/SET_USER_MASQUERADING';

enum ActionTypes {
  CHECK_AUTH_STATUS = 'auth/CHECK_AUTH_STATUS',
  LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
  AUTH_FAIL = 'auth/AUTH_FAIL',
  LOGOUT = 'auth/LOGOUT',
  SET_USER_MASQUERADING = 'auth/SET_USER_MASQUERADING'
}

type InternalIdentifiers = {
  displayName: string,
  emails: {
    type: string,
    value: string,
  }[],
  name: {
    familyName: string,
    givenName: string,
  },
  photos: {
    value: string,
  }[],
  token: string,
  userDomain: string,
}

type Identity = {
  uid: string,
  status: 'active' | 'inactive',
  type: 'internal' | 'platform' | 'server' | string,
  isMasquerading: boolean,
  actualIdentity: Identity,
  identifiers: InternalIdentifiers,
  legacyId: number,
  metadata: Record<string, any>,
}

export type MasqueradingIdentifier = {
  email: string,
  firstName: string,
  lastName: string,
  hierarchyType: string,
  legacyId: number,  
}

type State = {
  isAuthenticated: boolean,
  isCheckingAuth: boolean,
  user: Identity | null,
  masquerading: MasqueradingIdentifier | null  
};

// TODO: Create types for each action
type Action = { type: ActionTypes, [key: string]: any };

const initialState = {
  isAuthenticated: false,
  isCheckingAuth: false,
  user: null,
  masquerading: null,
};

export const userReducer = (state: State = initialState, action: Action) => {
  switch (action.type) {
    case AUTH_SUCCESS: {
      return {
        ...action.data,
        isAuthenticated: true,
        isCheckingAuth: false,
      };
    }
    case CHECK_AUTH_STATUS: {
      return {
        ...action.data,
        isCheckingAuth: true,
      };
    }
    case AUTH_FAIL: {
      return {
        ...action.data,
        isAuthenticated: false,
        isCheckingAuth: false,
      };
    }
    case LOGOUT: {
      return {
        ...action.data,
        isAuthenticated: false,
        isCheckingAuth: false,
      };
    }
    case SET_USER_MASQUERADING: {      
      return {
        ...state, 
        masquerading: action.masquerading              
      }
    }    
    default:
      return state;
  }
};

export function setUserMasquerading(userMasquerading) {
  return {
    type: SET_USER_MASQUERADING,
    masquerading: userMasquerading,    
  };
}

// Selectors
const getUser = (state): Identity => state.user;

export const getUserFirstName = createSelector(
  getUser,
  (identity: Identity): string | null =>
    idx(identity, _ => _.identifiers.name.givenName) || null
);
export const getUserEmailAddress = createSelector(
  getUser,
  (identity: Identity): string | null =>
    idx(identity, _ => _.identifiers.emails[0].value) || null
);
export const getUserAvatarURI = createSelector(
  getUser,
  (identity: Identity): string | null =>
    idx(identity, _ => _.identifiers.photos[0].value) || null
);
