import * as React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { components, elements, colors } from '@peachjar/components';
import config from '../../config';

import {
  getUserFirstName,
  getUserEmailAddress,
  getUserAvatarURI,
} from '../../Login/user.duck';

const { REACT_APP_AUTH_URL } = config;
const { Profile, VerticalNav } = components;
const { Note } = elements.typography;

type ReduxStateProps = {
  firstName: string | null,
  emailAddress: string | null,
  avatarURI: string | null,
};
type OwnProps = {};
type Props = ReduxStateProps & OwnProps;

const ProfileDropdown = ({ firstName, emailAddress, avatarURI }: Props) => (
  <Profile
    name={firstName}
    emailAddress={emailAddress}
    profilePictureURI={avatarURI}
    accountType="Peachjar User"
    className={css`
      border-left: 1px solid #e6e6e6;
      z-index: 999;
    `}
  >
    <VerticalNav
      label="Profile Information Navigation"
      className={classNames.profileNav}
    >
      <a href={`${REACT_APP_AUTH_URL}/logout`} className={classNames.logoutNav}>
        <Note>Log Out</Note>
      </a>
    </VerticalNav>
  </Profile>
);

const classNames = {
  profileNav: css`
    padding: 0.5rem;
  `,
  logoutNav: css`
    border-radius: 3px;
    border-left: 4px solid transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
    display: block;
    width: 100%;
    padding: 1rem 1rem 1rem 0.5rem;
    text-decoration: none;
    color: ${colors.prussian};
    &:hover {
      color: ${colors.prussian};
      text-decoration: none;
    }

    @media screen and (min-width: 1920px) {
      padding: 1rem;
    }
  `,
};

const mapStateToProps = (state): ReduxStateProps => ({
  firstName: getUserFirstName(state),
  emailAddress: getUserEmailAddress(state),
  avatarURI: getUserAvatarURI(state),
});

// $FlowFixMe - react-redux libdef is not compatible with flow .85 - See https://github.com/facebook/flow/issues/7125
export default connect(mapStateToProps)(ProfileDropdown);
