import React, { useState, useEffect } from 'react';
import { css, cx } from 'emotion';
import idx from 'idx';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateIcon from '@material-ui/icons/IndeterminateCheckBox';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import { colors, elements } from '@peachjar/components';

const { Note, Label, Paragraph } = elements.typography;

type Props = {
  tableData: Array<any>,
  numberOfDistributions: number,
  totalSchools: number,
  classes: Record<string, any>,
  handleCheckbox: any,
  disabledButtonClick: boolean,
  stagedReturnItems: Array<any>,
  refetch: number,
};

const Table = ({
  tableData,
  numberOfDistributions,
  totalSchools,
  classes,
  handleCheckbox,
  disabledButtonClick,
  stagedReturnItems,
  refetch,
}: Props) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    // setError false when refetch change
    setError(false);

    if (disabledButtonClick) {
      setError(stagedReturnItems.length === 0);
    }

    if (stagedReturnItems.length) {
      setError(false);
    }
  }, [disabledButtonClick, stagedReturnItems, refetch]);

  return (
    <>
      <Paper
        elevation={0}
        style={{ border: '1px #e0e0e0 solid', width: '100%' }}
      >
        <div className={error ? styles.tableError : ''}>
          <MuiTable className={classes.table} padding="none">
            <TableHead>
              <TableCell
                className={classes.head}
                colSpan={2}
                style={{ padding: '0.75rem 0.75rem 0.75rem 1.25rem' }}
              >
                <Paragraph>
                  {`${totalSchools} School(s) x ${numberOfDistributions} Distribution(s)`}
                </Paragraph>
              </TableCell>
              {COLUMN_HEADERS.map((columnHeader, i) => {
                // columnHeader index used to access isChecked value, but only check enabled checkboxes based on numberOfDistributions
                const allItemsCannotBeReturned = tableData.every((val) => {
                  const returnable =
                    idx(val, (_) => _.returnsByDistribution[i].canBeReturned) ||
                    false;
                  return i <= numberOfDistributions - 1 && !returnable;
                });
                const allItemsCanBeReturned = tableData.every((val) => {
                  const isChecked =
                    idx(val, (_) => _.returnsByDistribution[i].isChecked) ||
                    false;
                  return i <= numberOfDistributions - 1 && isChecked;
                });
                const someItemsCanBeReturned = tableData.some((val) => {
                  const isChecked =
                    idx(val, (_) => _.returnsByDistribution[i].isChecked) ||
                    false;
                  return i <= numberOfDistributions - 1 && isChecked;
                });
                const isDisabled = i > numberOfDistributions - 1;

                return (
                  <TableCell
                    className={cx(classes.head, styles.checkboxHeaderCell)}
                    align="center"
                    onChange={() => handleCheckbox({ columnIndex: i, setColumnChecked: !allItemsCanBeReturned })}
                  >
                    <Note muted style={{ whiteSpace: 'nowrap' }}>{columnHeader}</Note>
                    {allItemsCannotBeReturned ? (
                      <CheckIcon style={{ color: colors.prussian }} />
                    ) : (
                      <Checkbox
                        classes={{
                          root: classes.checkboxRoot,
                        }}
                        indeterminateIcon={
                          <IndeterminateIcon style={{ color: colors.leaf }} />
                        }
                        checked={allItemsCanBeReturned}
                        indeterminate={
                          allItemsCanBeReturned === false &&
                          someItemsCanBeReturned
                        }
                        style={{ padding: 0, margin: 0 }}
                        disabled={isDisabled}
                      />
                    )}
                  </TableCell>
                );
              })}
            </TableHead>
            <TableBody>
              {tableData.map((row) => {
                // Array to map and return remaining table cells
                const disabledColumns = new Array(
                  12 - row.returnsByDistribution.length
                ).fill('');

                return (
                  <TableRow className={classes.row}>
                    <TableCell className={styles.labelCell}>
                      <Paragraph>{row.schoolName}</Paragraph>
                      <br />
                      <Note muted>{row.districtName}</Note>
                    </TableCell>
                    <TableCell className={styles.creditsCell}>
                      {row.creditsStaged !== 0 ? (
                        `${row.creditsStaged} c`
                      ) : (
                        <Dashed testid="creditsStagedDashed" />
                      )}
                    </TableCell>
                    {row.returnsByDistribution.map((col) => (
                      <TableCell className={styles.checkboxCell}>
                        <Checkbox
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                          checked={col.isChecked || !col.canBeReturned}
                          checkedIcon={
                            col.canBeReturned ? (
                              <CheckBoxIcon style={{ color: colors.leaf }} />
                            ) : (
                              <CheckIcon style={{ color: colors.prussian }} />
                            )
                          }
                          disableRipple={!col.canBeReturned}
                          onChange={() =>
                            col.canBeReturned &&
                            handleCheckbox({ lineItem: col.lineItemId })
                          }
                          style={{ padding: 0, margin: 0 }}
                        />
                      </TableCell>
                    ))}
                    {disabledColumns.map((col) => (
                      <TableCell className={styles.checkboxCell}>
                        <Checkbox
                          classes={{
                            root: classes.checkboxRoot,
                            checked: classes.checked,
                          }}
                          style={{ padding: 0, margin: 0 }}
                          disabled
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </MuiTable>
        </div>
      </Paper>
      {error && (
        <FormHelperText>
          <Note style={{ color: colors.dragon, paddingBottom: '2rem' }}>
            {MISSED_REQUIREMENT_ERROR}
          </Note>
        </FormHelperText>
      )}
    </>
  );
};

const muiStyles = {
  table: {
    fontFamily: 'Proxima Nova',
    minWidth: 700,
  },
  row: {
    height: '64px',
    '&:nth-of-type(odd)': {
      backgroundColor: colors.platinum,
    },
  },
  head: {
    backgroundColor: '#ffffff',
    position: 'sticky',
    top: 0,
    zIndex: 99,
  },
  checkboxRoot: {
    color: colors.slate,
    '&$checked': {
      color: colors.leaf,
    },
  },
  indeterminateRoot: {
    color: colors.leaf,
  },
};

// @ts-ignore
export default withStyles(muiStyles)(Table);

const MISSED_REQUIREMENT_ERROR = ' You missed this requirement.';

const styles = {
  checkboxCell: css`
    height: 64px;
    width: 70px;
    padding: 0.75rem;
    text-align: center !important;
  `,
  checkboxHeaderCell: css`
    height: 64px;
    width: 70px;
    padding: 0.75rem !important;
    text-align: center !important;
  `,
  creditsCell: css`
    padding: 0.5rem 2rem 0.5rem 0 !important;
    width: 70px;
    text-align: right !important;
    font-size: 1rem;
    white-space: nowrap;
  `,
  labelCell: css`
    padding: 0.75rem 0.75rem 0.75rem 1.25rem !important;
    width: 271px;
  `,
  tableError: css`
    border: 2px solid ${colors.dragon};
    border-radius: 3px;
  `,
};

const COLUMN_HEADERS = [
  'Dist 1',
  'Dist 2',
  'Dist 3',
  'Dist 4',
  'Dist 5',
  'Dist 6',
  'Dist 7',
  'Dist 8',
  'Dist 9',
  'Dist 10',
  'Dist 11',
  'Dist 12',
];
