import React, { FunctionComponent } from 'react'
import { css } from 'emotion'
import {
  ButtonFlatLarge,
  ButtonPrimaryLarge,
} from '@peachjar/ui/dist/lib/components/Buttons/Buttons'

type Props = {
  isValid: boolean
  updateFlyerDetails: () => void
}

const FlyerManagerFooter: FunctionComponent<Props> = ({
  isValid,
  updateFlyerDetails,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.cta}>
        <ButtonFlatLarge onClick={() => window.location.reload()}>
          Cancel
        </ButtonFlatLarge>
      </div>
      <div className={styles.cta}>
        <ButtonPrimaryLarge disabled={!isValid} onClick={updateFlyerDetails}>
          Update
        </ButtonPrimaryLarge>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 40px;
  `,
  cta: css`
    margin-left: 20px;
  `,
}

export default FlyerManagerFooter
