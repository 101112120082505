import React, { useState, useContext } from 'react';
import { components } from '@peachjar/components';
import Search from './Search';
import bffClient from '../../_middleware/fetch/bffApolloClient';
import { SEARCH_TYPES } from '../utils/search-types';
import { GlobalSearchContext } from '../globalSearchContext';
import * as queries from '../utils/queries.graphql';
import preprocessUserData from '../utils/preprocessUserData';

const { LoadingIndicators: { Spinner } } = components;

const GlobalSearchContainer = ({ ...rest }) => {
  const [hasErrorInputSearch, setHasErrorInputSearch] = useState(false);
  const [hasErrorInputType, setHasErrorInputType] = useState(false);
  const {
    DEFAULT_PLACEHOLDER, 
    inputType,
    setInputType,
    placeholder,
    setPlaceholder,
    onSearch: onSearchCB,
    setIsLoading,
    setResults,
    results,
    setHasSearched,
    hasSearched
  } = useContext(GlobalSearchContext);
  
  const [searchQuery, setSearchQuery] = useState('');
  
  const onSearch = async () => {
    if(!inputType) {
      setHasErrorInputType(true);
      return 
    }

    if(!searchQuery) {
      setHasErrorInputSearch(true);
      return;
    }
    setIsLoading(true)

    let query, variables;
    const LIMIT = 30;

    switch(inputType.description){
      case SEARCH_TYPES.districtOrSchool:
        query = queries.GET_ACADEMIC_GROUPS;
        variables = {
          input: {
            filter: searchQuery,
            paginationOptions: {
              limit: LIMIT
            }
          }
        }

      break;
      case SEARCH_TYPES.organization:
        query = queries.GET_ORGANIZATIONS;
        variables = {
          searchTerm: searchQuery,
          limit: LIMIT
        }        
      break;
      case SEARCH_TYPES.flyer:
        query = queries.GET_FLYERS;
        variables = {
          input: {
            filter: {
              searchText: searchQuery,
              showAllNoDrafts: true
            },
            limit: LIMIT
          }
        }         
      break;
      case SEARCH_TYPES.user:
        query  = queries.GET_PLATFORM_USERS;
        variables = {
          input: {
            search: searchQuery,
            pagination: {
              limit: LIMIT
            }
          },
        }
      break;
      default:
        query = null;
    }
    try {  
      const { loading, data } = await bffClient.query({
        query,
        variables,
      });

      if (loading) {
        return <Spinner />;
      }
  
      const preprocessedData = (inputType.description === SEARCH_TYPES.user) ?
        preprocessUserData(data) :
        data
  
      onSearchCB(preprocessedData, loading);
    } catch(e) {
      console.log("error", e)
      setIsLoading(false)      
      onSearchCB({ platformUsers: [] }, false);
    }
    
  };

  const onInputChange = (e) => {
    const { target: { value } } = e;
    let searchString = value;
    if(inputType.description === SEARCH_TYPES.user){
      searchString = value.replace(/\s+/g, ' ').trim();
    }
    setSearchQuery(searchString);
    if(hasErrorInputSearch){
      setHasErrorInputSearch(false);
    }
  };

  const onInputKeyDown = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      onSearch();
    }
  };

  const onInputTypeChange = (value) => {        
    setInputType(value); 
    if(hasErrorInputSearch){
      setHasErrorInputSearch(false);
    }

    if(hasErrorInputType){
      setHasErrorInputType(false);
    }   
    let placeholder;
    switch(value.description){
      case SEARCH_TYPES.districtOrSchool:
        placeholder = 'ID or Name'
      break;
      case SEARCH_TYPES.organization:
        placeholder = 'ID, Name, or Contact Email Address'
      break;
      case SEARCH_TYPES.flyer:
        placeholder = 'ID, Title, or District/Org Name';
      break;
      case SEARCH_TYPES.user: 
        placeholder = 'ID or User Email Address';
      break;
      default: 
       placeholder = DEFAULT_PLACEHOLDER;
    }    
    setPlaceholder(placeholder);
    if(results.length > 0){
      setResults([]);
    }
    if(hasSearched){
      setHasSearched(false);
    }
    setSearchQuery("");
  }
  

  const mergedProps = {
    placeholder,
    testId: 'global-search',
    searchQuery,
    onSearch,
    onInputChange,
    onInputKeyDown,
    inputType,
    onInputTypeChange,
    hasErrorInputType,
    hasErrorInputSearch,
    ...rest,
  };

  return <Search {...mergedProps} />;      
      
};

export default GlobalSearchContainer;
