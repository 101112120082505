import { get, flatMap } from 'lodash'

export interface User {
  uid: string
  userId: number
  email: string
  oldEmails: string[] | null
  firstName: string | null
  lastName: string | null
  hierarchyType: string
  hierarchyId: string
  isOldEmail?: boolean
}

export interface UserData {
  platformUsers: {
    limit: number
    offset: number
    total: number
    items: User[]
  }
}

export default function preprocessUserData(data: UserData): UserData {
  // This func adds oldEmails as separate user rows for display
  const newItems = flatMap(data.platformUsers.items, u => ([
    ...(get(u, 'oldEmails', []) || []).map(
      e => ({ ...u, email: e, isOldEmail: true }) as User
    ),
    { ...u, isOldEmail: false },
  ]))

  return {
    platformUsers: {
      ...data.platformUsers,
      items: newItems,
    }
  }
}
