import { neutral } from '../assets/colors';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  palette: {
    type: 'dark',
  },
  appFrame: {
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
    background: '#fff',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  'appBar-left': {
    marginLeft: drawerWidth,
  },
  'appBar-right': {
    marginRight: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // padding: theme.spacing.unit * 3,
    minWidth: 0, // So the Typography noWrap works
  },
  notLoggedInWrapper: {
    paddingTop: '15%',
    width: '100%',
    boxOrient: 'vertical',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notLoggedInPaper: {
    margin: theme.spacing.unit * 2,
    padding: `${theme.spacing.unit * 2}px 0px ${theme.spacing.unit * 8}px`,
    backgroundColor: 'white',
    minWidth: 400,
    textAlign: 'center',
  },
  spinnerWrapper: {
    minHeight: 150,
    minWidth: 150,
  },
  item: {
    paddingTop: theme.spacing.unit * 5,
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  portalSubtitle: {
    textTransform: 'uppercase',
    color: neutral.stone,
    fontWeight: 600,
  },
  buttonContainer: {
    paddingTop: 10,
  },
  button: {
    backgroundColor: '#3F51B5',
    '&:hover': {
      backgroundColor: '#303F9F',
      color: 'white',
    },
  },
});

export { styles };
