import { FormControl, FormHelperText, MenuItem, Select, TextField } from "@material-ui/core";
import {
  Add as AddIcon,
  Check as ConfirmIcon,
  Edit as EditIcon,
  Clear as RemoveIcon,
} from '@material-ui/icons';
import { ButtonFlatSmall } from "@peachjar/ui/dist/lib/components/Buttons";
import colors from "@peachjar/ui/dist/lib/styles/colors";
import Typo from "@peachjar/ui/dist/lib/typography/TypographyRedux";
import { css } from "emotion";
import { get } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { httpClient } from "../../_middleware/fetch/http";
import { CallToAction, CallToActionType } from "../../api/Flyer";
import { validateCTA } from "../../utils/validateCTA";

type Props = {
  allLinks: CallToAction[]
  featuredLinkIds: CallToAction['id'][]
  maxFeaturedLinksReached: boolean
  setFeaturedLinkValue: (id: string, value: string) => void
  setFeaturedLinkType: (id: string, type: CallToActionType) => void
  featureNextLink: () => void
  replaceFeaturedLink: (id: string, newId: string) => void
  removeFeaturedLink: (id: string) => void
  addNewFeaturedLink: (type: CallToActionType, value: string) => void
  onInvalid: (isInvalid: boolean) => void
  onEdit: (isEditing: boolean) => void
}

export default function FeaturedLinkList({
  allLinks,
  featuredLinkIds,
  maxFeaturedLinksReached,
  setFeaturedLinkType,
  setFeaturedLinkValue,
  featureNextLink,
  replaceFeaturedLink,
  removeFeaturedLink,
  addNewFeaturedLink,
  onInvalid,
  onEdit
}: Props) {
  const featuredLinks: CallToAction[] = [];
  for (const id of featuredLinkIds) {
    const link = allLinks.find(link => link.id === id);
    if (link) {
      featuredLinks.push(link);
    }
  }
  const selectOptions = allLinks.filter(link => !featuredLinkIds.some(id => id === link.id))

  const [showManualLinkInput, setManualLinkInput] = useState(false)
  function toggleManualLinkInput() {
    setManualLinkInput(!showManualLinkInput)
    onEdit(!showManualLinkInput)
  }

  function addAnotherLink() {
    if (!maxFeaturedLinksReached && selectOptions.length === 0) {
      toggleManualLinkInput()
    } else {
      featureNextLink()
    }
  }

  return (
    <div className={styles.container}>
      {featuredLinks.length > 0 && <>
        <Typo.Dark.Note translate="" style={{ color: colors.slate }}>
          Primary Featured Link
        </Typo.Dark.Note>
        <FeaturedLink
          link={featuredLinks[0]}
          onTypeChange={type => setFeaturedLinkType(featuredLinks[0].id, type)}
          onUrlChange={url => setFeaturedLinkValue(featuredLinks[0].id, url)}
          onLinkChange={newId => replaceFeaturedLink(featuredLinks[0].id, newId)}
          remove={() => removeFeaturedLink(featuredLinks[0].id)}
          selectOptions={selectOptions}
          data-testid="call-to-action primary"
          onInvalid={onInvalid}
          onEdit={onEdit}
        />
      </>}
      {featuredLinks.length >= 1 && (
        <Typo.Dark.Note translate="" style={{ color: colors.slate }}>
          Other Featured Links
        </Typo.Dark.Note>
      )}
      {featuredLinks.length > 1 && featuredLinks.slice(1).map(link => (
        <FeaturedLink
          key={link.id}
          link={link}
          onTypeChange={type => setFeaturedLinkType(link.id, type)}
          onUrlChange={url => setFeaturedLinkValue(link.id, url)}
          onLinkChange={newId => replaceFeaturedLink(link.id, newId)}
          remove={() => removeFeaturedLink(link.id)}
          selectOptions={selectOptions}
          data-testid="call-to-action other"
          onInvalid={onInvalid}
          onEdit={onEdit}
        />
      ))}
      {showManualLinkInput && (
        <ManualFeaturedLink
          onConfirm={(type, value) => {
            addNewFeaturedLink(type, value)
            toggleManualLinkInput()
          }}
          onCancel={toggleManualLinkInput}
          data-testid={`call-to-action ${featuredLinks.length > 0 ? 'other' : 'primary'}`}
        />
      )}
      {!maxFeaturedLinksReached && (
        <ButtonFlatSmall
          onClick={addAnotherLink}
          startIcon={<AddIcon />}
          style={{ alignSelf: 'self-start' }}
          data-testid="cta-add"
        >
          {featuredLinks.length === 0 ? 'Add a primary link' : 'Add another link'}
        </ButtonFlatSmall>
      )}
    </div>
  )
}

function FeaturedLink({
  link,
  onTypeChange,
  onUrlChange,
  onLinkChange,
  remove,
  selectOptions,
  "data-testid": dataTestId,
  onInvalid,
  onEdit
}: {
  link: CallToAction
  onTypeChange: (type: CallToActionType) => void
  onUrlChange: (url: string) => void
  onLinkChange: (id: string) => void
  remove: () => void
  selectOptions: CallToAction[]
  'data-testid'?: string
  onInvalid: (isInvalid: boolean) => void
  onEdit: (isEditing: boolean) => void
}) {
  const [localValue, setLocalValue] = useState(() => link.value)
  const [editing, setEditing] = useState(false)
  const [checkingUrl, setCheckingUrl] = useState(false)
  const [urlCheckMessage, setUrlCheckMessage] = useState<string | undefined>()
  const options = [link, ...selectOptions]

  function startEditing() {
    setLocalValue(link.value)
    setEditing(true)
    onEdit(true)
  }

  async function confirmChanges() {
    if (!link.type || localValue === '' || validateCTA(link.type, localValue) !== undefined) {
      return
    };
    if (link.type !== "call" && link.type !== "email") {
      setCheckingUrl(true)
      const safeUrlsResponse = await httpClient.checkUrlSafety([localValue])
      const isSafe = get(safeUrlsResponse, 'safeUrls[0].isSafe', false)
      const isReachable = get(safeUrlsResponse, 'safeUrls[0].isReachable', false)
      setCheckingUrl(false)
      if (!isReachable) {
        setUrlCheckMessage("Your URL points to an unknown destination")
        return
      }
      if (!isSafe) {
        setUrlCheckMessage("Your URL can not be validated due to unsafe content")
        return
      }
      setUrlCheckMessage(undefined)
    }
    setEditing(false)

    onUrlChange(localValue)
    onEdit(false)
  }

  const errorMessage = useMemo(
    () => validateCTA(link.type, link.value),
    [link.type, link.value]
  )
  useEffect(function notifyParentOfInvalidState() {
    onInvalid(errorMessage !== undefined)
  }, [errorMessage])
  const invalid = errorMessage !== undefined

  const editingErrorMessage = useMemo(
    () => {
      return validateCTA(link.type, localValue) || urlCheckMessage;
    },
    [link.type, localValue, urlCheckMessage]
  )
  const invalidWhileEditing = editingErrorMessage !== undefined

  return <div
    className={styles.linkRow}
    data-testid={dataTestId}
  >
    <LinkTypeSelect
      value={link.type}
      onChange={onTypeChange}
    />
    {(editing) &&
      <TextField
        autoFocus
        variant="outlined"
        size="small"
        value={localValue}
        onChange={e => setLocalValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            confirmChanges();
          }
        }}
        onBlur={confirmChanges}
        error={invalidWhileEditing}
        helperText={editingErrorMessage}
        data-testid="cta-value-input"
      />
    }
    {!editing &&
      <FormControl
        variant="outlined"
        size="small"
      >
        <Select
          value={link.id}
          onChange={e => onLinkChange(e.target.value as string)}
          error={invalid}
          data-testid="cta-value-select"
        >
          {options.map(link => (
            <MenuItem key={link.id} value={link.id}>
              {link.value}
            </MenuItem>
          ))}
        </Select>
        {invalid && <FormHelperText>
          <Typo.Dark.Note translate="" style={{ color: colors.dragon }}>
            {errorMessage}
          </Typo.Dark.Note>
        </FormHelperText>}
      </FormControl>
    }
    <div className={styles.actionsContainer}>
      {editing &&
        (checkingUrl ? (
          <Typo.Dark.Note translate="">
            Checking URL...
          </Typo.Dark.Note>
        ) : (
          <button
            className={styles.action}
            onClick={confirmChanges}
            data-testid="cta-confirm-changes"
          >
            <ConfirmIcon />
          </button>
        ))
      }
      {!editing && <>
        <button
          className={styles.action}
          onClick={startEditing}
          data-testid="cta-edit"
        >
          <EditIcon />
        </button>
        <button
          className={styles.action}
          onClick={remove}
          data-testid="cta-remove"
        >
          <RemoveIcon />
        </button>
      </>}
    </div>
  </div>
}

function ManualFeaturedLink({
  onConfirm,
  onCancel,
  "data-testid": dataTestId
}: {
  onConfirm: (type: CallToActionType, value: string) => void,
  onCancel: () => void,
  'data-testid'?: string
}) {
  const [value, setValue] = useState('')
  const [dirty, setDirty] = useState(false)
  const [type, setType] = useState<CallToActionType>()
  const [checkingUrl, setCheckingUrl] = useState(false)
  const [urlCheckMessage, setUrlCheckMessage] = useState<string | undefined>()

  const errorMessage = useMemo(() => {
    if (!dirty || type === undefined) {
      return undefined
    }
    return validateCTA(type, value) || urlCheckMessage
  }, [dirty, type, value, urlCheckMessage])
  const invalid = errorMessage !== undefined

  async function confirmCreation() {
    setDirty(true)
    if (!type || value === '' || validateCTA(type, value) !== undefined) return;

    if (type !== "call" && type !== "email") {
      setCheckingUrl(true)
      const safeUrlsResponse = await httpClient.checkUrlSafety([value])
      const isSafe = get(safeUrlsResponse, 'safeUrls[0].isSafe', false)
      const isReachable = get(safeUrlsResponse, 'safeUrls[0].isReachable', false)
      setCheckingUrl(false)
      if (!isReachable) {
        setUrlCheckMessage("Your URL points to an unknown destination")
        return
      }
      if (!isSafe) {
        setUrlCheckMessage("Your URL can not be validated due to unsafe content")
        return
      }
      setUrlCheckMessage(undefined)
    }

    onConfirm(type, value)
  }

  return <div
    className={styles.linkRow}
    data-testid={dataTestId}
  >
    <LinkTypeSelect
      value={type}
      onChange={setType}
      autoFocus
    />
    <TextField
      variant="outlined"
      size="small"
      value={value}
      onChange={e => setValue(e.target.value)}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          console.log('enter key')
          confirmCreation();
        }
      }}
      onBlur={confirmCreation}
      error={invalid}
      helperText={errorMessage}
      data-testid="cta-value-input"
    />
    <div className={styles.actionsContainer}>
      {checkingUrl ? (
        <Typo.Dark.Note translate="">
          Checking URL...
        </Typo.Dark.Note>
      ) : <>
        <button
          className={styles.action}
          onClick={confirmCreation}
          data-testid="cta-confirm-changes"
        >
          <ConfirmIcon />
        </button>
        <button
          className={styles.action}
          onClick={onCancel}
          data-testid="cta-cancel"
        >
          <RemoveIcon />
        </button>
      </>}
    </div>
  </div>
}

type LinkTypeSelectProps = {
  value?: CallToActionType,
  onChange: (type: CallToActionType) => void,
  autoFocus?: boolean
}

export function LinkTypeSelect({
  value,
  onChange,
  autoFocus = false
}: LinkTypeSelectProps) {
  return (
    <FormControl variant="outlined" size="small" data-testid="cta-type">
      <Select
        value={value}
        onChange={e => onChange(e.target.value as CallToActionType)}
        autoFocus={autoFocus}
      >
        {linkOptions.map(option => (
          <MenuItem key={option.id} value={option.id}>
            <div className={styles.typeSelectOption}>
              <option.icon />
              {option.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export type LinkTypeOption = {
  id: CallToActionType
  label: string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

export const LINK_ICONS: Record<CallToActionType, (props: React.SVGProps<SVGSVGElement>) => JSX.Element> = {
  'call': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 8h1.333A3.335 3.335 0 0 0 8 4.667V6c1.107 0 2 .893 2 2zm2.667 0H14a6 6 0 0 0-6-6v1.333A4.663 4.663 0 0 1 12.667 8zm.666 2.333a7.574 7.574 0 0 1-2.38-.38.683.683 0 0 0-.68.16L8.807 11.58a10.03 10.03 0 0 1-4.394-4.393L5.88 5.713a.64.64 0 0 0 .167-.666 7.574 7.574 0 0 1-.38-2.38C5.667 2.3 5.367 2 5 2H2.667C2.3 2 2 2.3 2 2.667 2 8.927 7.073 14 13.333 14c.367 0 .667-.3.667-.667V11c0-.367-.3-.667-.667-.667zm-9.98-7h1c.047.587.147 1.167.3 1.72l-.8.807a10.232 10.232 0 0 1-.5-2.527zm9.314 9.314a9.936 9.936 0 0 1-2.534-.507l.8-.8c.567.16 1.147.26 1.734.3v1.007z" /></svg>,
  'download': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M12.667 6H10V2H6v4H3.333L8 10.667 12.667 6zM7.334 7.333v-4h1.333v4h.78L8 8.78 6.553 7.333h.78zm-4 4.667h9.333v1.333H3.334V12z" /></svg>,
  'email': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M14.667 4c0-.733-.6-1.333-1.333-1.333H2.667c-.733 0-1.333.6-1.333 1.333v8c0 .733.6 1.333 1.333 1.333h10.666c.734 0 1.334-.6 1.334-1.333V4zm-1.333 0L8 7.333 2.667 4h10.666zm0 8H2.667V5.333L8 8.666l5.334-3.333V12z" /></svg>,
  'get-app': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M11.334.673 4.667.667c-.733 0-1.333.6-1.333 1.333v12c0 .733.6 1.333 1.333 1.333h6.667c.733 0 1.333-.6 1.333-1.333V2c0-.734-.6-1.327-1.333-1.327zm0 11.993H4.667V3.333h6.667v9.333zm-.667-4h-2V5.333H7.334v3.333h-2L8 11.333l2.667-2.667z" /></svg>,
  'join-meeting': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10 5.333v5.334H3.333V5.333H10zM10.667 4h-8C2.3 4 2 4.3 2 4.667v6.666c0 .367.3.667.667.667h8c.366 0 .666-.3.666-.667V9L14 11.667V4.333L11.333 7V4.667c0-.367-.3-.667-.666-.667z" /></svg>,
  'learn-more': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M8 1.333A6.67 6.67 0 0 0 1.333 8 6.669 6.669 0 0 0 8 14.667 6.669 6.669 0 0 0 14.667 8 6.669 6.669 0 0 0 8 1.333zM2.667 8c0-.407.053-.806.14-1.186L5.994 10v.667c0 .733.6 1.333 1.333 1.333v1.287c-2.62-.334-4.66-2.574-4.66-5.287zm9.26 3.6a1.328 1.328 0 0 0-1.267-.933h-.667v-2c0-.367-.3-.667-.666-.667h-4V6.667H6.66c.367 0 .667-.3.667-.667V4.667H8.66c.733 0 1.333-.6 1.333-1.333V3.06A5.336 5.336 0 0 1 13.333 8c0 1.387-.54 2.653-1.406 3.6z" /></svg>,
  'order': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M10.367 8.667c.5 0 .94-.274 1.166-.687l2.387-4.327a.664.664 0 0 0-.58-.986H3.473l-.627-1.333H.666v1.333H2l2.4 5.06-.9 1.627c-.487.893.153 1.98 1.167 1.98h8V10h-8L5.4 8.667h4.966zM4.107 4h8.1l-1.84 3.333h-4.68L4.106 4zm.56 8c-.734 0-1.327.6-1.327 1.334 0 .733.593 1.333 1.327 1.333.733 0 1.333-.6 1.333-1.333C6 12.6 5.4 12 4.667 12zm6.666 0c-.733 0-1.326.6-1.326 1.334 0 .733.593 1.333 1.326 1.333.733 0 1.333-.6 1.333-1.333 0-.734-.6-1.334-1.333-1.334z" /></svg>,
  'register': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M7.333 8a2.666 2.666 0 1 0 0-5.333 2.666 2.666 0 1 0 0 5.333zm0-4c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C6.6 6.667 6 6.067 6 5.333S6.6 4 7.333 4zm-4 8c.134-.42 1.714-1.12 3.307-1.293L8 9.373a6.226 6.226 0 0 0-.667-.04C5.553 9.333 2 10.226 2 12v1.333h6L6.667 12H3.333zm10.4-3.667-3.42 3.447-1.38-1.387-.933.94 2.313 2.333 4.354-4.393-.934-.94z" /></svg>,
  'rsvp': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M7.333 8a2.666 2.666 0 1 0 0-5.333 2.666 2.666 0 1 0 0 5.333zm0-4c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C6.6 6.667 6 6.067 6 5.333S6.6 4 7.333 4zm-4 8c.134-.42 1.714-1.12 3.307-1.293L8 9.373a6.226 6.226 0 0 0-.667-.04C5.553 9.333 2 10.226 2 12v1.333h6L6.667 12H3.333zm10.4-3.667-3.42 3.447-1.38-1.387-.933.94 2.313 2.333 4.354-4.393-.934-.94z" /></svg>,
  'signup': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M7.333 8a2.666 2.666 0 1 0 0-5.333 2.666 2.666 0 1 0 0 5.333zm0-4c.734 0 1.334.6 1.334 1.333s-.6 1.333-1.334 1.333C6.6 6.667 6 6.067 6 5.333S6.6 4 7.333 4zm-4 8c.134-.42 1.714-1.12 3.307-1.293L8 9.373a6.226 6.226 0 0 0-.667-.04C5.553 9.333 2 10.226 2 12v1.333h6L6.667 12H3.333zm10.4-3.667-3.42 3.447-1.38-1.387-.933.94 2.313 2.333 4.354-4.393-.934-.94z" /></svg>,
  'subscribe': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M13.334 2.667H2.667c-.733 0-1.327.6-1.327 1.333l-.007 8c0 .733.6 1.333 1.334 1.333H8V12H2.667V5.333L8 8.666l5.334-3.333v3.333h1.333V4c0-.733-.6-1.333-1.333-1.333zM8 7.333 2.667 4h10.666L8 7.333zm3.56 7.333-2.36-2.36.94-.94 1.413 1.414 2.827-2.827.954.94-3.774 3.773z" /></svg>,
  'survey': (props) => <svg width="16" height="16" viewBox="0 0 16 16" {...props} xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M12.667 2H3.333C2.6 2 2 2.6 2 3.333v9.334C2 13.4 2.6 14 3.333 14h9.334C13.4 14 14 13.4 14 12.667V3.333C14 2.6 13.4 2 12.667 2zm0 10.667H3.333V3.333h9.334v9.334zm-8-6H6v4.666H4.667V6.667zm2.666-2h1.334v6.666H7.333V4.667zm2.667 4h1.333v2.666H10V8.667z" /></svg>,
}

export const LINK_LABELS: Record<CallToActionType, string> = {
  'call': 'Call',
  'download': 'Download',
  'email': 'Email',
  'get-app': 'Get App',
  'join-meeting': 'Join Meeting',
  'learn-more': 'Learn More',
  'order': 'Order',
  'register': 'Registration',
  'rsvp': 'RSVP',
  'signup': 'Sign Up',
  'subscribe': 'Subscribe',
  'survey': 'Survey',
}

const linkOptions: ReadonlyArray<LinkTypeOption> = [
  {
    id: 'call',
    label: LINK_LABELS.call,
    icon: LINK_ICONS.call
  },
  {
    id: 'download',
    label: LINK_LABELS.download,
    icon: LINK_ICONS.download
  },
  {
    id: 'email',
    label: LINK_LABELS.email,
    icon: LINK_ICONS.email
  },
  {
    id: 'get-app',
    label: LINK_LABELS['get-app'],
    icon: LINK_ICONS['get-app']
  },
  {
    id: 'join-meeting',
    label: LINK_LABELS['join-meeting'],
    icon: LINK_ICONS['join-meeting']
  },
  {
    id: 'learn-more',
    label: LINK_LABELS['learn-more'],
    icon: LINK_ICONS['learn-more']
  },
  {
    id: 'order',
    label: LINK_LABELS.order,
    icon: LINK_ICONS.order
  },
  {
    id: 'register',
    label: LINK_LABELS.register,
    icon: LINK_ICONS.register
  },
  {
    id: 'rsvp',
    label: LINK_LABELS.rsvp,
    icon: LINK_ICONS.rsvp
  },
  {
    id: 'signup',
    label: LINK_LABELS.signup,
    icon: LINK_ICONS.signup
  },
  {
    id: 'subscribe',
    label: LINK_LABELS.subscribe,
    icon: LINK_ICONS.subscribe
  },
  {
    id: 'survey',
    label: LINK_LABELS.survey,
    icon: LINK_ICONS.survey
  },
] as const

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `,
  linkRow: css`
    display: grid;
    grid-template-columns: 160px 1fr 96px;
    align-items: start;
    gap: 12px;
  `,
  action: css`
    all: unset;
    cursor: pointer;
    height: 42px;
    width: 42px;
    display: flex;
    place-items: center;
    place-content: center;
  `,
  actionsContainer: css`
    display: flex;
    gap: 12px;
    align-items: center;
    height: 42px;
  `,
  pointer: css`
    cursor: pointer;
  `,
  typeSelectOption: css`
    gap: 8px;
    display: flex;
    align-items: center;

    & svg {
      flex-shrink: 0;
    }
  `
}
