import React, { useState } from 'react';
import gql from 'graphql-tag';
import idx from 'idx';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import Table from '../components/Table';
import { css } from 'emotion';
import { get } from 'lodash';
import { History } from 'react-router-dom';
import { elements, colors, components } from '@peachjar/components';
import { useQuery as uq } from '@apollo/react-hooks';
import { Match } from 'react-router-dom';
import internalBffClient from '../../_middleware/fetch/bffApolloClient';
import ReceiptHeader from '../components/ReceiptHeader';
import SubappHeader from '../../App/components/SubappHeader';
import internalBffApolloClient from '../../_middleware/fetch/bffApolloClient';
import MiniBreadcrumb from '../../shared/components/MiniBreadcrumb';
import AddRemoveRefundCreditsDrawer, {
  ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE,
} from '../components/AddRemoveRefundCreditsDrawer';
import MenuButton from '../components/ActionSplitButton';
import { Action, DetailAction } from '../types/Actions';
import {
  TRANSACTION_HISTORY_COLUMN_HEADERS,
  TRANSACTION_HISTORY_HEADER_ALIGNMENT_MAP,
} from '../constants';

const {
  typography: { Paragraph },
} = elements;
const { SpinnerLarge } = components.LoadingIndicators;

export type Transaction = {
  actions: Action[],
  transactionGroupId: string,
  amountInCents: number,
  amountInCredits: number,
  creditBalance: number,
  details: string,
  extraInfo: any,
  id: string,
  sequenceId: number,
  timestamp: number,
  type: string,
};
export type RefundTransaction = {
  actions: DetailAction[],
  transactionGroupId: string,
  amountInCents: number,
  amountInCredits: number,
  creditBalance: number,
  details: string,
  extraInfo: any,
  id: string,
  sequenceId: number,
  timestamp: number,
  type: string,
};

type TransactionHistory = {
  transactions: [Transaction],
  organization: Record<string, any>,
};

type Props = {
  styles: { [key: string]: any },
  match: Match,
  useQuery?: any,
  history: History,
};

const TransactionManagement = ({
  styles,
  match,
  history,
  useQuery = uq,
}: Props) => {
  let transactionHistory;
  let companyName: string = '';
  let email: string = '';
  let creditBalance: number = 0;
  let columnHeaders: string[] = [];
  let columnHeaderAlignmentMap: Record<string, string> = {};
  let tableData;

  const [addCreditsState, setAddCreditsState] = useState(undefined);
  const [deleteCreditsState, setDeleteCreditsState] = useState(undefined);
  const [removeCreditsState, setRemoveCreditsState] = useState(undefined);

  const creditHolderInfo = match.params;

  const variables = {
    input: {
      creditHolder: {
        id: creditHolderInfo.creditHolderId,
        type: creditHolderInfo.creditHolderType,
      },
    },
  };

  const { data, loading, refetch, error } = useQuery(GET_TRANSACTION_HISTORY, {
    client: internalBffClient,
    fetchPolicy: 'no-cache',
    variables,
  });

  if (!loading && data) {
    transactionHistory = data.getTransactionHistory;
    ({ companyName, email } = transactionHistory.organization);

    columnHeaders = TRANSACTION_HISTORY_COLUMN_HEADERS;
    columnHeaderAlignmentMap = TRANSACTION_HISTORY_HEADER_ALIGNMENT_MAP;

    // Ensure data sorted by sequenceId desc
    tableData = transactionHistory
      ? transactionHistory.transactions.sort(
          (a, b) => b.sequenceId - a.sequenceId
        )
      : [];

    creditBalance = get(tableData, '[0].creditBalance', 0);
  }

  if (loading) {
    return <SpinnerLarge />;
  }

  if (error) {
    return <div>An error occurred :(</div>;
  }

  return (
    <>
      <SubappHeader className="pt-4 pb-4">Transaction Management</SubappHeader>
      <AddRemoveRefundCreditsDrawer
        drawerType={ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD}
        setCreditDrawerState={setAddCreditsState}
        creditDrawerState={addCreditsState}
        creditHolderId={creditHolderInfo.creditHolderId}
        creditHolderType={creditHolderInfo.creditHolderType}
        transactionsRefetch={refetch}
      />
      <AddRemoveRefundCreditsDrawer
        drawerType={ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE}
        setCreditDrawerState={setRemoveCreditsState}
        creditDrawerState={removeCreditsState}
        creditHolderId={creditHolderInfo.creditHolderId}
        creditHolderType={creditHolderInfo.creditHolderType}
        transactionsRefetch={refetch}
      />
      <AddRemoveRefundCreditsDrawer
        drawerType={ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND}
        setCreditDrawerState={setDeleteCreditsState}
        creditDrawerState={deleteCreditsState}
        creditHolderId={creditHolderInfo.creditHolderId}
        creditHolderType={creditHolderInfo.creditHolderType}
        transactionsRefetch={refetch}
      />
      <Paper elevation={0} className={styles.paperMargin}>
        <div className={styles.subAppContainerReceiptVariant}>
          <ReceiptHeader
            companyName={companyName}
            userId={creditHolderInfo.creditHolderId}
            email={email}
            currentCreditBalance={creditBalance}
            styles={styles}
            setAddCreditsState={setAddCreditsState}
          />
          <Table
            columnHeaders={columnHeaders}
            columnHeaderAlignmentMap={columnHeaderAlignmentMap}
            tableData={tableData}
            history={history}
            setDeleteCreditsState={setDeleteCreditsState}
            setRemoveCreditsState={setRemoveCreditsState}
            creditHolderInfo={creditHolderInfo}
          />
        </div>
      </Paper>
    </>
  );
};

const txClasses = {
  bottomContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 50px;
  `,
  bottomInner: css`
    display: flex;
  `,
  hyperlink: css`
    color: ${colors.jungle};
    cursor: pointer;
    &:hover {
      color: #c5e8c9;
    }
  `,
  paragraphContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    margin-top: 15px;
  `,
};

export const GET_TRANSACTION_HISTORY = gql`
  query transactionHistory($input: WrappedCreditHolderInput) {
    getTransactionHistory(input: $input) {
      organization {
        companyName
        email
      }
      transactions {
        id
        timestamp
        transactionGroupId
        sequenceId
        type
        details
        amountInCents
        amountInCredits
        creditBalance
        actions {
          type
          details {
            ... on TxActionDetailsPurchase {
              purchaseId
            }
            ... on TxActionDetailsInvoice {
              invoiceId
            }
            ... on TxActionTransactionAndDelta {
              transactionGroupId
              amountInCredits
              amountInCents
            }
            ... on TxActionReturn {
              purchaseId
              refId
            }
          }
          itemDetails {
              amountInCredits
              amountInCents
              unitPrice       
          }
        }
        extraInfo {
          __typename
          ... on TxDetailsInvoice {
            invoiceId
            status
          }
          ... on TxDetailsBasic {
            nope
          }
          ... on TxDetailsCreditPurchase {
            hasDeal
            deals {
              id
              name
              description
              creditsSaved
              savingsInCents
              count
            }
            pos
          }
          ... on TxDetailsCreditRefund {
            hasDeal
            deals {
              id
              name
              description
              creditsSaved
              savingsInCents
              count
            }
            lineItemId
            transactionDeltas {
              amountInCredits
              amountInCents
              unitPrice 
              targetRefId
              creditDelta
            }
            note
            pos
          }
          ... on TxDetailsCreditAddition {
            note
          }
          ... on TxDetailsCreditRemoval {
            note
          }
          ... on TxDetailsCreditUse {
            hasDeal
            deals {
              id
              name
              description
              creditsSaved
              savingsInCents
              count
            }
          }
          ... on TxDetailsCreditReturn {
            note
          }
          ... on TxDetailsCreditTransferredIn {
            note
          }
          ... on TxDetailsCreditTransferredOut {
            note
          }
          ... on TxDetailsCreditEvent {
            data
          }
        }
      }
    }
  }
`;

export default TransactionManagement;
