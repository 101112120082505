import React, { useContext } from 'react';
import SortableTable from "@peachjar/ui/dist/lib/components/SortableTable/SortableTable2"
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { components } from '@peachjar/components'
import { GlobalSearchContext } from '../../globalSearchContext';
import config from '../../../config'

const {
  Buttons: { ButtonSecondarySmall },
} = components

const {  
  REACT_APP_INTERNAL_APP_URL
} = config

const TableOrganization = () => {
  const {
    results,
    classes
  } = useContext(GlobalSearchContext);   


  const orgs =  results.map(result => {    
    const { location : { locality, state  }} = result;    
    return {
      ...result,
      location: locality && state ? `${locality}, ${state}`: '--',
    }
  })

  return (
    <SortableTable 
    columnCount={5}
    isFiltered={false}
    items={orgs}
    initialSort={{field: 'name', direction: 'asc'}}      
    thead={({ asSortableLabel }) => {
      return (
        <TableRow>
            <TableCell>{asSortableLabel('ID', 'orgId')}</TableCell>
            <TableCell>{asSortableLabel('Name', 'name')}</TableCell>  
            <TableCell>{asSortableLabel('Email Address', 'email')}</TableCell>
            <TableCell>{asSortableLabel('Location', 'location')}</TableCell>                                             
            <TableCell>&nbsp;</TableCell>
        </TableRow>
      )
    }}
    tbody={({ orgId, name, email, location}) => {      
      return (
      <TableRow className={classes.row} key={orgId}>
        <TableCell className={classes.cell}>{orgId}</TableCell>
        <TableCell className={classes.cell}>{name}</TableCell>
        <TableCell className={classes.cell}>{email}</TableCell>
        <TableCell className={classes.cell}> {location}</TableCell>        
        <TableCell className={classes.cellRight}>
          <ButtonSecondarySmall
            onClick={() => {                                           
              window.open(`${REACT_APP_INTERNAL_APP_URL}/transactions/${orgId}/organization`, '_blank')              
            }}
          >
            Manage Transactions
          </ButtonSecondarySmall>
        </TableCell>
      </TableRow>
      )
    }}
    />      
  )
}

export default TableOrganization;