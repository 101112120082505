import * as React from 'react';
import axios from 'axios';
import { css } from 'emotion';
import {  elements, components, colors } from '@peachjar/components';
import { connect } from 'react-redux';
import { setUserMasquerading, MasqueradingIdentifier } from '../../Login/user.duck';
const { Note } = elements.typography;
import { renderUserTypeCell } from '../../GlobalSearch/components/Tables/TableUsers'
import config from '../../config';
import { pageVisibilityApi } from '../../utils/pageVisibilityUtils';
import { Session } from '../../api/Session';

const { visibilityChange } = pageVisibilityApi();

axios.defaults.baseURL = config.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

const {
  Buttons: { ButtonSecondarySmall },
  Notifications: { notifySuccess, notifyError },
} = components

const {
  REACT_APP_AUTH_URL,  
} = config

type ReduxStateProps = {  
  masquerading: MasqueradingIdentifier,  
};

type ReduxDispatchProps = {
  onSetUserMasquerading: Function,
  onSuccess: Function,
  onError: Function
};
type Props = ReduxStateProps & ReduxDispatchProps;

const IS_MASKED_KEY = 'pjim'

const StopMasquerading = ({ masquerading, onSuccess, onError, onSetUserMasquerading }: Props) => {

  React.useEffect(() => {
    const onVerifyUserMasquerading = async () => {
      const response = await axios.get<Session>('/auth/session');
      const session = response.data as Session;      
      const { identity } = session;

      if (identity && identity.isMasquerading){
        const { identifiers } = identity;
        const { legacyId, metadata } = identity
    
        const user = {
          ...identifiers,
          legacyId,
          hierarchyType: metadata.hierarchyType,
        }
        onSetUserMasquerading(user);
      }
      else {
        localStorage.removeItem('pjimuid')
        localStorage.setItem(IS_MASKED_KEY, 'false')
        onSetUserMasquerading(null);
      } 
    }

    document.addEventListener(visibilityChange, onVerifyUserMasquerading, false);            
    onVerifyUserMasquerading();
    return () => {
      document.removeEventListener(visibilityChange, onVerifyUserMasquerading, false);                    
    }
  }, [])

  const unmaskMe = async () => {
    const response = await fetch(`${REACT_APP_AUTH_URL}/stop-masquerading`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
    })

    const json = await response.json()

    if (json.isMasquerading === false) {
      localStorage.setItem(IS_MASKED_KEY, 'false')
      localStorage.removeItem('pjimuid')
      onSetUserMasquerading(null);
      onSuccess()      
    } else {
      onError()
    }
  }
        
  if(!masquerading) return null;

  return(
    <div className={classNames.root}>
      <Note className={classNames.text}>
        You are masquerading as <b>{masquerading.email}</b> - ({renderUserTypeCell(masquerading.hierarchyType)} #{masquerading.legacyId}) 
      </Note>
      <ButtonSecondarySmall  onClick={async() => await unmaskMe()}>                     
        Stop Masquerading
      </ButtonSecondarySmall>
    </div>   
  )     
};

const classNames = {
  root: css`    
    display: flex;
    align-items: center;
    padding-right: 15px;
    color: ${colors.prussian};
  `,
  text: css`       
   padding-right: 15px;   
 `
};

const mapStateToProps = (state): ReduxStateProps => ({    
  masquerading: state.user.masquerading
});

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({  
  onSuccess: () => dispatch(notifySuccess('You are no longer masquerading.')),
  onError: () =>
    dispatch(notifyError('Something went wrong. Please try again.')),
  onSetUserMasquerading: (user) => dispatch(setUserMasquerading(user))
});


export default 
connect(
  mapStateToProps,
  mapDispatchToProps
)(StopMasquerading);
