import React, { useContext } from 'react';
import idx from 'idx'
import SortableTable from "@peachjar/ui/dist/lib/components/SortableTable/SortableTable2"
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { components } from '@peachjar/components'
import moment from 'moment';
import { GlobalSearchContext } from '../../globalSearchContext';
import config from '../../../config'

const {
  Buttons: { ButtonSecondarySmall },
} = components

const {  
  REACT_APP_INTERNAL_APP_URL
} = config

const TableFlyer = () => {
  const {
    results,
    classes    
  } = useContext(GlobalSearchContext);   

  const mapOwnerType = (ownerType) => {
    if(!ownerType) return "";

    if(ownerType === 'commFree') return "Org Member"

    if(ownerType.includes("-")){      
      const words = ownerType.split('-').map(word=> word.charAt(0).toUpperCase() + word.slice(1))
      return words.join(' ');
    }
    else {
      return ownerType.charAt(0).toUpperCase() + ownerType.slice(1);
    }
  }

  const flyers =  results.map(result => {         
    return {
      ...result,
      ownerType: mapOwnerType(result.ownerType),
    }
  });  

  return (
    <SortableTable 
    columnCount={6}
    isFiltered={false}
    items={flyers}
    initialSort={{field: 'flyerId', direction: 'asc'}}      
    thead={({ asSortableLabel }) => {
      return (
        <TableRow>
            <TableCell>{asSortableLabel('ID', 'flyerId')}</TableCell>
            <TableCell>{asSortableLabel('Title', 'title')}</TableCell>  
            <TableCell>{asSortableLabel('Target Distribution Date', 'startDate')}</TableCell>
            <TableCell>{asSortableLabel('Uploader Type', 'ownerType')}</TableCell>
            <TableCell>{asSortableLabel('District/Org Name', 'ownerName')}</TableCell>
            <TableCell>&nbsp;</TableCell>
        </TableRow>
      )
    }}
    tbody={(item) => {
      const flyerId = idx(item, (_) => _.flyerId) || ''      
      const title = idx(item, (_) => _.title) || ''
      const approvalDate = idx(item, (_) => _.startDate) || ''
      const ownerType = idx(item, (_) => _.ownerType) || ''                
      const ownerName = idx(item, (_) => _.ownerName) || ''
      const ownerId = idx(item, (_) => _.ownerId) || ''

      return (
      <TableRow className={classes.row} key={flyerId}>
        <TableCell className={classes.cell}>{flyerId}</TableCell>
        <TableCell className={classes.cell}>{title}</TableCell>
        <TableCell className={classes.cell}>{moment(approvalDate).format('MMM DD, YYYY')}</TableCell>
        <TableCell className={classes.cell}>{ownerType}</TableCell>
        <TableCell className={classes.cell}>{ownerName} {ownerId && `#${ownerId}`}</TableCell>
        <TableCell className={classes.cellRight}>
          <ButtonSecondarySmall
            onClick={() => {         
              window.open(`${REACT_APP_INTERNAL_APP_URL}/flyer/${flyerId}`, '_blank')
            }}
          >
            Manage Flyer
          </ButtonSecondarySmall>
        </TableCell>
      </TableRow>
      )
    }}
    />      
  )
}

export default TableFlyer;