import { css, cx } from "emotion";
import React, { useMemo, useState } from "react";
import getValidHref from "../../utils/getValidHref";
import { Flyer } from "../../api/Flyer";

type Props = {
  flyer: Flyer
  pageNumber: number
  flyerImage: string
  highlightedLink?: string
  highlightLink: (id: string | undefined) => void
}

export default function InteractiveFlyer({
  flyer,
  pageNumber,
  flyerImage,
  highlightedLink,
  highlightLink,
}: Props) {
  const [widthRatio, setWidthRatio] = useState(0)
  const [heightRatio, setHeightRatio] = useState(0)
  function onFlyerImageLoad(imageElement: HTMLImageElement) {
    setWidthRatio(imageElement.clientWidth / imageElement.naturalWidth)
    setHeightRatio(imageElement.clientHeight / imageElement.naturalHeight)
  }

  const links: any[] = []/*useMemo(() => (
    allLinks
      .filter(link => link.paragraph && link.paragraph.pageNumber + 1 === pageNumber)
      .map<ExtendedCTA>(link => ({
        ...link,
        left: widthRatio * link.paragraph!.vertices[0].x,
        top: heightRatio * link.paragraph!.vertices[0].y,
        width: widthRatio * (link.paragraph!.vertices[1].x - link.paragraph!.vertices[0].x),
        height: heightRatio * (link.paragraph!.vertices[1].y - link.paragraph!.vertices[0].y)
      }))
  ), [allLinks, heightRatio, pageNumber, widthRatio])*/

  const isHighlightedInThisPage = links.some(link => link.id === highlightedLink)

  return (
    <div className={styles.container}>
      <img
        data-testid="dimmedFlyer"
        className={cx(styles.image, styles.dimmed)}
        src={flyerImage}
        alt="Dimmed Flyer"
      />
      <img
        id="overlayFlyer"
        data-testid="overlayFlyer"
        className={cx(
          styles.image,
          styles.overlay,
          isHighlightedInThisPage && highlightedLink !== undefined && styles.clippedOverlay
        )}
        src={flyerImage}
        alt="Flyer Page"
        onLoad={(event) => onFlyerImageLoad(event.target as HTMLImageElement)}
      />
      {links.map(link => (
        <a
          key={link.id}
          data-testid={`interactiveLink-${link.id}`}
          className={styles.link}
          href={getValidHref(link.value, link.type)}
          style={{
            left: `${link.left}px`,
            top: `${link.top}px`,
            width: `${link.width}px`,
            height: `${link.height}px`
          }}
          onMouseEnter={() => highlightLink(link.id)}
          onMouseLeave={() => highlightLink(undefined)}
          rel="noopener noreferrer"
          target={link.type === "Email" || link.type === "Call" ? "_self" : "_blank"}
        >
        </a>
      ))}
      <svg width="0" height="0">
        <defs>
          <clipPath id="flyerClipPath">
            {links.map(link => (
              (highlightedLink === undefined || highlightedLink === link.id) &&
              <rect x={link.left} y={link.top} width={link.width} height={link.height} />
            ))}
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

const styles = {
  container: css`
    position: relative;
    width: 100%;
    aspect-ratio: 8.5 / 11;

    &:hover #overlayFlyer {
      clip-path: url(#flyerClipPath);
    }
  `,
  image: css`
    display: block;
    width: 100%;
    aspect-ratio: 8.5 / 11;
  `,
  dimmed: css`
    opacity: 0.2;
  `,
  overlay: css`
    position: absolute;
    left: 0;
    top: 0;
  `,
  clippedOverlay: css`
    clip-path: url(#flyerClipPath);
  `,
  link: css`
    position: absolute;
  `
}