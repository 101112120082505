import React, { useState } from 'react'
import idx from 'idx'
import { css } from 'emotion'
import { components, colors } from '@peachjar/components'
import { withRouter } from 'react-router-dom'
import { Grid, Typography, withStyles } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import config from '../config'
import MasqueradeSearchContainer from '../App/components/SearchContainer'
import noResultsSVG from '../assets/turtle.svg'
import SearchSvg from '../Search/components/SearchSvg'
import SubappHeader from '../App/components/SubappHeader'

const {
  Buttons: { ButtonSecondarySmall },
} = components
const {
  LoadingIndicators: { SpinnerLarge },
} = components

const {
  REACT_APP_AUTH_URL,
  REACT_PORTAL_DASHBOARD_URL,
  REACT_APP_ACCOUNTS_APP_URL,
} = config

const IS_MASKED_KEY = 'pjim'

const styles = (theme) => ({
  pageContainer: {
    padding: '32px 16px',
  },
  page: {
    background: 'white',
    width: '100%',
    minHeight: 500,
    marginTop: 0,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 5}px`,
  },
  row: {
    backgroundColor: colors.platinum,
  },
  cell: {
    textAlign: 'left',
  },
  cellRight: {
    textAlign: 'right',
  },
  table: {
    borderRadius: '6px',
    border: `1px solid ${colors.silver}`,
  },
  spinnerContainer: {
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
})

const MasqueradePage = ({ classes, history }) => {
  const [platformUsers, setPlatformUsers] = useState(null)
  const [hasSearched, setHasSearched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSearch = ({ platformUsers: users }, loading) => {
    const items = idx(users, (_) => _.items) || null
    setIsLoading(loading)
    setPlatformUsers(items)
    setHasSearched(true)
  }

  const masqueradeAsUser = async (uuid, user_type, email, user_id) => {
    const identityString = `${email} - ${user_id} (${user_type})`
    const response = await fetch(`${REACT_APP_AUTH_URL}/masquerade`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        subject: '' + uuid,
      }),
      credentials: 'include',
    })

    const json = await response.json()

    const uid = idx(json, (_) => _.uid) || null
    const isMasked = idx(json, (_) => _.isMasquerading) || false

    if (uid && isMasked) {
      localStorage.setItem(IS_MASKED_KEY, 'true')
      localStorage.setItem('pjimuid', identityString)
      // window.open(REACT_PORTAL_DASHBOARD_URL, '_blank');

      let urlToOpen = REACT_PORTAL_DASHBOARD_URL
      if (user_type === 'family' || user_type === 'parent') {
        urlToOpen = `${REACT_APP_ACCOUNTS_APP_URL}/parent/account-information`
      }

      window.open(urlToOpen, '_blank')

      history.push('/mask/success', { uid: identityString })
    }
  }

  const renderUserTable = () => {
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>First Name</TableCell>
            <TableCell className={classes.cell}>Last Name</TableCell>
            <TableCell className={classes.cell}>Email Address</TableCell>
            <TableCell className={classes.cell}>User ID</TableCell>
            <TableCell className={classes.cell}>User Type</TableCell>
            <TableCell className={classes.cell}>User Type ID</TableCell>
            <TableCell className={classes.cellRight}>&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {platformUsers.map((user) => {
            const firstName = idx(user, (_) => _.firstName) || ''
            const lastName = idx(user, (_) => _.lastName) || ''
            const userId = idx(user, (_) => _.userId) || ''
            const email = idx(user, (_) => _.email) || ''
            const hierarchyType = idx(user, (_) => _.hierarchyType) || ''
            const hierarchyId = idx(user, (_) => _.hierarchyId) || ''
            const uid = idx(user, (_) => _.uid) || null

            return (
              <TableRow className={classes.row} key={uid}>
                <TableCell className={classes.cell}>{firstName}</TableCell>
                <TableCell className={classes.cell}>{lastName}</TableCell>
                <TableCell className={classes.cell}>{email}</TableCell>
                <TableCell className={classes.cell}>{userId}</TableCell>
                <TableCell className={classes.cell}>{hierarchyType}</TableCell>
                <TableCell className={classes.cell}>{hierarchyId}</TableCell>
                <TableCell className={classes.cellRight}>
                  <ButtonSecondarySmall
                    onClick={() =>
                      masqueradeAsUser(uid, hierarchyType, email, userId)
                    }
                  >
                    Start Masquerading
                  </ButtonSecondarySmall>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    )
  }

  const hasPlatformUsers =
    platformUsers && Array.isArray(platformUsers) && platformUsers.length >= 1

  const isMasqd = localStorage.getItem(IS_MASKED_KEY) === 'true'

  if (isMasqd) {
    history.push('/mask/success')
  }

  return (
    <>
      <Grid container className={classes.pageContainer}>
        <SubappHeader>Masquerade</SubappHeader>
        <div className={classes.page}>
          <div className={cn.searchContainer}>
            <MasqueradeSearchContainer
              onSearch={onSearch}
              setIsLoading={setIsLoading}
            />
          </div>
          {!hasPlatformUsers && hasSearched && !isLoading && (
            <div className={cn.noResults}>
              <img src={noResultsSVG} alt="" />
              <p className={cn.noResultsTxt}>
                Uh oh! We can't find the user you are looking for. Please try
                again.
              </p>
            </div>
          )}
          {isLoading && (
            <div className={classes.spinnerContainer}>
              <SpinnerLarge />
            </div>
          )}
          {!hasPlatformUsers && !hasSearched && !isLoading && (
            <div className={cn.illustration}>
              {/* <img src={searchSVG} alt="" /> */}
              <SearchSvg />
            </div>
          )}
          {hasPlatformUsers && !isLoading && (
            <h3 className={cn.headline}>Found: </h3>
          )}
          {hasPlatformUsers && !isLoading && renderUserTable()}
        </div>
      </Grid>
    </>
  )
}

const cn = {
  headline: css`
    font-size: 19px;
  `,
  searchContainer: css`
    margin-bottom: 24px;
  `,
  noResults: css`
    text-align: center;
    padding-bottom: 60px;
  `,
  noResultsTxt: css`
    font-size: 16px;
    padding: 20px 0;
    margin: 0 auto;
    width: 370px;
  `,
  illustration: css`
    text-align: center;
    padding: 60px 0;
  `,
}

export default withStyles(styles)(withRouter(MasqueradePage))
