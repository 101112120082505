import React from 'react';
import { components } from '@peachjar/components';

const {
  Buttons: { ButtonPrimaryLarge },
  LoadingIndicators: { Spinner },
} = components;

const DisableableSpinningSubmitButton = ({ loading, error, handleClick, disabled, children}) => (
  <React.Fragment>
    <ButtonPrimaryLarge
      onClick={handleClick}
      disabled={disabled || loading || error !== undefined}
      className="ml-3"
      isLoading={loading}
    >
      {children}
    </ButtonPrimaryLarge>
  </React.Fragment>
);

export default DisableableSpinningSubmitButton;
