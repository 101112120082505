import gql from 'graphql-tag';

export default gql`
  query searchOrganizations($searchTerm: String!) {
    searchOrganizations(searchTerm: $searchTerm) {
      items {
        orgId
        email
        name
        orgCategory
      }
    }
  }
`;
