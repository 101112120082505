import { TransactionHistoryHeaderAlignmentMap, TransactionHistoryColumnHeaders, ActionButtonMap } from './types/constants';

export const TRANSACTION_HISTORY_COLUMN_HEADERS: TransactionHistoryColumnHeaders = [
  'ID',
  'Date',
  'Type',
  'Details',
  'Dollar Amount',
  'Credit Amount',
  'Credit Balance',
  'P.O.S.',
  'Deal',
  '',
];

export const TRANSACTION_HISTORY_HEADER_ALIGNMENT_MAP: TransactionHistoryHeaderAlignmentMap = {
  'ID': 'left',
  'Date': 'left',
  'Type': 'left',
  'Details': 'left',
  'Dollar Amount': 'center',
  'Credit Amount': 'center',
  'Credit Balance': 'center',
  'P.O.S.': 'right',
  'Deal': 'right',
  '': 'right',
};

export const ACTION_BUTTON_MAP: ActionButtonMap = {
  'view-credit-purchase-receipt': {
    label: 'View Receipt',
    path: 'receipt',
  },
  'view-flyer-purchase-details': {
    label: 'View Details',
    path: 'details',
  },
  'remove-credits': {
    label: 'Remove Credits',
    path: '',
  },
  'refund-purchase': {
    label: 'Refund Purchase',
    path: '',
  },
  'return-credits': {
    label: 'Return Credits',
    path: '',
  },
};
