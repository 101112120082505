function getConfig(domain) {
  switch (domain) {
    case 'internal.peachjar.com':
      return {
        BASE_DOMAIN: 'peachjar.com',
        ENVIRONMENT: 'production',
        CDN_URL: 'https://cdn-assets.peachjar.com',
        REACT_APP_API_BASE_URL: 'https://internal-bff.peachjar.com',
        REACT_APP_PORTAL_BFF_URL: 'https://portal-bff.peachjar.com/graphql',
        REACT_APP_GOOGLE_AUTH_URL:
          'https://internal-bff.peachjar.com/auth/google',
        REACT_APP_AUTH_URL: 'https://internal-bff.peachjar.com/auth',
        REACT_PORTAL_DASHBOARD_URL: 'https://portal.peachjar.com/dashboard',
        REACT_APP_GRAPHQL_URI: 'https://internal-bff.peachjar.com/graphql',
        REACT_APP_GRAPHQL_NEWBFF_URI:
          'https://internal-bff.peachjar.com/graphql',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://peachjar.com',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.com',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar.com',
        REACT_APP_SCHOOL_ONBOARDING_URL: 'https://join.peachjar.com',
        REACT_APP_SENTRY_API_KEY: '7186a3311bd848fcaf7aec62af09512f',
        REACT_APP_SENTRY_PROJECT_ID: '1217883',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar.com',
      }
    case 'internal.peachjar-kauai.com':
      return {
        BASE_DOMAIN: 'peachjar-kauai.com',
        ENVIRONMENT: 'staging',
        CDN_URL: 'https://cdn-assets.peachjar.com',
        REACT_APP_API_BASE_URL: 'https://internal-bff.peachjar-kauai.com',
        REACT_APP_PORTAL_BFF_URL:
          'https://portal-bff.peachjar-kauai.com/graphql',
        REACT_APP_GOOGLE_AUTH_URL:
          'https://internal-bff.peachjar-kauai.com/auth/google',
        REACT_APP_AUTH_URL: 'https://internal-bff.peachjar-kauai.com/auth',
        REACT_PORTAL_DASHBOARD_URL:
          'https://portal.peachjar-kauai.com/dashboard',
        REACT_APP_GRAPHQL_URI:
          'https://internal-bff.peachjar-kauai.com/graphql',
        REACT_APP_GRAPHQL_NEWBFF_URI:
          'https://internal-bff.peachjar-kauai.com/graphql',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://peachjar-staging.com',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar-kauai.com',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar-kauai.com',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar-kauai.com',
        REACT_APP_SCHOOL_ONBOARDING_URL: 'https://join.peachjar-kauai.com',
        REACT_APP_SENTRY_API_KEY: '7186a3311bd848fcaf7aec62af09512f',
        REACT_APP_SENTRY_PROJECT_ID: '1217883',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar-kauai.com',
      }
    default:
      return {
        BASE_DOMAIN: 'peachjar.net:10443',
        ENVIRONMENT: 'development',
        CDN_URL: 'https://cdn-assets.peachjar.com',
        REACT_APP_API_BASE_URL: 'https://internal-bff.peachjar.net:10443',
        REACT_APP_GOOGLE_AUTH_URL:
          'https://internal-bff.peachjar.net:10443/auth/google',
        REACT_APP_AUTH_URL: 'https://internal-bff.peachjar.net:10443/auth',
        REACT_APP_PORTAL_BFF_URL:
          'https://portal-bff.peachjar.net:10443/graphql',
        REACT_PORTAL_DASHBOARD_URL:
          'https://portal.peachjar.net:10443/dashboard',
        REACT_APP_GRAPHQL_URI:
          'https://internal-bff.peachjar.net:10443/graphql',
        REACT_APP_GRAPHQL_NEWBFF_URI:
          'https://internal-bff.peachjar.net:10443/graphql',
        REACT_APP_PEACHJAR_HOMEPAGE: 'https://peachjar.net:10443',
        REACT_APP_ACCOUNTS_APP_URL: 'https://accounts.peachjar.net:10443',
        REACT_APP_INTERNAL_APP_URL: 'https://internal.peachjar.net:10443',
        REACT_APP_PORTAL_APP_URL: 'https://portal.peachjar.net:10443',
        REACT_APP_SCHOOL_ONBOARDING_URL: 'https://join.peachjar.net:10443',
        REACT_APP_SENTRY_API_KEY: 'e452b76ede9f4fd3890d418ea13087aa',
        REACT_APP_SENTRY_PROJECT_ID: '1186595',
        REACT_APP_PARENT_APP_URL: 'https://app.peachjar.net:10443',
      }
  }
}

export default getConfig(window.location.hostname)
