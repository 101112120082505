import React from 'react';

const PeachjarLogo = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    style={{ fill: 'currentColor' }}
  >
    <path
      d="M12,2C6.477,2,2,6.477,2,12s4.477,10,10,10s10-4.477,10-10S17.523,2,12,2z M11.95,18c-3.312,0-6-2.688-6-6s2.688-6,6-6
        c1.62,0,2.976,0.594,4.014,1.566L14.26,9.222c-0.432-0.408-1.188-0.888-2.31-0.888c-1.986,0-3.606,1.65-3.606,3.672
        c0,2.022,1.62,3.672,3.606,3.672c2.298,0,3.144-1.59,3.3-2.532h-3.306V13.14v-2.232h5.616c0.084,0.378,0.15,0.732,0.15,1.23
        C17.71,15.564,15.412,18,11.95,18z"
    />
  </svg>
);

export default PeachjarLogo;
