import React from 'react';
import { css } from 'react-emotion';
import { colors, elements } from '@peachjar/components';
import InfoPopover from '../../shared/components/InfoPopover';
import { padStart } from 'lodash';
import { closeCheckDetailDrawer } from '../../SchoolOnboarding/reducer';
import TargetTransactionGroupsQuery from './TargetTransactionGroup.graphql';
import bffClient from '../../_middleware/fetch/bffApolloClient';
import { useQuery } from '@apollo/react-hooks';


const { jungle } = colors;
const { Note, Paragraph } = elements.typography;
const REGULAR_PRICE = 500;

const styles = {
  noteLink: css`
    display: flex;
    justify-content: start;
    align-items: center;
    color: ${jungle};

    &:hover, &:hover > * {
      color: #C5E8C9;
      cursor: pointer;
    }
  `,
  noteIcon: css`
    font-size: 13px;
  `,
  notePopoverContent: css`
    padding: 16px 25px;
  `,
};

const convertToSmallId = (sequenceId: number, deals: any, deltas: any): string => {
  let seqId = sequenceId;
  const dollarDeal = getDollarDeal(deals);
  deals = deals.filter((deal) => deal.description! && deal.description.includes('Credits'));
  if (deals!.length > 0) {
    const dealsFirstUnitPrice = ((REGULAR_PRICE * deals[0].count - deals[0].savingsInCents) / deals[0].count) 
      * (1 - dollarDeal.percent);
    if (deltas.length < deals.length && deltas[0].unitPrice !== Math.floor(dealsFirstUnitPrice)) {
      seqId = sequenceId - 1;
    }
  }
  return padStart(seqId.toString(36), 7, '0');
};

type DollarDeal = {
  percent: number,
  description: string,
};

const getDollarDeal = ( deals: any): DollarDeal => {
  const dollarDealObj: DollarDeal = {
    percent: 0,
    description: '',
  };

  const hasDollarDeal = deals.filter((deal) => deal.description! && !deal.description.includes('Credits'));
  const dollarDeal = hasDollarDeal.length > 0 ? hasDollarDeal[0].description.split('%')[0] : '0';
  dollarDealObj.description = hasDollarDeal.length > 0 ? `- dollar ${hasDollarDeal[0].description}` : '';
  dollarDealObj.percent = dollarDeal.length > 0 ? parseFloat(dollarDeal) / 100 : 0;

  return dollarDealObj;
};

const dealCheck = ( deals: any, deltas: any, index: number): string => {
  // const hasDollarDeal = deals.filter((deal) => deal.description! && !deal.description.includes('Credits'));
  // const dollarDeal = hasDollarDeal.length > 0 ? hasDollarDeal[0].description.split('%')[0] : '0';
  // const hasDollarDealDescription = hasDollarDeal.length > 0 ? `- dollar ${hasDollarDeal[0].description}` : '';
  // const dollarDealPercent: number = dollarDeal.length > 0 ? parseFloat(dollarDeal) / 100 : 0;
  const dollarDeal = getDollarDeal(deals);
  deals = deals.filter((deal) => deal.description! && deal.description.includes('Credits'));
  const dealIndex = deals.reduce((acc, deal, ind) => {
    const dealsCurrentUnitPrice = ((REGULAR_PRICE * deal.count - deal.savingsInCents) / deal.count)
       * (1 - dollarDeal.percent);
    if (ind <= (deltas.length - 1) &&  
      deltas[ind].unitPrice !== Math.floor(dealsCurrentUnitPrice)) {
      acc += 1;
    }
    return acc;
  }, index);
  let name = '----';
  let description = '';
  if ((dealIndex + 1) <= deals.length) {
    name = deals[dealIndex].name;
    description = deals[dealIndex].description;
  }
  return deals!.length > 0 ? `${name} ${description} ${dollarDeal.description}` : '----';
};

const formatToDecimal = (amount: number): string => {
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formattedDollars = Number(amount / 100).toLocaleString(
    'en',
    formatOptions
  );
  return formattedDollars;
};

const NoteLink = () => (
  <div className={`ml-2 ${styles.noteLink}`}>
    <span className={`material-icons mr-1 ${styles.noteIcon}`}>message</span>
    <Note className={styles.noteLink}>Note</Note>
  </div>
);

const NotePopoverContent = function ({ note, deals, deltas, creditHolderInfo, transaction }) {
  if (transaction.type === 'credits-returned') {
    const {
      loading: transactionRefundLoading,
      error: transactionRefundError,
      data: transactionRefundData
    } = useQuery(TargetTransactionGroupsQuery, {
      client: bffClient,
      variables: {
        input: {
          transactionGroupId: transaction.transactionGroupId,
          creditHolder: {
            id: creditHolderInfo.creditHolderId,
            type: creditHolderInfo.creditHolderType,
          }
        }
      }
    });

    const purchaseIDs = transactionRefundData && transactionRefundData.getTargetTransactionGroups.targetRefEntries;

    if (!transactionRefundLoading && !transactionRefundError) {
      return (
          <div className={styles.notePopoverContent}>
            <div><Note muted>Note:</Note></div>
            <Note>{note}</Note>
            <div><Note muted>Credit Purchase Transaction IDs:</Note></div>
            <div>
              <Note>
                {transactionRefundData && purchaseIDs.map((refEntry, index) => {
                  return (
                      <>#{refEntry.seqId}{(purchaseIDs.length != 1 && index != purchaseIDs.length - 1) ? ', ' : ''}</>
                  );
                })

                }
              </Note>
            </div>
          </div>
      );
    } else {
      return (<>Error retrieving return purchase IDs.</>);
    }
  } else {
    return (
        <div className={styles.notePopoverContent}>
          <div><Note muted>Note:</Note></div>
          <Note>{note}</Note>
          <div>
            <div><Note muted>___________________________</Note></div>
            <div><Note muted>Transaction:</Note></div>
            <Note> {(deltas && deltas.length > 0) ? convertToSmallId(deltas[0].targetRefId, deals, deltas)
                : 'N/A'}</Note>
            {deltas && deltas.map((delta, index) => (
                <div>
                  <div><Note muted>___________________________</Note></div>
                  <div><Note muted>Deal:</Note></div>
                  <Note>{dealCheck(deals, deltas, index)}</Note>
                  <div><Note muted>___________________________</Note></div>
                  <div><Note muted>Credits Refunded:</Note></div>
                  <Note>{-delta.amountInCredits}</Note>
                  <div><Note muted>Refund Amount:</Note></div>
                  <Note>${formatToDecimal(-delta.amountInCents)}</Note>
                  <div><Note muted>Cost Per Credit:</Note></div>
                  <Note>${formatToDecimal(delta.unitPrice)}</Note>
                </div>
            ))}
          </div>
        </div>
    );
  }
}

const NoteTooltip = ({ note, deals, deltas, creditHolderInfo, transaction }) => {
  return (
    <InfoPopover content={<NoteLink />} popoverContent={
    <NotePopoverContent note={note} deals={deals} deltas={deltas} transaction={transaction} creditHolderInfo={creditHolderInfo} />}/>
  );
};

export default NoteTooltip;
