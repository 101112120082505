import React from "react";
import { isEmpty } from "lodash";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import * as Yup from "yup";
import { withFormik } from "formik";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import FormHelperText from "@material-ui/core/FormHelperText";

import { elements, components, colors } from "@peachjar/components";
import DisableableMutationButton from "./DisableableMutationButton";

import { Transaction, RefundTransaction } from "../Management";
import { ITransactionAndDeltaAction, DetailAction } from '../types/Actions';

import { formatCamelCaseSentence } from "../../SchoolOnboarding/PurchasingGrid/components/CreateAndApplyDiscount/util/Words";
import { Item } from "../../Transactions/types/Actions";

const { gold, slate, prussian, platinum, dragon } = colors;
const { ButtonFlatLarge } = components.Buttons;
const { TextInput, Notifications, Dropdown } = components;
const { Spinner } = components.LoadingIndicators;
import { formatAmountInCents } from "./Table";
const {
  typography: { Label, Note }
} = elements;
const { notifyWarn, notifySuccess } = Notifications;

import bffClient from "../../_middleware/fetch/bffApolloClient";
import { dedentBlockStringValue } from "graphql/language/blockString";
import { valueFromAST } from "graphql";

const MISSED_FIELD = "You missed this field.";
const ENTER_200 = "Enter 200 characters or less.";
const ENTER_WHOLE = "Enter a whole number greater than 0.";
const ENTER_500 = "Enter 500 credits or less.";
const ENTER_LESS = v =>
  `Only ${v} credits are available, enter ${v} credits or less.`;

const REGULAR_PRICE = 500;

const integerReg = new RegExp('^[0-9]+$');

export const REASONS_TO_REMOVE = [4, 5, 6, 8, 10];
export const REASONS_TO_ADD = [1, 2, 3, 7, 9, 11];

// export const REASONS_TO_REFUND = [4, 7, 8];
export const REASONS_TO_REFUND = [1, 2, 3, 4, 5, 6, 7, 8];

export enum ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  REFUND = 'REFUND'
}

type Classes = { [key: string]: string };

type CreditDrawerState = { transaction: RefundTransaction, action: ITransactionAndDeltaAction };

type SetCreditDrawerState = React.Dispatch<React.SetStateAction<null | { [key: string]: any }>>;

type ShowMessage = (msg) => void;

type FormState = { [key: string]: string };

type AddRemoveRefundCreditsSaveButton = {
  transactionsRefetch(): void
  setCreditDrawerState: SetCreditDrawerState
  showSuccessMessage: ShowMessage
  showErrorMessage: ShowMessage
  isSubmitting: boolean
  touched: FormState
  errors: FormState
  clearDrawer(): void
  mutation: string
  variables: any
  drawerType: ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE
}

export const AddRemoveRefundCreditsSaveButton = ({
  transactionsRefetch,
  setCreditDrawerState,
  showSuccessMessage,
  showErrorMessage,
  isSubmitting,
  touched,
  errors,
  clearDrawer,
  mutation,
  variables,
  drawerType,
}) => {

  return (
    <DisableableMutationButton
      handleSuccess={() => {
        transactionsRefetch();
        setCreditDrawerState(null);
        showSuccessMessage(`Credits have been ${drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD ? 'added' : drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE ? 'removed' : 'refunded'}.`);
        clearDrawer();
      }}
      handleError={() => {
        setCreditDrawerState(null);
        showErrorMessage(`Unable to ${drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD ? 'add' : drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE ? 'remove' : 'refund'} credits. Please try again.`);
      }}
      mutation={mutation}
      variables={variables}
      disabled={
        isSubmitting || isEmpty(Object.keys(touched)) || !isEmpty(errors)
      }
    >
      Save
    </DisableableMutationButton>
  );
};

type AddRemoveRefundCreditsHeaderProps = {
  drawerType: string
};

export const AddRemoveRefundCreditsHeader = ({ drawerType }: AddRemoveRefundCreditsHeaderProps) => {
  return (
    <Label id='headerLabel'>
      {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD && <>Add Credits</>}
      {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE && (
        <>Remove Credits</>
      )}
      {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND && (
        <>Issue Refund</>
      )}
    </Label>
  );
};

type RemoveCreditsNoteProp = {
  classes: Classes
  creditDrawerState: CreditDrawerState
  dateStr: string
};

export const RemoveCreditsNote = ({
  classes,
  creditDrawerState,
  dateStr
}: RemoveCreditsNoteProp)  => {
  return (
    <div className={classes.transaction}>
      <div>
        <Note style={{ color: slate }}>
          Transaction:{" "}
        </Note>
        <Note style={{ color: prussian }}>
          #{creditDrawerState.transaction.id}
        </Note>
      </div>
      <div>
        <Note style={{ color: slate }}>Date: </Note>
        <Note style={{ color: prussian }}>
          {dateStr}
        </Note>
      </div>
      <div>
        <Note style={{ color: slate }}>
          Available Credits:{" "}
        </Note>
        <Note style={{ color: prussian }}>
          {creditDrawerState.action.details.amountInCredits}
        </Note>
      </div>
    </div>
  );
};
const checkValidRefund = (creditDrawerState: CreditDrawerState): Item[] | any => {
  const refund = {
    amountInCredits: 0,
    amountInCents: 0,
    unitPrice: 0,
    targetRefId: 0,
    creditDelta: 0,
  };
  const refunds: Item[] = [];
  const deals = creditDrawerState.transaction.extraInfo.deals.filter((deal) => 
    deal.description! && deal.description!.includes('Credits'));
  const dealsCreditCount = deals.reduce((acc, value) => acc += value.count, 0);
  const itemDetails = creditDrawerState.transaction.actions[1].itemDetails;
  if (deals.length === itemDetails.length && dealsCreditCount === creditDrawerState.transaction.amountInCredits) {
    itemDetails.map( item => refunds.push(Object.assign({}, item)));
  } else if (deals.length > 0 || deals.length > itemDetails.length) {
    let modIndex = 0;
    deals.map( (deal, index) => {
      modIndex = index > itemDetails.length - 1 ? itemDetails.length - 1 : index;
      // get dealUnitPrice
      const unitPrice = calculateDealUnitPrice(creditDrawerState, index, false);
      if (unitPrice !== itemDetails[modIndex].unitPrice || unitPrice === REGULAR_PRICE) {
        refunds.push(refund);
      } else {
        refunds.push(Object.assign({}, itemDetails[modIndex]));
      }
    });
  }

  if (isNoDeal(creditDrawerState)) {
    refunds.push(Object.assign({}, itemDetails[deals.length]));
  }
  return refunds;
};

const getAvailableCredits = (creditDrawerState: CreditDrawerState, index): string | any => {
  // check if this deal credit is available
  const refunds = checkValidRefund(creditDrawerState);
  return refunds[index].amountInCredits;
};

const getAvailableAmount = (creditDrawerState: CreditDrawerState, index): string | any => {
  const refunds = checkValidRefund(creditDrawerState);
  const refundAmount = refunds[index].amountInCents;
  return formatDollar(refundAmount);
};

const formatDollar = (value): string | any => {
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formattedDollars = Number(Math.floor(value) / 100).toLocaleString(
    'en',
    formatOptions
  );
  return `$${formattedDollars}`;
};

const calculateDealUnitPrice = (creditDrawerState: CreditDrawerState, index: number, format = true): string | any => {
  let unitPrice = 0;
  
  // check any dollar deals
  const creditDeals = creditDrawerState!.transaction!.extraInfo.deals.filter((deal) => 
      deal.description! && deal.description.includes('Credits'));
  const dollarDeal = creditDrawerState!.transaction!.extraInfo.deals.filter((deal) => 
    deal.description! && !deal.description!.includes('Credits'));
  const dollarPercent = dollarDeal.length > 0 ? dollarDeal[0].description.split('%')[0] : '0';
  const dollarDealPercent: number = dollarDeal.length > 0 ? parseFloat(dollarPercent) / 100 : 0;

  if (creditDrawerState!.transaction!.actions[1].itemDetails.length === 
    creditDrawerState!.transaction!.extraInfo.deals.filter((deal) => 
     deal.description! && deal.description.includes('Credits')).length && !isNoDeal(creditDrawerState)) {
      unitPrice = creditDrawerState!.transaction!.actions[1].itemDetails[index].unitPrice;
  } else {
      unitPrice = ((REGULAR_PRICE * creditDeals[index].count - 
      creditDeals[index].savingsInCents) / creditDeals[index].count) * (1 - dollarDealPercent);
  }
  return format ? formatDollar(unitPrice) : Math.floor(unitPrice);
};

const isNoDeal = (creditDrawerState: CreditDrawerState): boolean => {
  const creditDeals = creditDrawerState!.transaction!.extraInfo.deals.filter((deal) => 
    deal.description! && deal.description!.includes('Credits'));
  const dealsCount = creditDeals.reduce((acc, value) => acc += value.count, 0);
  const amountInCredits = creditDrawerState.transaction.amountInCredits;
  return dealsCount < amountInCredits;
};

const noDealItem = (creditDrawerState: CreditDrawerState) => {
  const itemDetails = creditDrawerState.transaction.actions[1].itemDetails;
  const dealsCount = creditDrawerState.transaction.extraInfo.deals.filter((deal) => 
    deal.description! && deal.description.includes('Credits')).length;
  const index = itemDetails.length > dealsCount ? dealsCount : 0;
  return creditDrawerState.transaction.actions[1].itemDetails[index];
};

const calculateCreditRefundAmount = (credits: string, creditDrawerState: CreditDrawerState): string | any => {
  let refundCredits = parseInt(credits, 10);
  if (typeof credits === 'undefined' || credits === '' || refundCredits === 0) {
    return `$0`;
  }
  let spentCredits = refundCredits;
  let refundAmount = 0;
  if (creditDrawerState && creditDrawerState.transaction.actions) {
      refundAmount = creditDrawerState!.transaction!.actions[1].itemDetails.reduce((acc, item) => { 
      const unitPrice = item.unitPrice;
      spentCredits = refundCredits >= item.amountInCredits ? item.amountInCredits : refundCredits;
      refundCredits = refundCredits - spentCredits;
      return acc += spentCredits * unitPrice;
    }, 0 );
  }
  return formatDollar(refundAmount);
};

type IssueRefundNoteProp = {
  classes: Classes
  creditDrawerState: CreditDrawerState
  dateStr: string
};

export const IssueRefundNote = ({
  classes,
  creditDrawerState,
  dateStr,
}: IssueRefundNoteProp, )  => {
  return (
    <div className={classes.transaction}>
      <div>
        <div>
          <Note style={{ color: slate }}>
            Transaction:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            #{creditDrawerState.transaction.id}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Total Available Credits:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {creditDrawerState.transaction.actions[1].details.amountInCredits}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Total Refund Amount:{" "}
          </Note>
          <Note style={{ color: prussian }}>
          {formatAmountInCents(creditDrawerState.transaction.type, 
            creditDrawerState.transaction.actions[1].details.amountInCents)}
        </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>Date: </Note>
          <Note style={{ color: prussian }}>
            {dateStr}
          </Note>
        </div>
      </div>
      <div>
        <Note style={{ color: prussian }}>
        _____________________________
        </Note>
      </div>
      {creditDrawerState.transaction.extraInfo.deals.filter((deal) => 
        deal.description! && deal.description.includes('Credits')).map( (deal, index: number) => 
      <div >
        <div>
          <Note style={{ color: slate }}>
            Deal:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {deal.name} -- 
          </Note>
          <Note style={{ color: prussian }}>
            {deal.description}
          </Note>
          <Note style={{ color: prussian }}>
            {creditDrawerState.transaction.extraInfo.deals.filter((deal) => 
              deal.description! && !deal.description.includes('Credits'))
              .map((deal) => ` + dollar ${deal.description} `)}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Available Credits:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {getAvailableCredits(creditDrawerState, index)}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Refund Amount:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {getAvailableAmount(creditDrawerState, index)}
        </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Cost Per Credit:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {calculateDealUnitPrice(creditDrawerState, index)}
          </Note>
        </div>
      </div>
      )}
      {isNoDeal(creditDrawerState) &&
      <div >
        <div>
          <Note style={{ color: slate }}>
            Deal:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {} -- 
          </Note>
          <Note style={{ color: prussian }}>
            {}
          </Note>
          <Note style={{ color: prussian }}>
            {creditDrawerState.transaction.extraInfo.deals.filter((deal) => 
              deal.description! && !deal.description.includes('Credits'))
              .map((deal) => ` + dollar ${deal.description} `)}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Available Credits:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {noDealItem(creditDrawerState).amountInCredits}
          </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Refund Amount:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {formatDollar(noDealItem(creditDrawerState).amountInCents)}
        </Note>
        </div>
        <div>
          <Note style={{ color: slate }}>
            Cost Per Credit:{" "}
          </Note>
          <Note style={{ color: prussian }}>
            {formatDollar(noDealItem(creditDrawerState).unitPrice)}
          </Note>
        </div>
      </div>
      }
    </div>
  );
};

type AddRemoveRefundCreditsDrawerProps = {
  classes: Classes
  creditDrawerState?: CreditDrawerState
  creditHolderId: string
  creditHolderType: string
  drawerType: string
  errors: FormState
  isSubmitting: boolean
  touched: FormState
  values: FormState
  mockAddCreditReasons?: { loading: boolean, error: any, data: any}
  mockRemoveCreditReasons?: { loading: boolean, error: any, data: any}
  mockRefundCreditReasons?: { loading: boolean, error: any, data: any}
  setCreditDrawerState: SetCreditDrawerState
  handleBlur(e: React.FocusEvent<any>): void
  handleChange(e: React.ChangeEvent<any>): void
  handleSubmit(e?: React.FormEvent<any>): void
  setShowAddCredits(showAddCredits: boolean): void
  setTouched(isTouched: { [key: string]: boolean }): void
  showErrorMessage: ShowMessage
  showSuccessMessage: ShowMessage
  transactionsRefetch(): void
  resetForm(nextInitialState?: any): void
};

export const AddRemoveRefundCreditsDrawer = ({
  transactionsRefetch,

  showErrorMessage,
  showSuccessMessage,

  classes,

  drawerType,

  setCreditDrawerState,
  creditDrawerState,

  creditHolderId,
  creditHolderType,

  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setTouched,
  resetForm,

  mockAddCreditReasons,
  mockRemoveCreditReasons,
  mockRefundCreditReasons,
}: AddRemoveRefundCreditsDrawerProps) => {
  const {
    loading: loadingAddCreditReasons,
    error: errorAddCreditReasons,
    data: dataAddCreditReasons
  } = mockAddCreditReasons ? mockAddCreditReasons : useQuery(addCreditReasonsQuery, { client: bffClient });
  const {
    loading: loadingRemoveCreditReasons,
    error: errorRemoveCreditReasons,
    data: dataRemoveCreditReasons
  } = mockRemoveCreditReasons ? mockRemoveCreditReasons : useQuery(removeCreditReasonsQuery, { client: bffClient });
  const {
    loading: loadingRefundCreditReasons,
    error: errorRefundCreditReasons,
    data: dataRefundCreditReasons
  } = mockRefundCreditReasons ? mockRefundCreditReasons : useQuery(refundCreditReasonsQuery, { client: bffClient });
  if (loadingAddCreditReasons || loadingRemoveCreditReasons || loadingRefundCreditReasons ) {
    return <Spinner />;
  }

  if (errorAddCreditReasons || errorRemoveCreditReasons || errorRefundCreditReasons) {
    return "An error occurred loading reasons to add or remove or refund credits.";
  }

  function toOptionsFormat(r) {
    return { value: r.id, label: r.reason };
  }
  function addReasons(r) {
    return REASONS_TO_ADD.includes(r.value);
  }
  function removeReasons(r) {
    return REASONS_TO_REMOVE.includes(r.value);
  }
  function refundReasons(r) {
    return REASONS_TO_REFUND.includes(r.value);
  }

  const addCreditsOptions = dataAddCreditReasons.addCreditReasons.reasons
                              .map(toOptionsFormat)
                              .filter(addReasons);
  const removeCreditsOptions = dataRemoveCreditReasons.removeCreditReasons.reasons
                              .map(toOptionsFormat)
                              .filter(removeReasons);
  const issueRefundOptions = dataRefundCreditReasons.refundCreditReasons.reasons
                              .map(toOptionsFormat)
                              .filter(refundReasons);
  const { creditAmount, reason, note } = values;

  let dateStr = '';
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (creditDrawerState && creditDrawerState.transaction) {
    const d = new Date(creditDrawerState.transaction.timestamp);
    dateStr = `${months[d.getMonth()]} ${('0' + d.getDate()).slice(
      -2
    )}, ${d.getFullYear()}`;
  }

  function clearDrawer() {
    resetForm();
  }

  return (
    <Drawer
      anchor='right'
      open={!!creditDrawerState}
      onClose={() => {
        return;
      }}
    >
      <ClickAwayListener
        onClickAway={e => {
          // The onClickAway event handler should only fire for elements clicked
          // on that are outside of the ClickAwayListener element tree. This is ≈
          // firing on a few unexpected elements as well which are excluded below
          try {
            const el = e.target as any;
            if (
              el.getAttribute('role') !== 'listbox' &&
              el.getAttribute('role') !== 'document' &&
              el.parentElement.getAttribute('role') !== 'option' &&
              el.parentElement.id !== 'menu-' &&
              el.parentElement.parentElement.parentElement.id !== 'menu-'
            ) {
              setCreditDrawerState(null);
              clearDrawer();
            }
          } catch (e) {
            setCreditDrawerState(null);
            clearDrawer();
          }
        }}
      >
        <div className={classes.container}>
          <form onSubmit={handleSubmit}>
            <div className={classes.header}>
              <AddRemoveRefundCreditsHeader drawerType={drawerType} />
            </div>
            <div className={classes.body}>
              <FormControl className={classes.formControl}>
                {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE &&
                  creditDrawerState &&
                  creditDrawerState.transaction && (
                    <RemoveCreditsNote
                      classes={classes}
                      creditDrawerState={creditDrawerState}
                      dateStr={dateStr}
                    />
                  )}
                {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND &&
                  creditDrawerState &&
                  creditDrawerState.transaction && (
                    <IssueRefundNote
                      classes={classes}
                      creditDrawerState={creditDrawerState}
                      dateStr={dateStr}
                    />
                  )}
                <div className={classes.formElement}>
                  <TextInput
                    name='creditAmount'
                    id='creditAmount'
                    fullWidth
                    placeholder=''
                    label='Credit Amount'
                    value={creditAmount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      errors.creditAmount && touched.creditAmount
                    }
                    errorText={formatCamelCaseSentence(errors.creditAmount)}
                  />
                </div>
                {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND &&
                  creditDrawerState &&
                  creditDrawerState.transaction && (
                  <div className={classes.formElement}>
                    <TextInput
                      name='refundAmount'
                      id='refundAmount'
                      fullWidth
                      enable={false}
                      placeholder=''
                      label='Refund Amount'
                      value={calculateCreditRefundAmount(creditAmount, creditDrawerState)}
                    />
                  </div>
                  )}
                <div
                  className={classes.formElement}
                  onClick={e => {
                    // Possible work around to show validation messaging with no
                    // onBlur handler for the Dropdown component
                    setTouched({ reason: true });
                  }}
                >
                  <Dropdown
                    fullWidth
                    width='100%'
                    name='reason'
                    id='reason'
                    data-testid='reasonLabel'
                    label='Reason'
                    placeholder='Reason'
                    value={reason}
                    onChange={e => {
                      values.reason = e.target.value;
                    }}
                    error={errors.reason && touched.reason}
                    errorText={formatCamelCaseSentence(errors.reason)}
                    options={drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE ? removeCreditsOptions 
                      : ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD ? addCreditsOptions : issueRefundOptions}
                  />
                </div>

                <div className={classes.bottomFormElement}>
                  <TextInput
                    name='note'
                    id='note'
                    fullWidth
                    multiline
                    placeholder=''
                    label='Note'
                    value={note}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.note && touched.note}
                  />
                  <FormHelperText className={classes.noteHelperText}>
                    <div className={'layout-row layout-align-space-between'}>
                      {errors.note && touched.note && (
                        <Note style={{ color: colors.dragon }}>
                          {formatCamelCaseSentence(errors.note)}
                        </Note>
                      )}
                      <Note
                        className={classes.noteLengthHelperText}
                        style={{ color: colors.slate }}
                      >{`${values.note.length} / 200`}</Note>
                    </div>
                  </FormHelperText>
                </div>
              </FormControl>
              <div className={classes.buttonsWrapper}>
                <ButtonFlatLarge
                  onClick={e => {
                    setCreditDrawerState(null);
                    clearDrawer();
                  }}
                >
                  Cancel
                </ButtonFlatLarge>
                <div
                  className={classes.submitWrapper}
                  onClick={e => {
                    handleSubmit();
                  }}
                >
                  {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.ADD && (
                    <AddRemoveRefundCreditsSaveButton
                      transactionsRefetch={transactionsRefetch}
                      setCreditDrawerState={setCreditDrawerState}
                      showSuccessMessage={showSuccessMessage}
                      showErrorMessage={showErrorMessage}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      clearDrawer={clearDrawer}
                      mutation={addCreditsMutation}
                      variables={{
                        input: {
                          note,
                          reasonId: parseInt(reason, 10),
                          numberOfCredits: parseInt(creditAmount, 10),
                          labels: {},
                          creditHolder: {
                            id: creditHolderId,
                            type: creditHolderType
                          }
                        }
                      }}
                      drawerType={drawerType}
                    />
                  )}
                  {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE && (
                    <AddRemoveRefundCreditsSaveButton
                      transactionsRefetch={transactionsRefetch}
                      setCreditDrawerState={setCreditDrawerState}
                      showSuccessMessage={showSuccessMessage}
                      showErrorMessage={showErrorMessage}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      clearDrawer={clearDrawer}
                      mutation={removeCreditsMutation}
                      variables={{
                        input: {
                          note,
                          reasonId: parseInt(reason, 10),
                          creditAmount: parseInt(creditAmount, 10),
                          labels: {},
                          creditHolder: {
                            id: creditHolderId,
                            type: creditHolderType
                          },
                          targetTransactionGroupId:
                            creditDrawerState &&
                            creditDrawerState.transaction &&
                            creditDrawerState.transaction.transactionGroupId
                        }
                      }}
                      drawerType={drawerType}
                    />
                  )}
                  {drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND && (
                    <AddRemoveRefundCreditsSaveButton
                      transactionsRefetch={transactionsRefetch}
                      setCreditDrawerState={setCreditDrawerState}
                      showSuccessMessage={showSuccessMessage}
                      showErrorMessage={showErrorMessage}
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      clearDrawer={clearDrawer}
                      mutation={refundCreditsMutation}
                      variables={{
                        input: {
                          note,
                          reason: parseInt(reason, 10),
                          numberOfCredits: parseInt(creditAmount, 10),
                          creditHolder: {
                            id: creditHolderId,
                            type: creditHolderType
                          },
                          targetTransactionGroupId:
                            creditDrawerState &&
                            creditDrawerState.transaction &&
                            creditDrawerState.transaction.transactionGroupId
                        }
                      }}
                      drawerType={drawerType}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </ClickAwayListener>
    </Drawer>
  );
};

const addCreditsMutation = gql`
  mutation AddCredits($input: AddCredits) {
    addCredits(input: $input) {
      transactionGroupId
      transactionId
      creditBalance {
        numberOfCredits
        updatedAt
      }
    }
  }
`;

const addCreditReasonsQuery = gql`
  query AddCreditReasons {
    addCreditReasons {
      reasons {
        id
        action
        reason
      }
    }
  }
`;

const removeCreditsMutation = gql`
  mutation RemoveCredits($input: RemoveCredits) {
    removeCredits(input: $input) {
      transactionGroupId
      transactionId
      creditBalance {
        numberOfCredits
        updatedAt
      }
    }
  }
`;

const removeCreditReasonsQuery = gql`
  query RemoveCreditReasons {
    removeCreditReasons {
      reasons {
        id
        action
        reason
      }
    }
  }
`;

const refundCreditsMutation = gql`
  mutation RefundCreditPurchase($input: RefundCreditPurchase) {
    refundCreditPurchase(input: $input) {
      transactionGroupId
      refId
      creditBalance {
        numberOfCredits
        updatedAt
      }
    }
  }
`;

const refundCreditReasonsQuery = gql`
  query RefundCreditReasons {
    refundCreditReasons {
      reasons {
        id
        reason
      }
    }
  }
`;
const styles = theme => ({
  transaction: {
    marginTop: 24,
    marginBottom: 24,
    padding: 16,
    backgroundColor: colors.platinum
  },
  container: {
    width: 468
  },
  header: {
    paddingTop: 32,
    paddingRight: 48,
    paddingBottom: 32,
    paddingLeft: 48,
    backgroundColor: gold
  },
  body: {
    paddingLeft: 24,
    paddingTop: 24,
    paddingBottom: 48,
    paddingRight: 24,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 48
  },
  formElement: {
    height: 80,
    marginBottom: 16,
  },
  bottomFormElement: {
    minHeight: 80,
    marginBottom: 16,
  },
  formControl: {
    width: 420,
  },
  submitWrapper: {
    display: 'inline-block'
  },
  noteHelperText: {
    alignSelf: 'flex-end',
    width: '100%'
  },
  noteLengthHelperText: {
    marginLeft: 'auto'
  },
  dropdownError: {
    '&$error:after': {
      borderBottomColor: colors.dragon
    }
  }
});

const validateForm = withFormik({
  mapPropsToValues: props => ({
    drawerType: '',
    creditDrawerState: '',
    creditAmount: '',
    reason: '',
    note: ''
  }),
  validationSchema: Yup.object().shape({
    creditAmount: Yup.number()
      .integer()
      .min(0)
      // .max(500)
      .required(),
    reason: Yup.string().required(),
    note: Yup.string().required()
  }),

  validate: (values: { [key: string]: string }, props: any) => {
    const errors = {} as { [key: string]: string };
    if (!values.reason) {
      errors.reason = MISSED_FIELD;
    }

    if (!values.note) {
      errors.note = MISSED_FIELD;
    } else if (values.note.length > 200) {
      errors.note = ENTER_200;
    }

    if (values.creditAmount.length === 0) {
      errors.creditAmount = MISSED_FIELD;
    } else if (
      !integerReg.test(values.creditAmount) ||
      parseInt(values.creditAmount, 10) <= 0
    ) {
      errors.creditAmount = ENTER_WHOLE;
    }
    // } else if (parseInt(values.creditAmount, 10) > REGULAR_PRICE) {
    //   errors.creditAmount = ENTER_500;
    // }

    if (props.drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REMOVE) {
      if (
        values.creditAmount >
        props.creditDrawerState.action.details.amountInCredits
      ) {
        errors.creditAmount = ENTER_LESS(
          props.creditDrawerState.action.details.amountInCredits
        );
      }
    }

    if (props.drawerType === ADD_REMOVE_REFUND_CREDITS_DRAWER_TYPE.REFUND) {
      if (
        values.creditAmount >
        props.creditDrawerState.transaction.actions[1].details.amountInCredits
      ) {
        errors.creditAmount = ENTER_LESS(
          props.creditDrawerState.transaction.actions[1].details.amountInCredits
        );
      }
    }
    return errors;
  },
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors
    }
  ) => {
    return;
  }
});

const mapDispatchToProps = dispatch => ({
  showErrorMessage: msg => {
    dispatch(notifyWarn(msg));
  },
  showSuccessMessage: msg => {
    dispatch(notifySuccess(msg));
  }
});

export default withStyles(styles)(
  // @ts-ignore
  validateForm(connect(null, mapDispatchToProps)(AddRemoveRefundCreditsDrawer))
);
