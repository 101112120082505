import { css } from "emotion";
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons';
import colors from '@peachjar/ui/dist/lib/styles/colors';
import getValidHref from "../../utils/getValidHref";
import { LINK_ICONS, LINK_LABELS } from "./FeaturedLinks";
import React from 'react'

type Props = {
  cta: any
}

export default function InteractiveFlyerPrimaryCTA({
  cta
}: Props) {
  const Icon = LINK_ICONS[cta.type]
  return <div className={styles.container}>
    <ButtonPrimaryLarge
      data-testid="primaryCTA"
      startIcon={<Icon width="24" height="24" />}
      href={getValidHref(cta.value, cta.type)}
      rel="noopener noreferrer"
      target={cta.type === "Email" || cta.type === "Call" ? "_self" : "_blank"}
    >
      {LINK_LABELS[cta.type]}
    </ButtonPrimaryLarge>
  </div>
}

const styles = {
  container: css`
    background-color: ${colors.platinum};
    padding: 8px 16px;
    display: flex;
    justify-content: end;
  `
}