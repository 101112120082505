import React from 'react'
import ReactTable from 'react-table'
import ApolloClient from 'apollo-boost'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { Query } from 'react-apollo'
import { components } from '@peachjar/components'
import searchOrganizations from './searchOrganizations.graphql'
import SearchBar from './SearchBar'
import config from '../../config'
import transactionsConfig from '../config'
import noResultsSVG from '../../assets/turtle.svg'
import SearchSvg from '../../Search/components/SearchSvg'
import { Link } from 'react-router-dom'
import SubappHeader from '../../App/components/SubappHeader'

import './transactionsGrid.css.js'
import 'react-table/react-table.css'

const {
  Pagination,
  Buttons: { ButtonSecondarySmall },
  LoadingIndicators: { Spinner },
} = components

export const client = new ApolloClient({
  uri: config.REACT_APP_GRAPHQL_NEWBFF_URI,
  credentials: 'include',
})

const columns = [
  {
    Header: 'Org ID',
    id: 'id',
    accessor: (d) => d.orgId,
    style: { paddingLeft: '32px', paddingTop: '13px' },
    width: 200,
  },
  {
    Header: 'Org Name',
    id: 'companyName',
    accessor: (d) => d.name,
    style: { paddingLeft: '32px', paddingTop: '13px' },
    width: 350,
  },
  {
    Header: 'Email Address',
    id: 'email',
    accessor: (d) => d.email,
    style: { paddingLeft: '32px', paddingTop: '13px' },
    width: 350,
  },
  {
    Header: 'Org Category',
    id: 'orgCategory',
    accessor: (d) => d.orgCategory,
    style: { paddingLeft: '32px', paddingTop: '13px' },
    width: 200,
  },
  {
    Header: '',
    id: 'button',
    accessor: (d) => '',
    style: { paddingLeft: '32px' },
    Cell: (row) => {
      return (
        <Link to={`/transactions/${row.original.orgId}/organization`}>
          <ButtonSecondarySmall className="viewButton" onClick={(event) => {}}>
            View
          </ButtonSecondarySmall>
        </Link>
      )
    },
  },
]

const styles = (theme) => ({
  foundText: {
    fontSize: '1rem',
  },
  noResults: {
    textAlign: 'center',
    paddingBottom: '60px',
  },
  noResultsTxt: {
    fontSize: '16px',
    padding: '20px 0',
    margin: '0 auto',
    width: '370px',
  },
  illustration: {
    textAlign: 'center',
    padding: '60px 0',
  },
  results: {
    marginTop: '8px',
    marginLeft: '16px',
  },
  outerPageContainer: {
    paddingTop: '32px',
    paddingLeft: '16px',
    width: '1364px',
  },
  innerPageContainer: {
    marginTop: '16px',
    padding: '32px',
    backgroundColor: 'white',
  },
  grid: {
    marginBottom: theme.spacing.unit * 2,
    fontSize: 13,
  },
})

function SearchErrorMessage({ classes }) {
  return (
    <div className={classes.noResults}>
      <img src={noResultsSVG} alt="" />
      <p className={classes.noResultsTxt}>
        Uh oh! We can't find the user you are looking for. Please try again.
      </p>
    </div>
  )
}

class GridView extends React.Component {
  state = {
    searchString: '',
  }

  handleChangeSearch = (searchString) => {
    this.setState({ searchString })
  }

  render() {
    const { classes } = this.props
    const { searchString } = this.state
    const queryVariables = {
      searchTerm: searchString || '',
    }

    return (
      <Grid container className={classes.outerPageContainer}>
        <SubappHeader>Transaction Search</SubappHeader>
        <Grid container className={classes.innerPageContainer}>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={9}>
              <SearchBar
                searchString={searchString}
                onSubmit={(newSearchString) =>
                  this.handleChangeSearch(newSearchString)
                }
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {searchString === '' && (
              <div className={classes.illustration}>
                <SearchSvg />
              </div>
            )}
            {searchString !== '' && (
              <Query
                query={searchOrganizations}
                client={client}
                variables={queryVariables}
              >
                {({ loading, error, data }) => {
                  if (loading) {
                    return (
                      <Grid item container xs={12} justify="center">
                        <Spinner />
                      </Grid>
                    )
                  }

                  if (error) {
                    return <>Error querying for users</>
                  }

                  const searchResponseObject = data.searchOrganizations

                  if (!searchResponseObject) {
                    return <>Invalid response</>
                  }

                  if (
                    error ||
                    !searchResponseObject.items ||
                    (searchResponseObject.items &&
                      searchResponseObject.items.length === 0)
                  ) {
                    return <SearchErrorMessage classes={classes} />
                  }

                  if (searchResponseObject) {
                    return (
                      <Grid item xs={12} className={classes.results}>
                        <div className={classes.foundText}>Found:</div>
                        <ReactTable
                          manual
                          className={`${classes.grid} -striped -highlight`}
                          showPagination={false}
                          data={searchResponseObject.items}
                          columns={columns}
                          defaultPageSize={
                            searchResponseObject.items &&
                            searchResponseObject.items.length
                          }
                        />
                      </Grid>
                    )
                  } else {
                    return <SearchErrorMessage classes={classes} />
                  }
                }}
              </Query>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(GridView)
