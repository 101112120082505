const config = require('../../config');

const { ENVIRONMENT } = config.default;

if (ENVIRONMENT === 'development') {
  console.log('Sentry not loaded in development');
  module.exports = () => {};
} else {
  module.exports = require('./configureSentry.js');
}
