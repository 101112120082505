import apolloClient from '../_middleware/fetch/apolloClient';
import updateAgreementMutation from './PurchasingGrid/components/AgreementDetail/updateAgreementMutation.graphql';
import waitingForCheckMutation from './PurchasingGrid/components/CheckDetail/waitingForCheckMutation.graphql';
import checkPaymentMutation from './PurchasingGrid/components/CheckDetail/checkPaymentMutation.graphql';

export const SHOW_ONBOARDING_RECORD = 'SHOW_ONBOARDING_RECORD';
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const OPEN_DISCOUNT_DRAWER = 'OPEN_DISCOUNT_DRAWER';
export const CLOSE_DISCOUNT_DRAWER = 'CLOSE_DISCOUNT_DRAWER';
export const TOGGLE_GROUP_ONLY_DISCOUNT_DRAWER_MODE =
  'TOGGLE_GROUP_ONLY_DISCOUNT_DRAWER';
export const OPEN_AGREEMENT_DRAWER = 'OPEN_AGREEMENT_DRAWER';
export const CLOSE_AGREEMENT_DRAWER = 'CLOSE_AGREEMENT_DRAWER';
export const AGREEMENT_FORM_SAVING = 'AGREEMENT_FORM_SAVING';
export const AGREEMENT_FORM_SAVED = 'AGREEMENT_FORM_SAVED';
export const AGREEMENT_FORM_SAVE_FAILED = 'AGREEMENT_FORM_SAVE_FAILED';
export const OPEN_CHECK_DRAWER = 'OPEN_CHECK_DRAWER';
export const CLOSE_CHECK_DRAWER = 'CLOSE_CHECK_DRAWER';
export const CHECK_FORM_SAVING = 'CHECK_FORM_SAVING';
export const CHECK_FORM_SAVED = 'CHECK_FORM_SAVED';
export const CHECK_FORM_SAVE_FAILED = 'CHECK_FORM_SAVE_FAILED';
export const OPEN_RESUME_LINK_DRAWER = 'OPEN_RESUME_LINK_DRAWER';
export const CLOSE_RESUME_LINK_DRAWER = 'CLOSE_RESUME_LINK_DRAWER';
export const SET_GRID_FILTER = 'SET_GRID_FILTER';
export const OPEN_GET_LINK_DRAWER = 'OPEN_GET_LINK_DRAWER';

const initialState = {
  isRegistrationDetailsDrawerOpen: false,
  manageResumeLink: {
    drawerOpen: false,
    selectedRegistrationId: null,
  },
  manageDiscount: {
    drawerOpen: false,
    isDiscountDrawerGroupOnly: false,
    isGroupOnlyDiscountDrawerEnabled: false,
    selectedNcesDistrictId: null,
    selectedRegistrationId: null,
  },
  manageAgreement: {
    drawerOpen: false,
    selectedRegistrationId: null,
    selectedRegistrationAgreementId: null,
    saveInProgress: false,
    saveError: '',
  },
  manageCheck: {
    drawOpen: false,
    selectedRegistrationId: null,
    selectedRegistrationPaymentStatus: null,
    saveInProgress: false,
    saveError: '',
  },
  views: {},
};

export const onboarding = (state = initialState, action) => {
  switch (action.type) {
    case SET_GRID_FILTER: {
      const { filter, view } = action.data;
      const nextState = { ...state };
      nextState.views[view] = { filter };
      return nextState;
    }

    case SHOW_ONBOARDING_RECORD: {
      return {
        ...state,
        registrationId: action.id,
      };
    }

    case OPEN_DRAWER:
      return {
        ...state,
        [action.drawerOpenState]: true,
      };

    case CLOSE_DRAWER:
      return {
        ...state,
        [action.drawerOpenState]: false,
      };
    // case TOGGLE_DRAWER:
    //   return {
    //     ...state,
    //     [action.drawerOpenState]: !state[drawerOpenState]
    //   };

    case OPEN_RESUME_LINK_DRAWER:
      return {
        ...state,
        manageResumeLink: {
          ...state.manageResumeLink,
          drawerOpen: true,
          selectedRegistrationId: action.registrationId,
        },
      };

    case CLOSE_RESUME_LINK_DRAWER:
      return {
        ...state,
        manageResumeLink: {
          ...state.manageResumeLink,
          drawerOpen: false,
          selectedRegistrationId: null,
        },
      };

    case OPEN_DISCOUNT_DRAWER:
      return {
        ...state,
        manageDiscount: {
          ...state.manageDiscount,
          drawerOpen: true,
          selectedNcesDistrictId: action.ncesDistrictId,
          selectedRegistrationId: action.registrationId,
        },
      };

    case CLOSE_DISCOUNT_DRAWER:
      return {
        ...state,
        manageDiscount: {
          ...state.manageDiscount,
          drawerOpen: false,
          selectedNcesDistrictId: null,
          selectedRegistrationId: null,
        },
      };

    case TOGGLE_GROUP_ONLY_DISCOUNT_DRAWER_MODE:
      return {
        ...state,
        manageDiscount: {
          ...state.manageDiscount,
          isGroupOnlyDiscountDrawerEnabled:
            action.isGroupOnlyDiscountDrawerEnabled,
        },
      };

    case OPEN_AGREEMENT_DRAWER:
      return {
        ...state,
        manageAgreement: {
          ...state.manageAgreement,
          drawerOpen: true,
          selectedRegistrationId: action.registrationId,
          selectedRegistrationAgreementId: action.agreementId,
        },
      };

    case CLOSE_AGREEMENT_DRAWER:
      return {
        ...state,
        manageAgreement: {
          ...state.manageAgreement,
          drawerOpen: false,
          selectedRegistrationId: null,
          selectedRegistrationAgreementId: null,
        },
      };

    case AGREEMENT_FORM_SAVING:
      return {
        ...state,
        manageAgreement: {
          ...state.manageAgreement,
          saveInProgress: true,
          saveError: '',
        },
      };

    case AGREEMENT_FORM_SAVED:
      return {
        ...state,
        manageAgreement: {
          ...state.manageAgreement,
          drawerOpen: false,
          saveInProgress: false,
          saveError: '',
        },
      };

    case AGREEMENT_FORM_SAVE_FAILED:
      return {
        ...state,
        manageAgreement: {
          ...state.manageAgreement,
          saveInProgress: false,
          saveError: action.message,
        },
      };

    case OPEN_CHECK_DRAWER:
      return {
        ...state,
        manageCheck: {
          ...state.manageCheck,
          selectedRegistrationId: action.registrationId,
          selectedRegistrationPaymentStatus: action.status,
          saveInProgress: false,
          saveError: '',
          drawerOpen: true,
        },
      };

    case CLOSE_CHECK_DRAWER:
      return {
        ...state,
        manageCheck: {
          ...state.manageCheck,
          drawerOpen: false,
          selectedRegistrationId: null,
          selectedRegistrationPaymentStatus: null,
        },
      };

    case CHECK_FORM_SAVING:
      return {
        ...state,
        manageCheck: {
          ...state.manageCheck,
          saveInProgress: true,
          saveError: '',
        },
      };

    case CHECK_FORM_SAVE_FAILED:
      return {
        ...state,
        manageCheck: {
          ...state.manageCheck,
          saveInProgress: false,
          saveError: action.message,
        },
      };

    case CHECK_FORM_SAVED:
      return {
        ...state,
        manageCheck: {
          ...state.manageCheck,
          drawerOpen: false,
          saveInProgress: false,
          saveError: '',
        },
      };

    default:
      return state;
  }
};

export default onboarding;

// Action Creators
export function setGridViewFilter({ view, filter }) {
  return {
    type: SET_GRID_FILTER,
    data: { view, filter },
  };
}

export function openDrawer(drawerOpenState) {
  return {
    type: OPEN_DRAWER,
    drawerOpenState,
  };
}

export function closeDrawer(drawerOpenState) {
  return {
    type: CLOSE_DRAWER,
    drawerOpenState,
  };
}

export function toggleDrawer(drawerOpenState) {
  return {
    type: TOGGLE_DRAWER,
    drawerOpenState,
  };
}

export function toggleGroupOnlyDiscountDrawerMode(isEnabled) {
  return {
    type: TOGGLE_GROUP_ONLY_DISCOUNT_DRAWER_MODE,
    isGroupOnlyDiscountDrawerEnabled: isEnabled,
  };
}

export function openResumeLinkDrawer(registrationId) {
  return {
    type: OPEN_RESUME_LINK_DRAWER,
    registrationId,
  };
}

export function closeResumeLinkDrawer() {
  return {
    type: CLOSE_RESUME_LINK_DRAWER,
  };
}

export function openDiscountDetailDrawer(ncesDistrictId, registrationId) {
  return {
    type: OPEN_DISCOUNT_DRAWER,
    ncesDistrictId,
    registrationId,
  };
}

export function closeDiscountDetailDrawer() {
  return {
    type: CLOSE_DISCOUNT_DRAWER,
  };
}

export function openAgreementDetailDrawer(registrationId, agreementId) {
  return {
    type: OPEN_AGREEMENT_DRAWER,
    registrationId,
    agreementId,
  };
}

export function openGetLinkDrawer(registrationId, agreementId) {
  return {
    type: OPEN_GET_LINK_DRAWER,
    registrationId,
    agreementId,
  };
}

export function closeAgreementDetailDrawer() {
  return {
    type: CLOSE_AGREEMENT_DRAWER,
  };
}

export function saveAgreementId(registrationId, agreementId) {
  return dispatch => {
    dispatch({
      type: AGREEMENT_FORM_SAVING,
    });
    apolloClient
      .mutate({
        mutation: updateAgreementMutation,
        variables: {
          input: {
            registrationId,
            agreementId,
          },
        },
      })
      .then(() => {
        // For now, we close the drawer and wait for the polling to update the table
        dispatch({
          type: AGREEMENT_FORM_SAVED,
        });
      })
      .catch(error => {
        dispatch({
          type: AGREEMENT_FORM_SAVE_FAILED,
          message:
            'The agreement ID you entered could not be saved. It could be an invalid docusign document ID, but may also be a different problem. Please double-check your input and try again.',
        });

        // If this was an unexpected error, log it to the console
        if (error.message !== 'Not Found') {
          console.error(error);
        }
      });
  };
}

export function openCheckDetailDrawer(registrationId, status) {
  return {
    type: OPEN_CHECK_DRAWER,
    registrationId,
    status,
  };
}

export function closeCheckDetailDrawer() {
  return {
    type: CLOSE_CHECK_DRAWER,
  };
}

export function saveWaitingForCheck(registrationId) {
  return dispatch => {
    dispatch({
      type: CHECK_FORM_SAVING,
    });
    apolloClient
      .mutate({
        mutation: waitingForCheckMutation,
        variables: {
          registrationId,
        },
      })
      .then(() => {
        // For now, we close the drawer and wait for the polling to update the table
        dispatch({
          type: CHECK_FORM_SAVED,
        });
      })
      .catch(error => {
        dispatch({
          type: CHECK_FORM_SAVE_FAILED,
          message:
            'Check status could not be saved. Please check your input and try again.',
        });

        console.error(error);
      });
  };
}

export function saveCheckPayment({
  registrationId,
  checkNumber,
  amount,
  date,
}) {
  // GraphQL: recordCheckPaymentForRegistration
  return dispatch => {
    dispatch({
      type: CHECK_FORM_SAVING,
    });
    apolloClient
      .mutate({
        mutation: checkPaymentMutation,
        variables: {
          input: {
            registrationId,
            checkNumber, // String!
            amount: Number(amount), // Float!
            date, // String
          },
        },
      })
      .then(() => {
        // For now, we close the drawer and wait for the polling to update the table
        dispatch({
          type: CHECK_FORM_SAVED,
        });
      })
      .catch(error => {
        dispatch({
          type: CHECK_FORM_SAVE_FAILED,
          message:
            'Check payment could not be recorded. Please check your input and try again.',
        });

        console.error(error);
      });
  };
}
