import { AsYouType } from "libphonenumber-js"

const formatter = new AsYouType('US')

export function formatPhoneNumber(phoneNumber: string): string {
  return formatter.input(phoneNumber)
}

const phoneRegex = /^(\d{0,1})(\d{3})(\d{3})(\d{4})$/

export function validatePhoneNumber(phoneNumber: string) {
  const cleanPhoneNumber = phoneNumber.replace(/\s|-|\(|\)|\./g, '')
  return phoneRegex.test(cleanPhoneNumber)
}