import React from 'react'
import DatePicker from './DatePicker'
import utcDateToMidnightLocal from '../../utils/utcDateToMidnightLocal'
import localDateToMidnightUTC from '../../utils/localDateToMidnightUTC'

const noop = () => {}

const UTCDatePicker = ({
  label,
  name,
  value,
  onChange,
  minDate,
  maxDate,
  error,
}) => {
  const onChangeUTC = (date) => {
    const utcDate = localDateToMidnightUTC(date)
    return onChange(utcDate)
  }

  return (
    <DatePicker
      label={label}
      name={name}
      value={utcDateToMidnightLocal(value)}
      onChange={onChangeUTC}
      minDate={utcDateToMidnightLocal(minDate)}
      maxDate={utcDateToMidnightLocal(maxDate)}
      error={error}
    />
  )
}

export default UTCDatePicker
