// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import Raven from 'raven-js';
import { preloadBackgroundImage } from './Login/util/preloadBackgroundImage';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './_middleware/logging/sentry';
import AppWrapper from './App/AppWrapper.jsx';

document.title = 'Peachjar Internal Portal';

preloadBackgroundImage();

Raven.context(() => {
  const app = <AppWrapper />;
  ReactDOM.render(app, document.getElementById('root'));
});
