import gql from 'graphql-tag';

export default gql`
  mutation registerCustomSchoolOnboardingAgreement(
    $input: RegisterCustomSOAgreementInput
  ) {
    registerCustomSchoolOnboardingAgreement(input: $input) {
      id
    }
  }
`;
