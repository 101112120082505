import React, { useEffect } from 'react';
import config from '../config';

const CommunityFree = () => {
  useEffect(() => {
    window.location.href = `https://internal-bff.${config.BASE_DOMAIN}/approvals`;
  }, []);

  return null;
};

export default CommunityFree;
