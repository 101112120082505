import React, { useMemo } from "react"
import { css, cx } from "emotion"
import colors from "@peachjar/ui/dist/lib/styles/colors";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

type PaginationProps = {
  dataTestid?: string
  pageCount: number
  activePageNumber: number // starts with 1
  onPageChange: (pageNumber: number) => void
}

const ELLIPSIS = '…'

export default function Pagination({
  dataTestid,
  pageCount,
  activePageNumber,
  onPageChange
}: PaginationProps) {
  const pages = useMemo(() => {
    const result: Array<string | number> = [1]
    if (pageCount > 7 && activePageNumber > 4) result.push(ELLIPSIS)
    for (let i = 2; i <= pageCount - 1; i++) {
      if (activePageNumber <= 4 && i <= 5) {
        result.push(i)
      } else if (
        activePageNumber > 4 &&
        activePageNumber < pageCount - 3 &&
        Math.abs(i - activePageNumber) <= 1
      ) {
        result.push(i)
      } else if (
        activePageNumber >= pageCount - 3 &&
        i >= pageCount - 4
      ) {
        result.push(i)
      }
    }
    if (pageCount > 7 && activePageNumber < pageCount - 3)
      result.push(ELLIPSIS)
    if (pageCount > 1) result.push(pageCount)
    return result
  }, [activePageNumber, pageCount])

  function changeOffset(page: number | string) {
    if (typeof page === 'string') return
    if (page === activePageNumber) return
    onPageChange(page)
  }

  return (
      <div className={styles.container} data-testid={dataTestid}>
        {pages.length >= 1 && (
          <button
            className={styles.button}
            disabled={activePageNumber === 1}
            onClick={() => changeOffset(activePageNumber - 1)}
            data-testid="pagination-previous-page"
          >
            <ChevronLeft fontSize="small" />
          </button>
        )}
        {pages.map((page) => (
          <div key={page}>
            {page !== ELLIPSIS && (
              <button
                className={cx(styles.button, {[styles.active]: page === activePageNumber})}
                onClick={() => changeOffset(page)}
                data-testid={`pagination-page-${page} ${page === activePageNumber && `pagination-active-page`}`}
              >
                { page }
              </button>
            )}
            {page === ELLIPSIS && (
              <span className={styles.ellipsis}>
                { page }
              </span>
            )}
          </div>
        ))}
        {pages.length >= 1 && (
          <button
            className={styles.button}
            disabled={activePageNumber === pageCount}
            onClick={() => changeOffset(activePageNumber + 1)}
            data-testid="pagination-next-page"
          >
            <ChevronRight fontSize="small" />
          </button>
        )}
      </div>
  );
}

const styles = {
  container: css`
    display: flex;
    gap: 16px;
  `,
  button: css`
    all: unset;
    width: 24px;
    height: 24px;
    color: ${colors.jungle};
    font-size: 16px;
    display: flex;
    place-content: center;
    place-items: center;
    border-radius: 12px;
    box-sizing: border-box;
    
    &:hover:not(:disabled) {
      background-color: ${colors.silver};
      cursor: pointer;
    }
    
    &:disabled {
      color: ${colors.stone};
    }
  `,
  chevron: css`
  all: unset;
  width: 7.4px;
  color: ${colors.jungle};
  transform: scaleX(.5);
  font-size: 24px;
  `,
  active: css`
    border: 1px solid ${colors.stone};
  `,
  ellipsis: css`
    display: flex;
    place-content: center;
    place-items: center;
    width: 24px;
    height: 24px;
  `
}