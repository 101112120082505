import React, { Component } from 'react'
import { ButtonPrimaryLarge } from '@peachjar/ui/dist/lib/components/Buttons/Buttons'
import { components, elements } from '@peachjar/components'

const { H1, H2 } = elements.headers
const { WhitespaceColumn, FullSpread } = components

const ErrorFlyerMannagerScreen = () => (
  <FullSpread>
    <WhitespaceColumn full>
      <H1 h1style>Whoops!</H1>
      <H2 h3style>
        Something went wrong. Please make sure the flyer id in the url is
        correct.
      </H2>
      <ButtonPrimaryLarge onClick={() => window.location.reload()}>
        Try again
      </ButtonPrimaryLarge>
    </WhitespaceColumn>
  </FullSpread>
)

export default ErrorFlyerMannagerScreen
