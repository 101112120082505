import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControl, Input } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { components } from '@peachjar/components';

const {
  Buttons: { ButtonSecondarySmall },
} = components;

const SEARCH_FIELD_ID = 'pj-transactions-search';
const styles = (theme) => ({
  searchForm: {
    width: '40%',
  },
  searchIcon: {
    marginBottom: '24px',
  },
  searchButton: {
    marginBottom: '32px',
    marginLeft: '8px',
  },
  input: {
    display: 'none',
  },
  searchInput: {
    marginTop: '4px',
  },
});

class Search extends Component {
  state = {
    query: null,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    this.props.onSubmit && this.props.onSubmit(this.state.query);
  }

  handleChange(event) {
    const query = event.target.value;
    this.setState({ query });
    this.props.onChange && this.props.onChange(query);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <IconButton
          disableRipple
          onClick={(event) => {}}
          className={classes.searchIcon}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
        <FormControl className={classes.searchForm}>
          <div className={classes.searchInput}>
            <Input
              id={SEARCH_FIELD_ID}
              value={this.state.query || ''}
              onChange={this.handleChange}
              onKeyDown={(e) => {
                if (e.which === 13 || e.keyCode === 13) {
                  this.handleSubmit();
                }
              }}
              autoFocus
              fullWidth
              placeholder="Company Name or Email"
              inputProps={{
                datatestid: SEARCH_FIELD_ID,
                id: SEARCH_FIELD_ID,
              }}
            />
          </div>
        </FormControl>
        <ButtonSecondarySmall
          className={classes.searchButton}
          onClick={(event) => {
            this.handleSubmit(event);
          }}
        >
          Search
        </ButtonSecondarySmall>
      </div>
    );
  }
}
export default withStyles(styles)(Search);
