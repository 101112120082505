import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import { elements, link } from '@peachjar/components';
import { css } from 'emotion';
// import LastStatus from '../../all_approvals/components/LastStatus';
// import styles from './InfoPopover.styles';
// import popoverConstants from './InfoPopover.constants';
// import { Status } from '../../all_approvals/approvals.duck'

const { Headline2, Note } = elements.typography;

const noteStyles = `
    font-size: 0.9rem;
    line-height: 1.55;
`;

const labelStyles = `
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
`;

const styles = {
  icon: css`
    height: 1rem;
    cursor: pointer;
  `,
  wrapper: css`
    display: inline-block;
    margin-top: -10px;
  `,
  content: css`
    padding: 0;
    width: 310px;
  `,
  orgHeadline: css`
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 4px;
    word-break: break-all;
  `,
  orgLink: css`
    ${noteStyles};
    display: inline-block;
  `,
  note: css`
    ${noteStyles};
    ${labelStyles};
    display: block;
  `,
  section: css`
    margin: 16px 0 0 0;
    padding: 0 25px 0 25px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  `,
  label: css`
    ${noteStyles};
    display: inline-block;
  `,
  dashed: css`
    font-size: 0.8125rem;
    letter-spacing: 1px;
  `,
  iconRoot: css`
    line-height: 1;
    vertical-align: baseline !important;
  `,
  popoverText: css`
    color: #3a833c;
    font-size: 13px;
    cursor: pointer;
    line-height: 1.48;
    vertical-align: bottom;

    &:hover {
      color: #c5e8c9;

      #profileIcon {
        fill: #c5e8c9;
      }
    }

    svg {
      margin-right: 2px;
      height: 18px;
      width: 18px;
      display: inline-block;
      vertical-align: bottom;
    }
  `,
  kicker: css`
    padding: 16px 25px;
    border-bottom: 2px solid #ebebeb;
  `,
};

type Props = {
  className?: any,
  styleOverrides?: { [key: string]: any },
  originSettings?: {
    vertical: string,
    horizontal: string,
  },
  transformSettings?: {
    vertical: string,
    horizontal: string,
  },
  content: React.ReactNode,
  popoverContent: React.ReactNode,
};

type State = {
  anchorEl: null | React.ReactNode,
};

class InfoPopover extends React.Component<Props, State> {
  // set default props values for popover positioning
  static defaultProps = {
    styleOverrides: {
      top: '-4px',
    },
    originSettings: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformSettings: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  };

  state = {
    anchorEl: null,
  };

  setPopoverState = (event: any): void => {
    const { currentTarget } = event;
    const { anchorEl } = this.state;

    this.setState({ anchorEl: anchorEl ? null : currentTarget });
  };

  render() {
    const {
      className,
      originSettings,
      transformSettings,
      styleOverrides,
      content,
      popoverContent,
    } = this.props;
    const { anchorEl } = this.state;
    const isOpen = Boolean(anchorEl); // is popover currently open

    return (
      <div className={className} data-testid="link-info-popover">
        <div className="layout-row layout-align-center-center" onClick={this.setPopoverState}>
          {content}
        </div>

        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={this.setPopoverState}
          anchorOrigin={originSettings as any }
          transformOrigin={transformSettings as any}
          style={styleOverrides}
        >
          <div className={styles.content} data-testid="section-popover">
            {popoverContent}
          </div>
        </Popover>
      </div>
    );
  }
}

export default InfoPopover;
