import FlyerManagerContainer from './FlyerManagerContainer'
import generateSubappConfig from '../App/generateSubappConfig'
import ROUTES from '../routes.config'

const flyerManagerAppConfig = generateSubappConfig({
  path: ROUTES.flyerManager,
  isLive: true,
  navItemConfig: {
    label: 'Manage Flyer',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: FlyerManagerContainer,
  },
})

export default flyerManagerAppConfig
