import React from 'react'
import idx from 'idx'
import { connect } from 'react-redux'
import { css } from 'emotion'
import { components } from '@peachjar/components'
import { withRouter } from 'react-router-dom'
import { Grid, Typography, withStyles } from '@material-ui/core'
import config from '../config'
import SubappHeader from '../App/components/SubappHeader'

const { REACT_APP_AUTH_URL } = config

const IS_MASKED_KEY = 'pjim'

const {
  Buttons: { ButtonPrimarySmall },
  Notifications: { notifySuccess, notifyError },
} = components

const styles = (theme) => ({
  pageContainer: {
    padding: '32px 16px',
  },
  page: {
    background: 'white',
    width: '100%',
    minHeight: 500,
    marginTop: theme.spacing.unit * 3,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 5}px`,
  },
})

const MasqueradingAs = ({ classes, history, onSuccess, onError }) => {
  const uid =
    idx(history, (_) => _.location.state.uid) || localStorage.getItem('pjimuid')
  const unmaskMe = async () => {
    const response = await fetch(`${REACT_APP_AUTH_URL}/stop-masquerading`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      credentials: 'include',
    })

    const json = await response.json()

    if (json.isMasquerading === false) {
      localStorage.setItem(IS_MASKED_KEY, 'false')
      localStorage.removeItem('pjimuid')
      onSuccess()
      setTimeout(() => {
        history.push('/mask')
      }, 1000)
    } else {
      onError()
    }
  }

  return (
    <>
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12}>
          <SubappHeader className="pt-4 pb-4">Masquerade</SubappHeader>
        </Grid>
        <div className={classes.page}>
          <div className={cn.searchContainer}>
            Masquerading as <span className={cn.asText}>{uid}</span>
          </div>
          <div className={cn.unmaskCTA}>
            <ButtonPrimarySmall className={cn.maskCTA} onClick={unmaskMe}>
              Stop Masquerading
            </ButtonPrimarySmall>
          </div>
        </div>
      </Grid>
    </>
  )
}

const cn = {
  headline: css`
    font-size: 19px;
  `,
  searchContainer: css`
    padding-top: 20px;
    margin-bottom: 17px;
  `,
  noResults: css`
    text-align: center;
    padding-bottom: 60px;
  `,
  noResultsTxt: css`
    font-size: 16px;
    padding: 20px 350px;
  `,
  illustration: css`
    text-align: center;
    padding: 60px 0;
  `,
  asText: css`
    font-weight: bold;
  `,
  unmaskCTA: css``,
  maskCTA: css``,
}

const mapDispatchToProps = (dispatch) => ({
  onSuccess: () => dispatch(notifySuccess('You are no longer masquerading.')),
  onError: () =>
    dispatch(notifyError('Something went wrong. Please try again.')),
})

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(MasqueradingAs)))
