import React from 'react'
import FlyerManager from './FlyerManager'
import FlyerManagerErrorBoundary from './ErrorBoundary'

const FlyerManagerContainer = () => (
  <FlyerManagerErrorBoundary>
    <FlyerManager />
  </FlyerManagerErrorBoundary>
)

export default FlyerManagerContainer
