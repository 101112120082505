import React from 'react'
import UTCDatePicker from './UTCDatePicker'

const TargetDistributionDatePicker = ({
  targetDistributionDate,
  minDate,
  maxDate,
  onChange,
  error,
}) => (
  <>
    <UTCDatePicker
      name="targetDistributionDate"
      label="Distribution Target Date"
      value={targetDistributionDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      error={error}
    />
  </>
)

export default TargetDistributionDatePicker
