import gql from 'graphql-tag';

export default gql`
  query getFlyerStream(
    $limit: Int
    $offset: Int
    $searchText: String
    $districtIds: [String]
    $fromDate: Date
    $toDate: Date
  ) {
    getFlyerStream (
      input: {
        offset: $offset
        limit: $limit
        filter: {
          searchText: $searchText,
          districtIds: $districtIds,
          fromDate: $fromDate,
          toDate: $toDate
        }
      }
    ) {
      items {
        flyerId
        approvalDate
        audienceHiearchyId
        title
        ownerId
        ownerType
        ownerName
        pdfUrl
        pageCount
        coverJpegUrl
      }
      total
    }
  }
`;
