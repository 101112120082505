import MasqueradePage from './index'
import generateSubappConfig from '../App/generateSubappConfig'
import ROUTES from '../routes.config'

const masqueradeAppConfig = generateSubappConfig({
  path: ROUTES.mask,
  isLive: false,
  navItemConfig: {
    label: 'Masquerade',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: MasqueradePage,
  },
})

export default masqueradeAppConfig
