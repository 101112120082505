import gql from 'graphql-tag';

export const GET_PLATFORM_USERS = gql`
  query getPlatformUsers($input: FilterPlatformUsersInput) {
    platformUsers(input: $input) {
      limit
      offset
      total
      items {
        uid
        userId
        email
        oldEmails
        firstName
        lastName
        hierarchyType
        hierarchyId
        role
      }
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query searchOrganizations($searchTerm: String!, $limit: Int) {
    searchOrganizations(searchTerm: $searchTerm, limit: $limit) {
      items {
        orgId
        email
        name
        location {
          locality,
          state: administrativeAreaLevel1
        }
      }
    }
  }
`;

export const GET_FLYERS = gql`
query getFlyerStream(
  $input: FlyerStreamInput
  ) {
    getFlyerStream (
      input: $input
  ) {
    items {
      flyerId
      startDate
      audienceHiearchyId
      title
      ownerId
      ownerType
      ownerName      
    }
    total
  }
}
`;

export const GET_ACADEMIC_GROUPS = gql`
query searchAcademicGroups(
  $input: SearchAcademicGroupsInput
) {
  searchAcademicGroups (
    input: $input
  ) {
     limit,    
    items {
      id
      districtId
      schoolId      
      groupType
      parentId
      parentType
      parentName
      name
      location {        
        state: administrativeAreaLevel1
      }
    }    
  }
}
`;