import gql from 'graphql-tag';

export default gql`
    query getTargetTransactionGroups($input: GetTargetTransactionGroupsRequest!) {
        getTargetTransactionGroups(input: $input) {
            targetRefEntries {
                seqId
            }
        }
    }
`;
