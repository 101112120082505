import { injectGlobal } from 'emotion';
import moment from 'moment';
import config from '../../config';

const BACKGROUND_IMAGE_COUNT = 4; // see ./login-global.css
const { CDN_URL } = config;

const BACKGROUND_IMAGES = [
  // These live in s3 bucket peachjar-assets
  'internal-app/iStock-484321384.jpg',
  'internal-app/iStock-653085126.jpg',
  'internal-app/iStock-681714294.jpg',
  'internal-app/iStock-841791702.jpg',
];

export const preloadBackgroundImage = () => {
  const getDayModulo = moment().dayOfYear() % (BACKGROUND_IMAGE_COUNT - 1); // modulo operator is zero-based
  const imageIndex = getDayModulo + 1;
  const backgroundUrl = `${CDN_URL}/${BACKGROUND_IMAGES[imageIndex]}`;

  const unmountedTempImage = new Image();
  unmountedTempImage.src = backgroundUrl;

  injectGlobal`
    body.internal-login #root {
        background-image: url('${backgroundUrl}');
        background-size: cover;
        background-position: bottom left;
        overflow: hidden;
    }`;
};
