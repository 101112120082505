import * as React from 'react';
import { css } from 'emotion';
import { elements, colors } from '@peachjar/components';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

const { Paragraph } = elements.typography;

type Props = {
  totalCreditsStaged: number,
  classes: Record<string, any>,
};

const CreditsStagedSummary = ({ totalCreditsStaged, classes }: Props) => (
  <MuiTable className={classes.table} padding="none">
    <TableBody>
      <TableRow className={classes.row} style={{ border: 'none' }}>
        <TableCell className={styles.labelCell} colSpan={2}>
          <Paragraph className={styles.label}>
            Total Return Credit Amount
          </Paragraph>
        </TableCell>
        <TableCell className={styles.creditCell}>
          {totalCreditsStaged} c
        </TableCell>
        <TableCell colSpan={12} className={styles.spacerCell} />
      </TableRow>
    </TableBody>
  </MuiTable>
);

// @ts-ignore
export default withStyles(muiStyles)(CreditsStagedSummary);

const styles = {
  label: css`
    font-weight: bold;
    `,
    labelCell: css`
    padding: 0;
    border: none !important;
    width: 206px;
    @media screen and (min-width: 1410px) {
      width: 215px;
    }
    `,
    creditCell: css`
    padding: 0.5rem 2rem 0.5rem 0 !important;
    width: 70px;
    text-align: right !important;
    border: none !important;
    font-size: 1rem !important;
    white-space: nowrap;
  `,
  spacerCell: css`
    width: 660px;
    border: none !important;
  `,
};

const muiStyles = {
  table: {
    fontFamily: 'Proxima Nova',
    minWidth: 700,
  },
  row: {
    height: '64px',
  },
};
