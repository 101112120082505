import generateAppConfig from './generateAppConfig';

// Import your App's config file here
import schoolManagementConfig from '../SchoolManagement/config';
import communityFreeConfig from '../CommunityFree/config';
import transactionsAppConfig from '../Transactions/config';
import maskAppConfig from '../Masquerade/config';
import maskedAsAppConfig from '../Masquerade/maskedAsConfig';
import flyerManagerAppConfig from '../FlyerManager/config';
import nonStopFlyersAppConfig from '../NonStopFlyers/config';
import globalSearchAppConfig from '../GlobalSearch/config';

// Register new apps here
export const { apps, sidebarSections } = generateAppConfig([
  {
    sectionLabel: 'Main',
    apps: [
      globalSearchAppConfig,
      schoolManagementConfig,
      transactionsAppConfig,
      maskedAsAppConfig,
      maskAppConfig,
      communityFreeConfig,
      nonStopFlyersAppConfig,
      flyerManagerAppConfig,
    ],
  },
]);
