import { ClickAwayListener } from '@material-ui/core'
import { Add as AddIcon } from '@material-ui/icons'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css, cx } from "emotion"
import { reject } from "lodash"
import React, { useState } from "react"
import { Flyer } from '../../api/Flyer'
import FlyerCategoryTag, { FlyerCategoryCode, flyerCategoriesOptions } from "./FlyerCategoryTag"

type Props = {
  categorySet: Flyer['ownerHierarchyType']
  categories: FlyerCategoryCode[]
  setCategories: (categories: FlyerCategoryCode[]) => void
}

export default function FlyerTypeSelect({
  categorySet,
  categories,
  setCategories
}: Props) {
  const canAddCategories = categories.length < 2

  const [openOptions, toggleOpenOptions] = useState(false)
  const invalid = categories.length === 0

  const notSelectedOptions = reject(
    Object.keys(flyerCategoriesOptions[categorySet]) as FlyerCategoryCode[],
    code => categories.includes(code)
  )

  function onAdd(code: FlyerCategoryCode) {
    setCategories([...categories, code])
    if (categories.length === 1) {
      toggleOpenOptions(false)
    }
  }

  function onDelete(code: FlyerCategoryCode) {
    setCategories(categories.filter(t => t !== code))
  }

  return <>
    <div className={styles.root}>
      {categories.map(code => (
        <FlyerCategoryTag
          set={categorySet}
          code={code}
          onDelete={onDelete}
          key={code}
        />
      ))}
      {canAddCategories && <button
        className={cx(styles.add, invalid && styles.invalid)}
        onClick={() => toggleOpenOptions(open => !open)}
        data-testid="add-flyer-type"
      >
        <AddIcon fontSize="small" />
      </button>}
    </div>
    {invalid && (
      <div className={styles.errorMessage}>
        At least one type is required for your flyer
      </div>
    )}
    {openOptions && (
      <ClickAwayListener onClickAway={() => toggleOpenOptions(false)}>
        <div className={styles.options}>
        {notSelectedOptions.map(code => (
          <FlyerCategoryTag
            key={code}
            set={categorySet}
            code={code}
            onClick={onAdd}
          />
        ))}
        </div>
      </ClickAwayListener>
    )}
  </>
}

const styles = {
  root: css`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  `,
  add: css`
    all: unset;
    cursor: pointer;
    background-color: ${colors.white};
    border: 1px solid ${colors.prussian400};
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    box-sizing: border-box;
    color: ${colors.prussian};

    &:hover {
      background-color: ${colors.platinum};
    }
  `,
  invalid: css`
    border-color: ${colors.dragon};
  `,
  errorMessage: css`
    font-size: 12px;
    margin-block-start: 8px;
    color: ${colors.dragon};
  `,
  options: css`
    background-color: ${colors.platinum};
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 8px;
    margin-top: 8px;
  `
}
