import * as React from 'react';
import { css } from 'emotion';
import { components } from '@peachjar/components';

import ProfileDropdown from './ProfileDropdown';
import { REACT_APP_INTERNAL_APP_URL } from '../constants';
import StopMasquerading from './StopMasquerading'
const { Brandbar: PJBrandbar } = components;

const Brandbar = () => (
  <PJBrandbar logoURI={REACT_APP_INTERNAL_APP_URL}>
    <div className={classNames.brandbarContent}>
      <StopMasquerading/>
      <ProfileDropdown />
    </div>
  </PJBrandbar>
);

const classNames = {
  brandbarContent: css`
    flex: 1 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
  `,
};

export default Brandbar;
