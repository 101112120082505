import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { components } from '@peachjar/components';
import { errors } from './errors/errors.duck';
import { userReducer as user } from '../Login/user.duck';
import onboarding from '../SchoolOnboarding/reducer';

const { Notifications } = components;
const { notificationsReducer } = Notifications;

const rootReducer = combineReducers({
  routing: routerReducer,
  form: reduxFormReducer, // mounted under "form"
  errors,
  user,
  onboarding,
  notifications: notificationsReducer,
});

export default rootReducer;
