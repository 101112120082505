import * as React from 'react';
import { History } from 'react-router-dom';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from '@material-ui/core';
import { colors, elements } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import { format } from 'date-fns';
import FormattedTableCell from './FormattedTableCell';
import ActionTableCell from './ActionTableCell';
import { Transaction } from '../Management';
import DealTooltip from './DealTooltip';
import {
  TransactionHistoryHeaderAlignmentMap,
  TransactionHistoryColumnHeaders,
} from '../types/constants';

const { platinum } = colors;
const { Note } = elements.typography;

type Props = {
  columnHeaders: TransactionHistoryColumnHeaders,
  columnHeaderAlignmentMap: TransactionHistoryHeaderAlignmentMap,
  tableData: Transaction[],
  history: History,
  classes: Record<string, any>,
  setDeleteCreditsState: (deleteCreditState) => void,
  setRemoveCreditsState: (removeCreditState) => void,
  creditHolderInfo: Record<string, any>,
};

const Table = ({
  columnHeaders,
  columnHeaderAlignmentMap,
  tableData,
  history,
  classes,
  setDeleteCreditsState,
  setRemoveCreditsState,
  creditHolderInfo,
}: Props) => (
  <Paper elevation={0} style={{ border: '1px #e0e0e0 solid', width: '100%' }}>
    <MuiTable className={classes.table} padding="none">
      <TableHead>
        {columnHeaders.map((columnHeader) => (
          <TableCell
            className={classes.head}
            // @ts-ignore
            align={columnHeaderAlignmentMap[columnHeader]}
            style={{
              height: '64px',
              padding: '0.5rem 1rem',
              textAlign: columnHeaderAlignmentMap[columnHeader],
            }}
          >
            <Note muted>{columnHeader}</Note>
          </TableCell>
        ))}
      </TableHead>
      <TableBody>
        {tableData.map((transaction) => (
          <TableRow className={classes.row} key={transaction.id}>
            <FormattedTableCell
              value={transaction.id}
              width="70px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              value={formatTimestamp(transaction.timestamp)}
              width="120px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              value={formatType(transaction.type)}
              width="140px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />
            <FormattedTableCell
              value={transaction.details}
              hasStatusPill={displayStatusPill(
                transaction.type,
                transaction.extraInfo
              )}
              hasNote={displayNote(transaction.type, transaction.extraInfo)}
              type={transaction.type}
              typeInfo={transaction.extraInfo}
              width="330px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              align="right"
              value={formatAmountInCents(
                transaction.type,
                transaction.amountInCents
              )}
              width="50px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              align="right"
              value={formatAmountInCredits(transaction.amountInCredits)}
              width="50px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              align="right"
              value={transaction.creditBalance}
              width="50px"
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              align="right"
              width="50px"
              value={transaction.extraInfo.pos}
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <FormattedTableCell
              align="right"
              width="50px"
              value=""
              hasDeal={transaction.extraInfo.hasDeal}
              typeInfo={transaction.extraInfo}
              transaction={transaction}
              creditHolderInfo={creditHolderInfo}
            />

            <ActionTableCell
              setDeleteCreditsState={setDeleteCreditsState}
              setRemoveCreditsState={setRemoveCreditsState}
              align="right"
              width="180px"
              history={history}
              actions={transaction.actions}
              formatButtonText={formatType}
              transaction={transaction}
            />
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  </Paper>
);

const displayStatusPill = (type: string, typeInfo: any): boolean =>
  // Display status pill if listed types and status are provided
  (type === 'view-invoice' && !!typeInfo.status) ||
  (type === 'invoice-created' && !!typeInfo.status);

const displayNote = (type: string, typeInfo: any): boolean => !!typeInfo.note;

const formatAmountInCents = (type: string, cents: number): string | any => {
  // Only return amount if value is provided, is positive, or is not a credit return. Negative value indicates it was a credit usuage, and doesn't need to be displayed in the UI

  if (
    !cents ||
    (cents < 0 && !type.match(/refund/)) ||
    type === 'credits-returned' ||
    type === 'credits-transferred-in'
  ) {
    return <Dashed testid="cents" />;
  }
  const centsToDollars = Math.abs(cents) / 100;
  const formatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };
  const formattedDollars = Number(centsToDollars).toLocaleString(
    'en',
    formatOptions
  );

  return `$${formattedDollars}`;
};

const formatAmountInCredits = (credits: number): string | number => {
  if (credits < 0) {
    return credits;
  }
  if (credits > 0) {
    return `+${credits}`;
  }
  return credits;
};

const formatTimestamp = (timestamp: number): string =>
  format(timestamp, 'MMM d, yyyy');

const formatType = (type: string): string =>
  type
    .split('-')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ')
    .replace(/Created|In|Out/i, '')
    .replace(/Transferred/i, 'Transfer');

const styles = {
  table: {
    fontFamily: 'Proxima Nova',
    minWidth: 700,
  },
  row: {
    height: '64px',
    '&:nth-of-type(odd)': {
      backgroundColor: platinum,
    },
  },
  head: {
    backgroundColor: '#ffffff',
    position: 'sticky',
    top: 0,
    Index: 1,
  },
};

// @ts-ignore
export default withStyles(styles)(Table);
export {
  displayStatusPill,
  formatAmountInCents,
  formatAmountInCredits,
  formatTimestamp,
  formatType,
};
