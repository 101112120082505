import { CallToActionType } from "../api/Flyer";
import { validatePhoneNumber } from "./phoneNumber";
import validateEmailFormat from "./validateEmail";
import validateUrlFormat from "./validateUrlFormat";

export function validateCTA(type: CallToActionType, value: string) {
  if (type === "call" && !validatePhoneNumber(value)) {
    return "Please enter a valid phone number";
  } else if (type === "email" && !validateEmailFormat(value)) {
    return "Please enter a valid e-mail";
  } else if (type !== "call" && type !== "email" && !validateUrlFormat(value)) {
    return "Your URL should start with https:// or http://";
  }
}