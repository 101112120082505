import React, { useEffect, useState } from 'react';
import { Flyer } from '../../api/Flyer';
import Modal from "@peachjar/ui/dist/lib/components/Modal/Modal";
import ModalHeader from "@peachjar/ui/dist/lib/components/Modal/ModalHeader";
import { ButtonPrimaryLarge, ButtonSecondaryLarge } from "@peachjar/ui/dist/lib/components/Buttons/Buttons";
import colors from "@peachjar/ui/dist/lib/styles/colors";
import { css } from 'emotion';

type Props = {
  flyer: Flyer
  open: boolean
  onCancel: () => void
  onSave: (userText: string[]) => void
}

export default function TextVersionModal({
  flyer,
  open,
  onCancel,
  onSave
}: Props) {
  const [userTexts, setUserTexts] = useState<string[]>([]);

  useEffect(() => {
    setUserTexts(flyer.flyerPages.map(page => 
      page.userText !== null && page.userText !== undefined && page.userText.length > 0
      ? page.userText
      : page.ocrText))
  }, [flyer.flyerPages])
  
  function updateUserText(index: number, text: string) {
    setUserTexts(userTexts => {
      const newTexts = [...userTexts];
      newTexts[index] = text;
      return newTexts;
    });
  }

  function saveChanges() {
    onSave(userTexts);
  }

  function cancelChanges() {
    setUserTexts(flyer.flyerPages.map(page => 
      page.userText !== null && page.userText !== undefined && page.userText.length > 0
      ? page.userText
      : page.ocrText))
    onCancel();
  }

  return (
    <Modal
      open={open}
      close={cancelChanges}
      width="md"
    >
      <ModalHeader>
        {flyer.title}
      </ModalHeader>
      <div className={styles.container}>
        {flyer.flyerPages.map((page, index) => {
          return (
            <div key={page.pageNumber}>
              <div className={styles.pageRow}>
                <div id={`image${index}`} className={styles.image}>
                  <img src={page.jpegUrl} alt={`Page ${page.pageNumber}`} />
                </div>
                <textarea
                  className={styles.textField}
                  value={userTexts[index]}
                  onChange={e => updateUserText(index, e.target.value)}
                />
              </div>
              <div className={styles.pageCounter}>
                {page.pageNumber} of {flyer.flyerPages.length}
              </div>
            </div>
          );
        })}
        <div className={styles.controls}>
          <ButtonSecondaryLarge onClick={cancelChanges}>
            Cancel
          </ButtonSecondaryLarge>
          <ButtonPrimaryLarge onClick={saveChanges}>
            Save
          </ButtonPrimaryLarge>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  container: css`
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  `,
  pageRow: css`
    display: flex;
    gap: 24px;
  `,
  image: css`
    width: 66%;
    border: 1px dashed ${colors.stone};
    padding: 2px;

    & img {
      width: 100%;
    }
  `,
  textField: css`
    width: 33%;
    padding: 1.5rem;
    border: 1px solid ${colors.mercury};
    border-radius: 6px;
    resize: none;
    white-space: pre-line;
  `,
  pageCounter: css`
    font-size: 0.8125rem;
    color: ${colors.slate};
  `,
  controls: css`
    display: flex;
    justify-content: flex-end;
    gap: 24px;
  `
}