import React, { FunctionComponent } from 'react'
import get from 'lodash/get'
import { css } from 'emotion'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { styles as sharedStyles } from './BasicInfo'
import PostExpirationDatePicker from './PostExpirationDatePicker'
import TargetDistributionDatePicker from './TargetDistributionDatePicker'
import { Flyer } from '../../api/Flyer'
import { getPostExpirationDateRange } from '../utils/utils'
import localDateToMidnightUTC from '../../utils/localDateToMidnightUTC'

type Props = {
  flyer: Flyer
  onDateChange: (d: any) => void
  onTargetDateChange: (d: any) => void
}

const Distributions: FunctionComponent<Props> = ({ flyer, onDateChange, onTargetDateChange }) => {
  const distroTargetDate = get(flyer, 'startDate')
  const distributions = get(flyer, 'distributions')
  const campaignStarts = get(flyer, 'startDate')
  const postExpires = get(flyer, 'endDate')
  const distributeStatus = get(flyer, 'distributeStatus')
  const formattedTargetDate = moment(distroTargetDate).format('MMM D, YYYY')
  const { min: minDate, max: maxDate } = getPostExpirationDateRange(
    distributions,
    campaignStarts,
    localDateToMidnightUTC(new Date())
  )

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={styles.container}>
        <h4 className={sharedStyles.pageHeader}>Distribution</h4>
        <p className={styles.section}>
        {distributeStatus === 'released' && <>
          <p className={styles.sectionTitle}>Distribution Target Date</p>
          <p className={styles.sectionInfo}>{formattedTargetDate}</p> 
        </>}
        {distributeStatus === 'scheduled' && (
          <div className={styles.section} data-testid="target-distribution-date-picker">
            <TargetDistributionDatePicker
              targetDistributionDate={new Date(campaignStarts)}
              minDate={localDateToMidnightUTC(new Date())}
              maxDate={maxDate}
              onChange={onTargetDateChange}
              error={false}
            />
          </div>
        )}
        </p>
        <div className={styles.section}>
          <p className={styles.sectionTitle}>Distribution Rounds</p>
          <p className={styles.sectionInfo} data-testid="distributions-count">{distributions}</p>
        </div>
        <div className={styles.section} data-testid="post-expiration-date-picker">
          <PostExpirationDatePicker
            postExpirationDate={ postExpires === '' ? null : new Date(postExpires)}
            minDate={minDate}
            maxDate={maxDate}
            onChange={onDateChange}
            error={postExpires === '' ? true : false}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  )
}

const styles = {
  container: css`
    width: 71%;
  `,
  section: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  `,
  sectionTitle: css`
    font-size: 13px;
    margin-bottom: 5px;
  `,
  sectionInfo: css`
    font-size: 16px;
  `,
}

export default Distributions
