export const ROUTES = Object.freeze({
  schoolMgmt: '/mgmt/new',
  flyerManager: '/flyer/:id',
  mask: '/mask',
  transactions: '/transactions',
  commFree: '/approvals',
  maskedAs: '/mask/success',
  nonStopFlyers: '/nonstopflyers',
  search: '/search',
});

export default ROUTES;
