import CommunityFree from './CommunityFree';
import generateSubappConfig from '../App/generateSubappConfig';
import ROUTES from '../routes.config';

const communityFreeConfig = generateSubappConfig({
  path: ROUTES.commFree,
  isLive: true,
  navItemConfig: {
    label: 'Community Free',
  },
  appConfig: {
    Component: CommunityFree,
  },
});

export default communityFreeConfig;
