import React, { useState, useEffect } from 'react'
import idx from 'idx'
import { css } from 'emotion'
import { components, colors } from '@peachjar/components'
import { withRouter } from 'react-router-dom'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { isEmpty } from 'lodash'

import config from '../config'
import noResultsSVG from '../assets/turtle.svg'

import InfiniteScroll from 'react-infinite-scroller'
import getFlyerStream from './flyerStream.graphql'
import ApolloClient from 'apollo-boost'

import SearchBar from './SearchBar'
import moment from 'moment'
import 'font-awesome/css/font-awesome.min.css'

import Collapse from '@material-ui/core/Collapse'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import SubappHeader from '../App/components/SubappHeader'

import {
  Help as HelpIcon,
  School as SchoolIcon,
  People as PeopleIcon,
  Business as BusinessIcon,
} from '@material-ui/icons'

export const client = new ApolloClient({
  uri: config.REACT_APP_GRAPHQL_NEWBFF_URI,
  credentials: 'include',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

const LIMIT = 25

const {
  Buttons: { ButtonSecondarySmall },
} = components
const {
  LoadingIndicators: { SpinnerLarge },
} = components

const styles = (theme) => ({
  outerPageContainer: {
    paddingTop: '32px',
    paddingLeft: '16px',
    height: '90vh',
    minHeight: '90vh',
  },
  innerPageContainer: {
    marginTop: '16px',
    padding: '32px',
    backgroundColor: 'white',
  },
  illustration: {
    textAlign: 'center',
    padding: '60px 0',
  },
  noResultsTxt: {
    fontSize: '16px',
    padding: '20px 0',
    margin: '0 auto',
    maxWidth: '1320px',
    width: '100%',
  },
  noResultsContainer: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  flyerCardContainer: {
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '5px',
    maxWidth: '1450px',
    flexDirection: 'row',
  },
  flyerCard: {
    paddingTop: '200px',
    paddingBottom: '200px',
    border: '1px solid #dedede',
    margin: '20px',
  },
  cardContainer: {
    height: '70vh',
    overflow: 'auto',
  },
  flyerBox: {
    margin: '20px',
    width: '250px',
    maxWidth: '250px',
    display: 'flex',
    backgroundColor: '#fff',
    boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.1)',
    minWidth: '250px',
    overflow: 'hidden',
  },
  flyerImage: {
    width: '100%',
    borderBottom: '1px solid #dedede',
  },
  flyerTitle: {
    textAlign: 'left',
    marginBottom: '15px',
    color: '#fff',
    fontSize: '18px',
    fontWeight: '700',
  },
  flyerSpacer: {
    flexGrow: '1',
  },
  flyerInfo2: {
    padding: '15px',
    color: '#666666',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '250px',
    backgroundColor: 'rgba(0, 0, 0, .9)',
    boxSizing: 'border-box',
    marginLeft: '-250px',
    zIndex: '99',
  },
  flyerCover: {
    minWidth: '250px',
    cursor: 'pointer',
  },
  flyerLinkBox: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fiText: {
    fontSize: '13px',
  },
  fiField: {
    fontWeight: 'bold',
  },
  fiLink: {
    display: 'block',
  },
  fiLinka: {
    margin: '0',
    display: 'inline-flex',
    outline: 'none',
    position: 'relative',
    alignItems: 'center',
    userSelect: 'none',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none',
    minWidth: '64px',
    boxSizing: 'border-box',
    transition:
      'backgroundColor 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms,boxShadow 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubicBezier(0.4, 0, 0.2, 1) 0ms',
    fontWeight: '500',
    border: '1px solid #3a833c',
    padding: '0.5rem 1rem',
    fontSize: '0.8125rem',
    fontStyle: 'normal',
    boxShadow: 'none',
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
    lineHeight: '1.23',
    fontStretch: 'normal',
    borderRadius: '3px',
    textTransform: 'none',
    marginRight: '8px',
    cursor: 'pointer',
    textDecoration: 'none',
    backgroundColor: '#3a833c',
    color: '#fff !important',
  },
  bottomInfo: {
    color: '#ababab',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'uppercase',
    borderTop: '1px solid #dedede',
    marginTop: '15px',
    paddingTop: '15px',
  },
  biIcon: {
    flexGrow: '1',
    textAlign: 'right',
    paddingTop: '12px',
  },
  orgTypeIcon: {
    fontSize: '24px',
    marginTop: '-20px',
    marginRight: '3px',
  },
  flyerText: {},
  flyerTextTH: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '11px',
    textTransform: 'uppercase',
    color: '#dedede',
  },
  flyerTextTD: {
    fontSize: '14px',
    color: '#fff',
  },
  gapTH: {
    paddingTop: '8px',
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '11px',
    textTransform: 'uppercase',
    color: '#dedede',
  },
  brPopup: {
    marginLeft: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    height: '37px',
    width: '95px',
    padding: '5px',
    boxSizing: 'border-box',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    marginTop: '-48px',
    fontWeight: 'bold',
    display: 'block',
    textAlign: 'right',
    cursor: 'pointer',
    position: 'relative',
    clear: 'both',
    float: 'right',
  },
  brDate: {
    display: 'inline-block',
    marginLeft: '4px',
    textAlign: 'left',
  },
  brDateTop: {
    fontSize: '15px',
  },
  brDateBottom: {
    fontSize: '10px',
    marginTop: '-6px',
  },
  notFoundImage: {
    margin: 'auto',
  },
  spinnerContainer: {
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
})

const NonStopFlyersPage = ({ classes }) => {
  const [flyerCards, setFlyerCards] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const [searchString, setSearchString] = useState('')
  const [expandedId, setExpandedId] = useState(-1)

  const [fromDate, setFromDate] = useState('')

  const [searchVariables, setSearchVariables] = useState({
    limit: LIMIT,
    offset: 0,
  })

  const [getMoreFlyers] = useLazyQuery(getFlyerStream, {
    client: client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      let localFlyerCards = flyerCards

      if (data.getFlyerStream.total > 0) {
        data.getFlyerStream.items.map((flyer) => {
          localFlyerCards.push(flyer)
        })

        setFlyerCards(localFlyerCards)
        // setOffset(offset + limit)

        if (data.getFlyerStream.total < LIMIT) {
          setHasMore(false)
        }
      } else {
        setHasMore(false)
      }

      setLoading(false)
    },
    onError: (error) => {
      console.log('Error fetching flyer stream', error)
    },
  })

  // hanlde filter changing
  useEffect(() => {
    setLoading(true)
    doLoadMoreFlyers()
  }, [searchVariables])

  const loadMoreFlyers = (page) => {
    setLoading(true)

    let variables = searchVariables
    variables.offset = (page - 1) * LIMIT

    setSearchVariables({ ...searchVariables, offset: (page - 1) * LIMIT })
  }

  const doLoadMoreFlyers = () => {
    getMoreFlyers({ variables: searchVariables })
  }

  const searchForFilter = (filter) => {
    // clear existing flyers
    setFlyerCards([])
    setHasMore(true)

    let variables = {
      offset: 0,
      limit: LIMIT,
    }

    if (!isEmpty(filter.searchText)) {
      variables.searchText = filter.searchText
    }
    if (!isEmpty(filter.fromDate)) {
      variables.fromDate = filter.fromDate
    }
    if (!isEmpty(filter.toDate)) {
      variables.toDate = filter.toDate
    }

    setSearchVariables(variables)
  }

  const getIconForCustomerType = (type) => {
    switch (type) {
      case 'org-member':
        return <BusinessIcon title={type} className={classes.orgTypeIcon} />
        break
      case 'commFree':
      case 'commfree':
        return <PeopleIcon title={type} className={classes.orgTypeIcon} />
        break
      case 'district-staff':
      case 'school-staff':
      case 'volunteer':
        return <SchoolIcon title={type} className={classes.orgTypeIcon} />
        break
      default:
        return <HelpIcon title={type} className={classes.orgTypeIcon} />
        break
    }
  }

  const handleExpandClick = (e, i) => {
    e.stopPropagation()
    e.nativeEvent.stopImmediatePropagation()
    setExpandedId(expandedId === i ? -1 : i)
  }

  const renderFlyerCards = () => {
    let cards = []
    flyerCards.map((flyer) => {
      const fboardUrl = `https://app.${config.BASE_DOMAIN}/flyers/${flyer.flyerId}/districts/${flyer.audienceHiearchyId}`

      cards.push(
        <div key={flyer.flyerId} className={classes.flyerBox}>
          <div className={classes.flyerCover}>
            <img className={classes.flyerImage} src={flyer.coverJpegUrl} />
            <div
              onClick={(e) => handleExpandClick(e, flyer.flyerId)}
              className={classes.brPopup}
            >
              {getIconForCustomerType(flyer.ownerType)}
              <div className={classes.brDate}>
                <div className={classes.brDateTop}>
                  {moment(flyer.approvalDate).format('MMM D')}
                </div>
                <div className={classes.brDateBottom}>
                  {moment(flyer.approvalDate).format('h:mma')}
                </div>
              </div>
            </div>
          </div>
          <Slide
            className={classes.flyerInfo2}
            direction="left"
            in={expandedId === flyer.flyerId}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <div className={classes.flyerTitle}>{flyer.title}</div>
              <table>
                <tbody>
                  <tr>
                    <th className={classes.flyerTextTH}>Uploaded By</th>
                  </tr>
                  <tr>
                    <td className={classes.flyerTextTD}>
                      {flyer.ownerName} <br />
                      (ID: {flyer.ownerId})
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.gapTH}>Approved on</th>
                  </tr>
                  <tr>
                    <td className={classes.flyerTextTD}>
                      {moment(flyer.approvalDate).format('MMM D, h:mma')}
                    </td>
                  </tr>
                  <tr>
                    <th className={classes.gapTH}>Flyer ID</th>
                  </tr>
                  <tr>
                    <td className={classes.flyerTextTD}>{flyer.flyerId}</td>
                  </tr>
                </tbody>
              </table>
              <div className={classes.flyerSpacer}></div>
              <div className={classes.bottomInfo}>
                <div className={classes.flyerLinkBox}>
                  <div className={classes.fiLink}>
                    <a
                      className={classes.fiLinka}
                      target="_blank"
                      href={fboardUrl}
                    >
                      Flyerboard
                    </a>
                  </div>
                  <div className={classes.fiLink}>
                    <a
                      className={classes.fiLinka}
                      target="_blank"
                      href={flyer.pdfUrl}
                    >
                      PDF
                    </a>
                  </div>
                  <div className={classes.biIcon}>
                    {getIconForCustomerType(flyer.ownerType)}
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        </div>
      )
    })
    return cards
  }

  return (
    <>
      <Grid
        container
        className={classes.outerPageContainer}
        onClick={(e) => handleExpandClick(e, -1)}
      >
        <SubappHeader className="pt-4 pb-4">Non Stop Flyers</SubappHeader>
        <Grid container className={classes.innerPageContainer}>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={12}>
              <SearchBar
                searchString={searchString}
                onSearch={(filter) => searchForFilter(filter)}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} justify="center">
            <>
              <div className={classes.cardContainer}>
                {isLoading === true && flyerCards.length == 0 && (
                  <div className={classes.spinnerContainer}>
                    <SpinnerLarge />
                  </div>
                )}

                {isLoading === false && flyerCards.length === 0 && (
                  <div className={classes.flyerCardContainer}>
                    <div className={classes.noResultsContainer}>
                      <img
                        className={classes.notFoundImage}
                        src={noResultsSVG}
                        alt=""
                      />
                      <p className={classes.noResultsTxt}>
                        Uh oh! We can't find any flyers. Please try again.
                      </p>
                    </div>
                  </div>
                )}

                {flyerCards.length > 0 && (
                  <InfiniteScroll
                    pageStart={1}
                    loadMore={loadMoreFlyers}
                    hasMore={hasMore && !isLoading}
                    useWindow={false}
                  >
                    <div className={classes.flyerCardContainer}>
                      {renderFlyerCards()}
                    </div>
                    {isLoading && (
                      <div className={classes.spinnerContainer}>
                        <SpinnerLarge />
                      </div>
                    )}
                  </InfiniteScroll>
                )}
              </div>
            </>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(withRouter(NonStopFlyersPage))
