import React from 'react'
import UTCDatePicker from './UTCDatePicker'

const PostExpirationDatePicker = ({
  postExpirationDate,
  minDate,
  maxDate,
  onChange,
  error,
}) => (
  <>
    <UTCDatePicker
      name="postExpirationDate"
      label="Post Expiration Date"
      value={postExpirationDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      error={error}
    />
  </>
)

export default PostExpirationDatePicker
