import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, FormControl, Input } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { components } from '@peachjar/components';
import TextField from '@material-ui/core/TextField';

const {
  Buttons: { ButtonSecondarySmall, ButtonPrimarySmall }
} = components;

const styles = theme => ({
  searchForm: {
    width: "300px"
  },
  searchIcon: {
    marginBottom: "10px",
    marginTop: "14px",
  },
  searchButton: {
    marginBottom: "0px",
    marginLeft: "8px",
    marginTop: "18px",
  },
  input: {
    display: 'none',
  },
  searchInput: {
    marginTop: "4px"
  },
  searchBarContainer: {

  },
  searchItem: {
    paddingLeft: "5px",
    paddingRight: "5px",    
  },
  buttonItem: {

  }
});

class Search extends Component {
  
  constructor(props) {
    super(props);
    this.handleChange=this.handleChange.bind(this);
    this.performSearch=this.performSearch.bind(this);
    this.resetSearch=this.resetSearch.bind(this);

    this.state = {
      searchText: '',
      fromDate: '',
      toDate: ''
    }

  }

  performSearch() {
    this.props.onSearch && this.props.onSearch(this.state)
  };

  resetSearch() {
    const emptyState = {
      searchText: '',
      fromDate: '',
      toDate: ''
    }
    this.setState(emptyState)
    this.props.onSearch && this.props.onSearch(emptyState)
  }

  handleChange(prop, event) {
    let stateValue = {};
    stateValue[prop] = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      ...stateValue
    }))
  }

  render() {
    const { classes } = this.props;
    return (
      <div>

        <Grid container className={classes.searchBarContainer}>
          <Grid item xs={6} className={classes.searchItem}>
             <TextField
              id="searchText"
              value={this.state.searchText}
              onKeyDown={(e) => {
                if (e.which === 13 || e.keyCode === 13) {
                   this.performSearch();
                }
              }}
              onChange={(v) => this.handleChange('searchText', v)}
              label="Search for Flyers"
              fullWidth
              placeholder="Flyer title, Org name, ZIP code"
              variant="outlined"
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2} className={classes.searchItem}>
            <TextField
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              value={this.state.fromDate}
              onChange={(v) => this.handleChange('fromDate', v)}
              variant="outlined"
              margin="dense"
              placeholder=""
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={2} className={classes.searchItem}>
            <TextField
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              value={this.state.toDate}
              onChange={(v) => this.handleChange('toDate', v)}
              variant="outlined"
              margin="dense"
              placeholder=""
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={2} className={classes.buttonItem}>
            <ButtonPrimarySmall
              className={classes.searchButton}
              onClick={this.performSearch}
            >
              Search
            </ButtonPrimarySmall>
            <ButtonSecondarySmall
              className={classes.searchButton}
              onClick={this.resetSearch}
            >
              Reset
            </ButtonSecondarySmall>
          </Grid>

        </Grid>

        
      </div>
    );
  }
}
export default withStyles(styles)(Search);
