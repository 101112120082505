import * as React from 'react'
import { css, cx } from 'emotion'
import { elements } from '@peachjar/components'
import colors from '@peachjar/ui/dist/lib/styles/colors'

const { Headline1 } = elements.typography

type Props = {
  children: React.ReactNode
  className?: string
}

const SubappHeader = ({ children, className }: Props) => (
  <h1 className={cx(classNames.subappHeader, className)}>{children}</h1>
)

SubappHeader.defaultProps = { className: '' }

const classNames = {
  subappHeader: css`
    font-family: Proxima Nova;
    padding: 20px 0 35px 0;
    font-size: 34px;
    color: ${colors.prussian};
    margin: 0;
  `,
}

export default SubappHeader
