import { ButtonSecondarySmall } from '@peachjar/ui/dist/lib/components/Buttons'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css, cx } from 'emotion'
import { ChangeEvent, FunctionComponent, useRef, useState } from 'react'
import { Flyer } from '../../api/Flyer'
import InteractiveFlyerModal from './InteractiveFlyerModal'
import Pagination from './Pagination'
import TextVersionModal from './TextVersionModal'
import React from 'react'
import { SpinnerLarge } from '@peachjar/ui/dist/lib/components/Spinners/Spinners'

type Props = {
  flyer: Flyer
  handleFlyerUpload: (file: File) => void
  handleOcrUpdate: (ocrTextFields: string[]) => void
  uploading?: boolean
}

const FlyerPreview: FunctionComponent<Props> = ({
  flyer,
  handleFlyerUpload,
  handleOcrUpdate,
  uploading = false,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const [currentPageNumber, setActivePage] = useState(1)
  const [textVersionModalOpen, setTextVersionModalOpen] = useState(false)
  const [interactiveModalOpen, setInteractiveModalOpen] = useState(false)
  const pageCount = flyer.flyerPages.length
  const activePage = flyer.flyerPages[currentPageNumber - 1]

  function reupload(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target || !event.target.files || event.target.files.length === 0) return
    
    handleFlyerUpload(event.target.files[0])
    hiddenFileInput.current!.value = ''
  }

  function toggleTextVersionModal() {
    setTextVersionModalOpen(textVersionModalOpen => !textVersionModalOpen)
  }
  
  function toggleInteractiveModal() {
    setInteractiveModalOpen(interactiveModalOpen => !interactiveModalOpen)
  }

  return <div className={styles.container}>
    <div className={styles.preview}>
      <div className={cx(styles.box, { uploading })}>
        <img
          key={currentPageNumber}
          id={`flyer-page-${currentPageNumber}`}
          className={styles.image}
          src={activePage.jpegUrl}
          alt={`Flyer Page ${currentPageNumber}`}
        />
      </div>
      {!uploading && <>
        <Pagination
          activePageNumber={currentPageNumber}
          pageCount={pageCount}
          onPageChange={setActivePage}
        />
        <span
          className={styles.textVersion}
          onClick={toggleTextVersionModal}
        >
          Text Version
        </span>
        <div className={styles.actions}>
          <ButtonSecondarySmall
            data-testid="preview-flyer"
            onClick={() => hiddenFileInput.current && hiddenFileInput.current.click()}
          >
            Reupload Flyer
          </ButtonSecondarySmall>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={reupload}
            accept="application/pdf,image/jpeg,image/png"
            style={{ display: 'none' }}
          />
          {/* <ButtonSecondarySmall
            data-testid="preview-flyer"
            onClick={toggleInteractiveModal}
          >
            Preview Flyer
          </ButtonSecondarySmall> */}
        </div>
      </>}
      <TextVersionModal
        flyer={flyer}
        open={textVersionModalOpen}
        onCancel={toggleTextVersionModal}
        onSave={(userText: string[]) => {
          handleOcrUpdate(userText)
          toggleTextVersionModal()
        }}
      />
      <InteractiveFlyerModal
        flyer={flyer}
        open={interactiveModalOpen}
        onClose={toggleInteractiveModal}
      />
      {/* <FlyerPreviewModal
        open={textVersionModalOpen}
        flyer={flyer}
        stagedFlyer={stagedFlyer}
        loadingStagedFlyer={loadingStagedFlyer}
        handleStagingOfFlyer={handleStagingOfFlyer}
        handleFlyerUpload={handleFlyerUpload}
        handleOcrUpdate={handleOcrUpdate}
        unstageFlyer={unstageFlyer}
        closeModal={closePreview}
      /> */}
      {uploading && <SpinnerLarge className={styles.spinner} />}
    </div>
  </div>
}

const styles = {
  container: css`
    display: flex;
    justify-content: end;
  `,
  preview: css`
    margin-top: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    position: relative;
  `,
  spinner: css`
    position: absolute;
    top: 155px;
  `,
  box: css`
    border: 1px dashed ${colors.stone};
    padding: 2px;
    margin-block-end: 8px;

    &.uploading {
      opacity: 0.2;
    }
  `,
  image: css`
    display: block;
    height: 400px;
    aspect-ratio: 8.5 / 11;
  `,
  textVersion: css`
    color: #3a833c;
    cursor: pointer;
    text-decoration: underline;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
  `,
  actions: css`
    display: flex;
    flex-direction: row;
    gap: 12px;
  `,
}

export default FlyerPreview
