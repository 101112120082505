export const firstWord = sentence => sentence.substr(0, sentence.indexOf(' '));

export const camelCaseToCappedWords = (camelCase = '') => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const formatCamelCaseSentence = (sentence = '') =>
  sentence.replace(
    firstWord(sentence),
    camelCaseToCappedWords(firstWord(sentence))
  );