import axios from 'axios';
import { put, fork } from 'redux-saga/effects';
import {
    CHECK_AUTH_STATUS,
    AUTH_FAIL,
    AUTH_SUCCESS,
} from '../../Login/user.duck';
import { Session } from '../../api/Session';
import config from '../../config';

// TODO: this axios should not be here come on man
axios.defaults.baseURL = config.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

export const CHECK_AUTH = 'CHECK_AUTH';

export function* reallyCheckAuth() {
    yield put({ type: CHECK_AUTH_STATUS });
    try {
        const response = yield axios.get<Session>('/auth/session');

        const session = response.data as Session;
        const { identity } = session;
        if (identity.type !== 'internal') {
            
            if (identity.isMasquerading) {
                
                return yield put({ type: AUTH_SUCCESS, data: {
                    ...identity.actualIdentity,
                    masquerading: {
                        ...identity.identifiers,
                        hierarchyType: identity.metadata.hierarchyType,
                        legacyId: identity.legacyId,
                    }
                } });
            }

            return yield put({ type: AUTH_FAIL });
        }

        yield put({ type: AUTH_SUCCESS, data: response.data.identity });
    } catch (e) {
        yield put({ type: AUTH_FAIL });
    }
}

export function* checkAuth() {
    yield fork(reallyCheckAuth);
}
