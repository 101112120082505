import React from 'react';
import { css } from 'react-emotion';
import format from 'date-fns/format';
import { elements } from '@peachjar/components';

const {
  typography: { Label, Paragraph },
} = elements;

type Props = {
  flyerTitle: string,
  flyerId: any,
  transactionId: string,
  transactionDate: number,
  totalCreditsUsed: number,
};

const Header = ({
  flyerTitle,
  flyerId,
  transactionId,
  transactionDate,
  totalCreditsUsed,
}: Props) => {
  const formattedDate =
    transactionDate && format(new Date(transactionDate), 'PP');

  return (
    <>
      <div>
        <Label className={styles.label}>{flyerTitle}</Label>
      </div>
      <div>
        <div className={styles.headerTextWrapper}>
          <div>
            <Paragraph className={styles.headerText}>
              Transaction #{transactionId}
              <br />
              Transaction Date: {formattedDate}
            </Paragraph>
          </div>

          <div>
            <Paragraph className={styles.headerText}>
              Flyer #{flyerId}
              <br />
              Total Credits Used: {totalCreditsUsed}
            </Paragraph>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  headerTextWrapper: css`
    display: flex;
    justify-content: space-between;
    width: 60%;
    padding: 0 0 2rem 0;
  `,
  headerText: css`
    color: #7b7b7b;
  `,
  label: css`
    font-weight: bold;
  `,
};

export default Header;
