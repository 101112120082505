import React from 'react';
import { css } from 'react-emotion';
import { elements, components } from '@peachjar/components';
const { ButtonSecondarySmall } = components.Buttons;

const {
  typography: { Label, Paragraph },
} = elements;

type Props = {
  companyName: string,
  email: string,
  userId: string,
  currentCreditBalance: number,
  styles: Record<string, any>,
  setAddCreditsState: (addCreditState) => void,
};

const ReceiptHeader = ({
  companyName,
  email,
  userId,
  currentCreditBalance,
  setAddCreditsState,
}: Props) => {
  return (
    <>
      <div className={styles.headerFlexParent}>
        <div>
          <div>
            <Label className={styles.label}>{companyName}</Label>
          </div>
          <div>
            <div className={styles.headerTextWrapper}>
              <div>
                <Paragraph className={styles.headerText}>
                  Email Address: {email}
                </Paragraph>
                <Paragraph className={styles.headerText}>
                  User ID: {userId}
                </Paragraph>
                <Paragraph className={styles.headerText}>
                  Credit Balance: {currentCreditBalance}
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.addCreditsWrapper}>
          <ButtonSecondarySmall onClick={(e) => { setAddCreditsState({}); }}>Add Credits</ButtonSecondarySmall>
        </div>
      </div>
    </>
  );
};

const styles = {
  headerFlexParent: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `,
  addCreditsWrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
  headerTextWrapper: css`
    display: flex;
    justify-content: space-between;
    width: 70%;
    padding-bottom: 2rem;
  `,
  headerText: css`
    display: block;
    color: #7b7b7b;
  `,
  label: css`
    font-weight: bold;
  `,
};

export default ReceiptHeader;
