import * as React from 'react';
import { connect } from 'react-redux';
import { css } from 'emotion';
import { components } from '@peachjar/components';
import { Grid } from '@material-ui/core';

import LoginContainer from '../../Login/LoginContainer';
import { CHECK_AUTH } from '../../_middleware/authentication/checkAuth';

const { Spinner } = components.LoadingIndicators;

type ReduxStateProps = {
  isAuthenticated: boolean,
  isCheckingAuth: boolean,
};

type ReduxDispatchProps = {
  onCheckAuth: Function,
};

type OwnProps = {
  children: any,
};

type Props = ReduxStateProps & ReduxDispatchProps & OwnProps;

class AuthMe extends React.Component<Props> {
  componentDidMount() {
    const { onCheckAuth } = this.props;
    onCheckAuth();
  }

  render() {
    const { isAuthenticated, isCheckingAuth, children } = this.props;
    if (isAuthenticated) {
      return children;
    }

    if (isCheckingAuth) {
      return (
        <Grid container spacing={8} className={classNames.root}>
          <div className={classNames.spinnerWrapper}>
            <Spinner />
          </div>
        </Grid>
      );
    }

    return <LoginContainer />;
  }
}

const mapStateToProps = (state): ReduxStateProps => ({
  isAuthenticated: state.user.isAuthenticated,
  isCheckingAuth: state.user.isCheckingAuth,
});

const mapDispatchToProps = (dispatch): ReduxDispatchProps => ({
  onCheckAuth: () => dispatch({ type: CHECK_AUTH }),
});

const classNames = {
  root: css`
    flex: 1 1 auto;
    z-index: 1;
    overflow: hidden;
    position: relative;
    display: flex;
  `,
  spinnerWrapper: css`
    margin: 30% auto;
  `,
};

export default // $FlowFixMe
connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthMe);
