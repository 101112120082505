import * as React from 'react';
import { css } from 'emotion';
import { colors, components } from '@peachjar/components';
import SidebarNavItem from './SidebarNavItem';
import SidebarNavSection from './SidebarNavSection';
import { SIDEBAR_WIDTH } from '../../constants';
import { SidebarConfig } from '../../generateAppConfig';

const { VerticalNav } = components;

type Props = {
  sections: SidebarConfig,
};

const Sidebar = ({ sections }: Props) => (
  <div className={classNames.sidebar}>
    <VerticalNav label="Main Site Navigation">
      {sections.map(({ items, sectionLabel }) => (
        <SidebarNavSection label={sectionLabel} key={sectionLabel}>
          {/*
            ** TODO: permissions/roles **
            // if CustomItem NOT provided
                // render Sidebar.Item using path and label
            // if CustomItem is provided
                // if fragmentOnUser NOT provided
                    // render CustomItem
                // if fragmentOnUser is provided
                    // render CustomItem with fragment passed as "user" prop
                    // NOTE: compromise made in that user has to know to use the "user" prop?
                    // NOTE: must also handle putting fragment in query somewhere else
                    // NOTE: should use "filter" if not built in to Apollo yet
          */}
          {items
            .filter(item => item.sideNavEnabled === undefined || item.sideNavEnabled === true)
            .map(({ path, label: itemLabel }) => (
            <SidebarNavItem label={itemLabel} path={path} key={path} />
          ))}
        </SidebarNavSection>
      ))}
    </VerticalNav>
  </div>
);

const classNames = {
  sidebar: css`
    min-width: ${SIDEBAR_WIDTH};
    max-width: ${SIDEBAR_WIDTH};
    padding: 0.5rem;
    border-right: 1px solid ${colors.mercury};
  `,
};

export default Sidebar;
