import React, { useEffect } from 'react';
import config from '../config';

const SchoolManagement = () => {
  useEffect(() => {
    window.location.href = `https://internal-bff.${config.BASE_DOMAIN}/mgmt/new`;
  }, []);

  return null;
};

export default SchoolManagement;
