import React from 'react';
import { TableCell } from '@material-ui/core';
import { elements } from '@peachjar/components';
import Dashed from '@peachjar/ui/dist/lib/components/Dashed';
import StatusPill from './StatusPill';
import NoteTooltip from './NoteTooltip';
import DealTooltip from './DealTooltip';
import { Transaction } from "../Management";

// TODO: Add new colors to shared library
const PILL_COLORS = {
  mercury: '#e6e6e6',
  rose: '#ffc7c7',
  mint: '#d4e8d9',
  peach: '#ffe6aa',
};

const { Note } = elements.typography;

type Props = {
  value: string | number,
  width: string,
  align?: any,
  hasStatusPill?: boolean,
  hasNote?: boolean,
  hasDeal?: boolean,
  type?: string,
  typeInfo?: any,
  transaction: Transaction,
  creditHolderInfo: Record<string, any>
};

const FormattedTableCell = ({
  value,
  width,
  align,
  hasStatusPill,
  hasNote,
  hasDeal,
  type,
  typeInfo,
  transaction,
  creditHolderInfo
}: Props) => (
  <TableCell align={align} style={{ padding: '0.5rem 1rem', width }}>
    {hasStatusPill || hasNote || hasDeal ? (
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Note>{value}</Note>
        {hasNote && <NoteTooltip note={typeInfo.note} deals={typeInfo.deals} deltas={typeInfo.transactionDeltas!} transaction={transaction} creditHolderInfo={creditHolderInfo}/>}
        {hasDeal && <DealTooltip deals={typeInfo.deals} />}
        {hasStatusPill && generatePillStatus(typeInfo.status)}
      </div>
    ) : (
      <div>
        <Note>
          {value !== undefined && value !== null ? value : <Dashed testid="" />}
        </Note>
      </div>
    )}
  </TableCell>
);

const generatePillStatus = status => {
  let text;
  let color;

  if (status === 'Paid') {
    text = 'Paid';
    color = PILL_COLORS.mint;
  }

  if (status === 'Cancelled') {
    text = 'Cancelled';
    color = PILL_COLORS.mercury;
  }

  if (status === 'Overdue') {
    text = 'Overdue';
    color = PILL_COLORS.rose;
  }

  if (status === 'Outstanding') {
    text = 'Outstanding';
    color = PILL_COLORS.peach;
  }

  return <StatusPill pillText={text} pillColor={color} />;
};

export default FormattedTableCell;
export { generatePillStatus };
