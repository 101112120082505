import MaskedAsPage from './maskedAs';
import ROUTES from '../routes.config';
import generateSubappConfig from '../App/generateSubappConfig';

const maskedAsAppConfig = generateSubappConfig({
  path: ROUTES.maskedAs,
  isLive: true,
  navItemConfig: {
    label: 'Masquerade',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: MaskedAsPage,
  },
});

export default maskedAsAppConfig;
