/* global document */
import * as React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { styles } from './login.css.js';
import LoginCard from './LoginCard';

type ReduxStateProps = { isAuthenticated: boolean };
type OwnProps = {
  classes: { [key: string]: string },
};
type Props = ReduxStateProps & OwnProps;
class LoginContainer extends React.Component<Props> {
  componentDidMount() {
    document.body.classList.add('internal-login');
  }

  componentWillUnmount() {
    document.body.classList.remove('internal-login');
  }

  render() {
    const { classes, isAuthenticated } = this.props;
    !isAuthenticated
      ? document.body.classList.add('internal-login')
      : document.body.classList.remove('internal-login');
    return (
      <Grid container spacing={8} className={classes.root}>
        <div className={`${classes.notLoggedInWrapper} login-container`}>
          <Grid container justify="center" spacing={10}>
            <LoginCard />
          </Grid>
        </div>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
});

// @ts-ignore
export default connect(mapStateToProps)(withStyles(styles)(LoginContainer));
