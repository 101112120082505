import { injectGlobal } from 'emotion';
import { neutral } from '../../assets/colors';

injectGlobal`
  .ReactTable {
    margin-top: 16px;
  }
  .rt-thead,
  .rt-tbody{
    background:  ${neutral.white}
  }
  .rt-thead{
    color: grey;
    min-height: 64px;
  }
  .rt-tbody{
    border: 1px solid ${neutral.mercury}
  }
  .rt-thead .rt-th,
  .rt-thead .rt-td {
    border-right: none;
  }
  .rt-thead .rt-th:hover,
  .rt-thead .rt-td:hover {
    border-right: 1px solid rgba(0,0,0,0.05);
  }
  .rt-tbody .rt-td {
    text-transform: capitalize;
    border-right: none;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-left: 32px;
  }
  .rt-tr {
     cursor: pointer;
  }
  .rt-resizable-header-content {
      padding-top: 16px;
      font-size: 1.125rem;
      text-align: left;
  }
   .rt-tr {
     cursor: pointer;
  }
  .rt-thead .rt-th.-sort-asc,
  .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
    --webkit-box-shadow: none;
  }
  .rt-th.rt-resizable-header .rt-resizable-header-content {
        font-size: 13px;
  }
  .ReactTable .rt-thead .rt-resizable-header {
    padding-left: 32px;
    border: none;
  }
  .ReactTable .rt-tbody .rt-td {
    border: none;
  }
`;
