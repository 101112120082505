import React, { useState } from 'react';
import gql from 'graphql-tag';
import { components } from '@peachjar/components';
import MasqueradeSearch from './Search';
import bffClient from '../../_middleware/fetch/bffApolloClient';

const GET_PLATFORM_USERS = gql`
  query getPlatformUsers($input: FilterPlatformUsersInput) {
    platformUsers(input: $input) {
      limit
      offset
      total
      items {
        uid
        userId
        email
        firstName
        lastName
        hierarchyType
        hierarchyId
      }
    }
  }
`;

const { LoadingIndicators: { Spinner } } = components;

const MasqueradeSearchContainer = ({ onSearch: onSearchCB, setIsLoading, ...rest }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const onSearch = async () => {

    setIsLoading(true)

    const { loading, error, data } = await bffClient.query({
      query: GET_PLATFORM_USERS,
      variables: {
        input: {
          search: searchQuery,
        },
      },
    });

    if (loading) {
      return <Spinner />;
    }

    if (error) {
      throw new Error('unable to query for platform users.');
    }

    onSearchCB(data, loading);
  };

  const onInputChange = (e) => {
    const { target: { value } } = e;
    const searchString = value.replace(/\s+/g, ' ').trim();
    setSearchQuery(searchString);
  };

  const onInputKeyDown = (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      onSearch();
    }
  };

  const mergedProps = {
    placeholder: 'Username or Email Address',
    testId: 'masquerade-user',
    searchQuery,
    onSearch,
    onInputChange,
    onInputKeyDown,
    ...rest,
  };

  return <MasqueradeSearch {...mergedProps} />;
};

export default MasqueradeSearchContainer;
