import GlobalSearchPage from './index'
import generateSubappConfig from '../App/generateSubappConfig'
import ROUTES from '../routes.config'

const globalSearchAppConfig = generateSubappConfig({
  path: ROUTES.search,
  isLive: true,
  navItemConfig: {
    label: 'Search',
  },
  appConfig: {
    Component: GlobalSearchPage,
  },
})

export default globalSearchAppConfig
