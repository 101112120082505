export const ERROR_FATAL = 'ERROR_FATAL';

export const errors = (state = {}, action) => {
  switch (action.type) {
    case ERROR_FATAL:
      return {
        ...state,
        hasFatalError: true,
      };
    default:
      return state;
  }
};
