import AddressInput, {
  AddressAndPlaceId,
} from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import TextInput from '@peachjar/ui/dist/lib/components/Inputs/TextInputWrapper'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { css } from 'emotion'
import React from 'react'
import { Flyer } from '../../api/Flyer'
import FlyerTypeSelect from './FlyerCategorySelect'
import { FlyerCategoryCode } from './FlyerCategoryTag'

type Props = {
  flyerTitle: string
  flyerTitleError: string | null
  flyerAddress: AddressAndPlaceId | null
  textLimit: number
  handleFlyerTitleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleFlyerAddressChange: (address: AddressAndPlaceId | null) => void
  categorySet: Flyer['ownerHierarchyType']
  categories: FlyerCategoryCode[]
  setCategories: (categories: FlyerCategoryCode[]) => void
}

const BasicFlyerInfo: React.FunctionComponent<Props> = ({
  flyerTitle,
  flyerTitleError,
  flyerAddress,
  textLimit,
  handleFlyerTitleChange,
  handleFlyerAddressChange,
  categorySet,
  categories,
  setCategories
}) => {
  return (
    <>
      <div className={styles.container}>
        <h4 className={styles.pageHeader}>Flyer Details</h4>
        <div className={styles.section}>
          <TextInput
            label="Title"
            data-testid="input-flyer-title"
            onChange={handleFlyerTitleChange}
            textLimit={textLimit}
            value={flyerTitle}
            error={flyerTitleError}
          />
        </div>
        <div className={styles.section}>
          <FlyerTypeSelect
            categorySet={categorySet}
            categories={categories}
            setCategories={setCategories}
          />
        </div>
        <div className={styles.section}>
          <AddressInput
            label="Physical Address (optional)"
            value={flyerAddress}
            onChange={handleFlyerAddressChange}
            data-testid="input-flyer-address"
          />
        </div>
      </div>
    </>
  )
}

export const styles = {
  container: css`
    padding-top: 56px;
  `,
  pageHeader: css`
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    color: ${colors.prussian};
  `,
  section: css`
    margin-bottom: 20px;
  `,
}

export default BasicFlyerInfo
