import React, { useState, useEffect } from 'react';
import { useQuery as uq } from '@apollo/react-hooks';
import { debounce } from 'lodash';
import { css } from 'emotion';
import gql from 'graphql-tag';
import { components, TextInput, elements, colors } from '@peachjar/components';
import FormHelperText from '@material-ui/core/FormHelperText';
import bffApolloClient from '../../../_middleware/fetch/bffApolloClient';

const { Dropdown } = components;
const { Note } = elements.typography;

type Props = {
  reason: string,
  setReason: any,
  note: string,
  setNote: any,
  useQuery?: any,
  disabledButtonClick: boolean,
  toggleDisabledButtonClick: any,
  isSaveDisabled: boolean,
  refetch: number,
};

const ReturnReason = ({
  reason,
  setReason,
  note,
  setNote,
  useQuery = uq,
  disabledButtonClick,
  toggleDisabledButtonClick,
  isSaveDisabled,
  refetch,
}: Props) => {
  const [error, setError] = useState({ note: false, reason: false });
  // For text input performance
  const [localNote, setLocalNote] = useState('');
  const { loading, data } = useQuery(GET_RETURN_REASONS, {
    client: bffApolloClient,
  });

  let reasonOptions: any = [];

  if (data) {
    const { returnCreditReasons } = data;

    // Options for dropdown
    reasonOptions = returnCreditReasons.reasons.map(item => ({
      value: item.id,
      label: item.reason
        .split(' ')
        .map(i => i.charAt(0).toUpperCase() + i.slice(1))
        .join(' '),
    }));
  }

  useEffect(() => {
    // All this crap to handle the stupid validation when a user clicks the disabled save button
    if (disabledButtonClick) {
      setError({
        note: localNote.length > 200 || localNote.length === 0,
        reason: reason === '',
      });

      if (!isSaveDisabled) {
        setError({ note: false, reason: false });
      }
    }
  }, [disabledButtonClick, isSaveDisabled]);

  useEffect(() => {
    // Dropdown can only error when a user clicks the disabled save button. On reason prop updates, this will handle the error state accordingly
    if (disabledButtonClick) {
      setError({ ...error, reason: reason === '' });
    }
  }, [reason]);

  useEffect(() => {
    // When refetch prop updates, reset local values
    setError({ note: false, reason: false });
    setLocalNote('');
  }, [refetch]);

  // Handles performance issue, and experience issue, setting note value in parent onChange vs onBlur
  const debounceSetNote = value => debounce(() => setNote(value), 1000);

  useEffect(() => {
    // Prevents stupid error flash
    if (localNote.length) {
      setError({
        ...error,
        note: localNote.length > 200 || localNote.length === 0,
      });
    }

    // Updates note in parent for Save button validation
    debounceSetNote(localNote)();
  }, [localNote]);

  return loading ? (
    <React.Fragment />
  ) : (
    <div className={styles.wrapper}>
      <Dropdown
        label="Reason"
        width="100%"
        options={reasonOptions}
        value={reason}
        error={error.reason}
        onChange={event => setReason(event.target.value)}
      />
      {error.reason && (
        <FormHelperText>
          <Note className={styles.errorText}>{MISSED_FIELD_ERROR}</Note>
        </FormHelperText>
      )}

      <div className={styles.inputWrapper}>
        <TextInput
          label="Note"
          name="Note"
          fullWidth
          value={localNote}
          error={error.note}
          multiline
          onBlur={() => {
            setNote(localNote);
            setError({
              ...error,
              note: localNote.length > 200 || localNote.length === 0,
            });
          }}
          onChange={event => setLocalNote(event.target.value)}
        />
        <FormHelperText style={{ textAlign: 'right' }}>
          <div
            className={`layout-row layout-align-${
              error.note ? 'space-between' : 'end'
            }`}
          >
            {error.note && (
              <Note className={styles.errorText}>
                {localNote.length === 0 ? MISSED_FIELD_ERROR : MAX_NOTE_ERROR}
              </Note>
            )}
            <div
              className={error.note ? styles.errorText : ''}
            >{`${localNote.length}/200`}</div>
          </div>
        </FormHelperText>
      </div>
    </div>
  );
};

export default ReturnReason;

const MISSED_FIELD_ERROR = 'You missed this field.';
const MAX_NOTE_ERROR = 'Enter 200 characters or less.';

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-top: 2rem;
  `,
  inputWrapper: css`
    margin-top: 2rem;
  `,
  errorText: css`
    color: ${colors.dragon};
  `,
};

const GET_RETURN_REASONS = gql`
  query {
    returnCreditReasons {
      reasons {
        id
        reason
      }
    }
  }
`;
