import TransactionsPage from './index';
import generateSubappConfig from '../App/generateSubappConfig';
import ROUTES from '../routes.config';

const transactionsAppConfig = generateSubappConfig({
  path: ROUTES.transactions,
  isLive: true,
  navItemConfig: {
    label: 'Transactions',
    sideNavEnabled: false,
  },
  appConfig: {
    Component: TransactionsPage,
  },
});

export default transactionsAppConfig;
