import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import config from '../config';

import { LOGOUT } from '../Login/user.duck';

const API_BASE_URL = config.REACT_APP_API_BASE_URL;
const LOGOUT_URL = `${API_BASE_URL}/auth/logout`;

const Logout = ({ onLogout }) => {
  axios.get(LOGOUT_URL).then(onLogout);
  return <Redirect to="/" />;
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch({ type: LOGOUT }),
});

export default connect(
  null,
  mapDispatchToProps
)(Logout);
