import React from 'react'
import { css } from 'emotion'
import { Grid, Typography, withStyles } from '@material-ui/core'
import colors from '@peachjar/ui/dist/lib/styles/colors'

type Props = {
  title: string
  children: React.ReactNode
  classes: {
    [k: string]: any
  }
}

const DefaultPage: React.FunctionComponent<Props> = ({
  title,
  classes,
  children,
}) => (
  <>
    <Grid container className={classes.pageContainer}>
      <Grid item xs={12}>
        <h1 className={classes.pageHeader}>{title}</h1>
      </Grid>
      <div className={classes.page}>{children}</div>
    </Grid>
  </>
)

const styles = (theme) => ({
  pageContainer: {
    padding: '32px 16px',
  },
  pageHeader: {
    fontFamily: 'Proxima Nova, Arial, Helvetica, sans-serif',
    fontSize: '34px',
    color: colors.prussian,
  },
  page: {
    background: 'white',
    width: '100%',
    minHeight: 500,
    marginTop: theme.spacing.unit * 3,
    padding: '40px',
  },
  spinnerContainer: {
    marginTop: '100px',
    display: 'flex',
    justifyContent: 'center',
  },
})

// @ts-ignore
export default withStyles(styles)(DefaultPage)
