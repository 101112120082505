import React from 'react'
import get from 'lodash/get'
import { css, cx } from 'emotion'
import colors from '@peachjar/ui/dist/lib/styles/colors'
import { Flyer } from '../../api/Flyer'
import config from '../../config'

type Props = {
  flyer: Flyer
  flyerId: string
}

const { REACT_APP_PARENT_APP_URL, REACT_APP_API_BASE_URL } = config

const FlyerSummary: React.FunctionComponent<Props> = ({ flyer, flyerId }) => {
  const title = get(flyer, 'title')
  const campaignId = get(flyer, 'campaignId')
  const entityType = get(flyer, 'ownerHierarchyType')
  const uploader = get(flyer, 'ownerName', '')
  const uploaderType = get(flyer, 'ownerType', '')
  const uploaderId = get(flyer, 'ownerId')
  const entity = get(flyer, 'ownerHierarchyName')
  const entityId = get(flyer, 'ownerHierarchyId')
  const audienceId = get(flyer, 'audienceId')
  const distributionCount = get(flyer, 'distributionsSchoolCount')
  const entityIsLinkable =
    entityType.toLowerCase() === 'district' ||
    entityType.toLowerCase() === 'school'
  // todo: update with school id if school entity
  const entityUrl =
    entityIsLinkable && entityType.toLowerCase() === 'district'
      ? `${REACT_APP_API_BASE_URL}/mgmt/district/${entityId}`
      : `${REACT_APP_API_BASE_URL}/mgmt/district/${entityId}`

  const getFlyerboardUrl = () =>
    `${REACT_APP_PARENT_APP_URL}/flyers/all/schools/${audienceId}`

  const getFlyerPerformanceReportUrl = () =>
    `https://dashboard.peachjar.com/d/JNj4b9gGk/flyer-metrics-report?orgId=1&var-flyer_distributions=All&var-flyer_duration=&var-flyer_id=${flyerId}&var-flyer_image_id=&var-flyer_start=`

  const getFlyerDistributionReport = () =>
    `https://dashboard.peachjar.com/d/FZiVBbyGk/flyer-report?orgId=1&var-flyer_id=${flyerId}&var-campaign_id=${campaignId}`

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title} data-testid="flyer-title">{title}</h3>
          <span className={styles.id} data-testid="flyer-id">#{flyerId}</span>
        </div>
        <p className={styles.sectionItem}>
          Schools Distributed: <span data-testid="schools-distributed-count">{distributionCount}</span>
        </p>
        <p className={cx(styles.sectionItem, styles.sectionLink)}>
          <a href={getFlyerboardUrl()} target="_blank" data-testid="link-flyer-board">
            Flyer Board
          </a>
        </p>
        <p className={cx(styles.sectionItem, styles.sectionLink)}>
          <a href={getFlyerPerformanceReportUrl()} target="_blank" data-testid="link-flyer-performance-report">
            Flyer Performance Report
          </a>
        </p>
        <p className={cx(styles.sectionItem, styles.sectionLink)}>
          <a href={getFlyerDistributionReport()} target="_blank" data-testid="link-flyer-distribution-report">
            Flyer Distribution Report
          </a>
        </p>
      </div>

      <div className={cx(styles.section, styles.pushDown)}>
        <p className={styles.sectionItem}>
          Uploader: <span data-testid="flyer-uploader">{uploader}</span> <span data-testid="flyer-uploader-id">#{uploaderId}</span>
        </p>
        <p className={styles.sectionItem}>Uploader Type: <span data-testid="flyer-uploader-type">{uploaderType}</span></p>
        <p className={styles.sectionItem}>
          Entity:{' '}
          {entityIsLinkable ? (
            <>
              <a
                href={entityUrl}
                className={styles.sectionLink}
                target="_blank"
                data-testid="flyer-entity"
              >
                {entity}
              </a>{' '}
              <span data-testid="flyer-entity-id">#{entityId}</span>
            </>
          ) : (
            <>
              <span data-testid="flyer-entity">{entity}</span> <span data-testid="flyer-entity-id">#{entityId}</span>
            </>
          )}
        </p>
        <p className={styles.sectionItem}>Entity Type: <span data-testid="flyer-entity-type">{entityType}</span></p>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    background-color: ${colors.platinum};
    padding: 32px;
    display: flex;
    flex-direction: row;
  `,
  section: css`
    width: 50%;
    font-family: 'Proxima Nova', Arial, Helvetica, sans-serif;
  `,
  titleContainer: css``,
  title: css`
    font-size: 19px;
    font-weight: bold;
    color: ${colors.prussian};
    display: inline-block;
    padding-right: 16px;
  `,
  id: css`
    font-size: 16px;
    color: ${colors.slate};
  `,
  sectionItem: css`
    font-size: 16px;
    color: ${colors.prussian};
    margin-bottom: 5px;
  `,
  sectionLink: css`
    color: ${colors.jungle};

    &:hover {
      color: ${colors.jungle};
    }

    a,
    a:hover {
      color: ${colors.jungle};
    }
  `,
  pushDown: css`
    margin-top: 30px;
  `,
}

export default FlyerSummary
