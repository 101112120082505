import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Paper } from '@material-ui/core';
import gql from 'graphql-tag';
import { elements, colors, components } from '@peachjar/components';
import { useMutation as um } from '@apollo/react-hooks';
import { get } from 'lodash';
import bffApolloClient from '../../_middleware/fetch/bffApolloClient';
import SubappHeader from '../../App/components/SubappHeader';
import Header from './components/Header';
import Table from './components/Table';
import CreditsStagedSummary from './components/CreditsStagedSummary';
import ReturnReason from './components/ReturnReason';
import MiniBreadcrumb from '../../shared/components/MiniBreadcrumb';

const { Paragraph } = elements.typography;
const { ButtonPrimaryLarge } = components.Buttons;
const { notifyWarn } = components.Notifications;

type Props = {
  styles: { [key: string]: any },
  match: RouteComponentProps<UrlParam>,
  history: any,
  useMutation: any,
  displayNotification: any,
};

type State = {
  flyerId: string,
  flyerTitle: string,
  transactionId: string,
  transactionDate: number,
  totalCreditsUsed: number,
  flyerPurchaseRef: string,
  purchaserId: string,
  purchaserType: string,
  numberOfDistributions: number,
  totalSchools: number,
};

const Returns = ({
  styles,
  match,
  history,
  useMutation = um,
  displayNotification,
}: Props) => {
  // purchaseId from URL
  const {
    purchaseId: purchaseIdParam,
    creditHolderId,
    creditHolderType,
  } = match.params;

  const previousRouteUrl = `/transactions/${creditHolderId}/${creditHolderType}`;

  const [state, setState] = useState<State | any>({});
  const [tableData, setTableData] = useState<TableData | []>([]);
  const [totalCreditsStaged, setTotalCreditsStaged] = useState(0);
  const [stagedReturnItems, setStagedReturnItems] = useState([]);
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');
  const [disabledButtonClick, toggleDisabledButtonClick] = useState(false);
  // Refetch is a value to update to initiate updates and reset state
  const [refetch, toggleRefetch] = useState(0);
  const [showSpecialPricingWarning, setShowSpecialPricingWarning] = useState(
    false
  );
  const [updateReturns] = useMutation(UPDATE_RETURNS, {
    client: bffApolloClient,
    onCompleted: () => history.push(previousRouteUrl),
  });

  const getReturnsData = async (query1, query2) => {
    try {
      // Query getPurchaseOrder
      const result1 = await bffApolloClient.query({
        query: query1,
        fetchPolicy: 'network-only',
        variables: { purchaseId: purchaseIdParam },
      });

      const {
        data: { getPurchaseOrder },
      } = result1;

      // Query getAvailableReturnsForPurchase - use flyerPurchaseRef from getPurchaseOrder as purchaseId
      const result2 = await bffApolloClient.query({
        query: query2,
        fetchPolicy: 'network-only',
        variables: { purchaseId: getPurchaseOrder.flyerPurchaseRef },
      });

      const {
        data: { getAvailableReturnsForPurchase },
      } = result2;

      return { getPurchaseOrder, getAvailableReturnsForPurchase };
    } catch (err) {
      console.log('Error getting returns data', err);
    }
  };

  const setReturnsState = (data) => {
    const { getPurchaseOrder, getAvailableReturnsForPurchase } = data;
    const { flyerPurchaseRef, purchaserId, purchaserType } = getPurchaseOrder;
    // If creditPurchaseTransaction is null, use flyerPurchaseTransaction
    const id =
      get(getPurchaseOrder, 'creditPurchaseTransaction.id') ||
      get(getPurchaseOrder, 'flyerPurchaseTransaction.id');
    const timestamp =
      get(getPurchaseOrder, 'creditPurchaseTransaction.timestamp') ||
      get(getPurchaseOrder, 'flyerPurchaseTransaction.timestamp');
    const {
      title,
      legacyFlyerId,
      deliverySettings,
    } = getPurchaseOrder.campaign;

    const {
      targetAudiences,
      numberOfDistributions,
    } = getPurchaseOrder.campaign.deliverySettings;

    const { returnsByAudience } = getAvailableReturnsForPurchase;

    let returnsTableData: TableData;
    let readOnlyState: State;

    readOnlyState = {
      flyerId: legacyFlyerId,
      flyerTitle: title,
      transactionId: id,
      transactionDate: timestamp,
      totalCreditsUsed: returnsByAudience.reduce(
        (acc, val) =>
          acc +
          val.returnsByDistribution.reduce((a, v) => a + v.numberOfCredits, 0),
        0
      ),
      flyerPurchaseRef,
      purchaserId,
      purchaserType,
      numberOfDistributions,
      totalSchools: targetAudiences.length,
    };

    setState(readOnlyState);

    // Add isChecked boolean to returnsByDistribution to manage checkbox selection state
    const returnsData = returnsByAudience.map((item) => ({
      ...item,
      returnsByDistribution: item.returnsByDistribution.map((distribution) => {
        const { __typename, ...rest } = distribution; // Omit __typename
        return {
          ...rest,
          isChecked: false,
        };
      }),
    }));

    // Merge targetAudiences and returnsData/returnsByAudience by schoolId
    returnsTableData = targetAudiences.map((taItem) => {
      const schoolData = {
        ...returnsData.find((rdItem) => rdItem.schoolId === taItem.schoolId),
        ...taItem,
        districtName: taItem.district.name,
        schoolName: taItem.name,
        creditsStaged: 0,
      };

      const {
        name,
        ncesId,
        district,
        __typename,
        audienceType,
        ...schoolDataCleaned
      } = schoolData; // Omit unused schoolData properties

      return schoolDataCleaned;
    });

    // Ensure we display only schools row that are returnable. Some districts with special pricing allow you to pay for 1 school, and the rest are free.
    // @ts-ignore
    const returnsTableDataFiltered = returnsTableData.filter((tableRow) =>
      Object.keys(tableRow).includes('returnsByDistribution')
    );

    setShowSpecialPricingWarning(
      returnsTableDataFiltered.length !== targetAudiences.length
    );

    setTableData(returnsTableDataFiltered); // Merged schools/returns data stored in state
  };

  const handleCheckbox = ({ lineItem, columnIndex, setColumnChecked }) => {
    let tableDataUpdate;

    // @ts-ignore
    tableDataUpdate = tableData.map((row) => {
      const returnsByDistributionUpdate = row.returnsByDistribution.map(
        (dist, distIndex) => {
          // If columnIndex is provided, user clicked on a select all column checkbox
          if (distIndex === columnIndex && dist.canBeReturned) {
            return { ...dist, isChecked: setColumnChecked };
          }
          
          if (dist.lineItemId === lineItem) {
            const isCheckedUpdate = !dist.isChecked;
            return { ...dist, isChecked: isCheckedUpdate };
          }
          
          return dist;
        }
      );

      const creditsStagedUpdate = returnsByDistributionUpdate.reduce(
        (acc, dist) => acc + (dist.isChecked ? dist.numberOfCredits : 0),
        0
      );

      return {
        ...row,
        returnsByDistribution: returnsByDistributionUpdate,
        creditsStaged: creditsStagedUpdate,
      };
    });
    
    const totalStagedCredits = tableDataUpdate.reduce(
      (acc, val) => acc + val.creditsStaged,
      0
    );

    // Update state
    setTableData(tableDataUpdate);
    setTotalCreditsStaged(totalStagedCredits);
  };

  const resetState = () => {
    setNote('');
    setReason('');
    setTotalCreditsStaged(0);
    setStagedReturnItems([]);
    toggleDisabledButtonClick(false);
  };

  useEffect(() => {
    getReturnsData(GET_PURCHASE_ORDER, GET_AVAILABLE_RETURNS).then((result) =>
      setReturnsState(result)
    );
    // Reset state on refetch state changes
    resetState();
  }, [refetch]);

  useEffect(() => {
    let staged: any = [];
    // @ts-ignore
    const items = tableData.forEach((item) =>
      item.returnsByDistribution.forEach(
        (i) =>
          i.isChecked &&
          staged.push({
            lineItemId: i.lineItemId,
            numberOfCredits: i.numberOfCredits,
          })
      )
    );
    setStagedReturnItems(staged);
  }, [tableData]);

  useEffect(() => {
    if (showSpecialPricingWarning) {
      displayNotification();
    }
  }, [showSpecialPricingWarning]);

  const {
    flyerId,
    flyerTitle,
    transactionId,
    transactionDate,
    totalCreditsUsed,
    numberOfDistributions,
    totalSchools,
    purchaserId,
    purchaserType,
    flyerPurchaseRef,
  } = state;

  const mutationVariables = {
    purchaser: {
      id: purchaserId,
      type: purchaserType,
    },
    purchaseId: flyerPurchaseRef,
    reason,
    note,
    returnType: 'partial',
    returnItems: stagedReturnItems,
  };

  const isSaveDisabled =
    note === '' ||
    note.length > 200 ||
    reason === '' ||
    stagedReturnItems.length < 1;

  return tableData.length === 0 ? (
    <React.Fragment />
  ) : (
    <>
      <MiniBreadcrumb text="Transaction Management" linkTo={previousRouteUrl} />
      <div>
        <SubappHeader className="pt-4 pb-4">Return Credits</SubappHeader>
      </div>
      <Paper elevation={0} className={styles.paperMargin}>
        <div className={styles.subAppContainerDetailsVariant}>
          <Header
            flyerId={flyerId}
            flyerTitle={flyerTitle}
            transactionId={transactionId}
            transactionDate={transactionDate}
            totalCreditsUsed={totalCreditsUsed}
          />
          <Table
            tableData={tableData}
            numberOfDistributions={numberOfDistributions}
            totalSchools={totalSchools}
            handleCheckbox={handleCheckbox}
            disabledButtonClick={disabledButtonClick}
            stagedReturnItems={stagedReturnItems}
            refetch={refetch}
          />
          <CreditsStagedSummary totalCreditsStaged={totalCreditsStaged} />
          <ReturnReason
            reason={reason}
            setReason={setReason}
            note={note}
            setNote={setNote}
            disabledButtonClick={disabledButtonClick}
            toggleDisabledButtonClick={toggleDisabledButtonClick}
            isSaveDisabled={isSaveDisabled}
            refetch={refetch}
          />
          <div className={styles.saveWrapper}>
            <div
              className={styles.cancel}
              onClick={() => history.push(previousRouteUrl)}
            >
              <Paragraph style={{ color: colors.jungle }}>Cancel</Paragraph>
            </div>
            <div
              onClick={() => isSaveDisabled && toggleDisabledButtonClick(true)}
            >
              <ButtonPrimaryLarge
                disabled={isSaveDisabled}
                onClick={() =>
                  updateReturns({
                    variables: { input: mutationVariables },
                  }).then(() => toggleRefetch(refetch + 1))
                }
              >
                Save
              </ButtonPrimaryLarge>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  displayNotification: () => {
    dispatch(
      notifyWarn(
        'This flyer had a school pricing deal applied. Only schools with credits to return are listed below, schools with 0 credits used are not listed.'
      )
    );
  },
});

export default connect(null, mapDispatchToProps)(withRouter(Returns));

type SchoolReturnData = {
  type: string,
  sequence: number,
  lineItemId: number,
  numberOfCredits: number,
  canBeReturned: boolean,
  isChecked: boolean,
};

type TableData = {
  districtId: number,
  districtName: string,
  schoolId: number,
  schoolName: string,
  returnsByDistribution: SchoolReturnData[],
  creditsStaged: number,
  length: number, // wtf ?
};

type UrlParam = {
  purchaseId: string,
};

const GET_PURCHASE_ORDER = gql`
  query getPurchaseOrder($purchaseId: String!) {
    getPurchaseOrder(purchaseId: $purchaseId) {
      id
      flyerPurchaseRef
      purchaserId
      purchaserType
      flyerPurchaseTransaction {
        id
        timestamp
      }
      creditPurchaseTransaction {
        id
        timestamp
      }
      campaign {
        title
        legacyFlyerId
        deliverySettings {
          numberOfDistributions
          targetAudiences
        }
      }
    }
  }
`;

const GET_AVAILABLE_RETURNS = gql`
  query getAvailableReturns($purchaseId: String!) {
    getAvailableReturnsForPurchase(purchaseId: $purchaseId) {
      purchaseId
      distributionDetails {
        numberOfDistributions
        numberOfReminders
      }
      returnsByAudience {
        schoolId
        districtId
        audienceType
        returnsByDistribution {
          type
          sequence
          lineItemId
          numberOfCredits
          canBeReturned
        }
      }
    }
  }
`;

const UPDATE_RETURNS = gql`
  mutation updateReturns($input: ReturnCredits) {
    returnCredits(input: $input) {
      id
      numberOfCredits
      creditsReturned
    }
  }
`;
