import React from 'react';
import { css } from 'emotion';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { colors, components } from '@peachjar/components';
import SelectInput from '@peachjar/ui/dist/lib/components/Inputs/SelectInput'
import { SEARCH_TYPES } from '../utils/search-types';

const {
  Buttons: { ButtonSecondarySmall },
} = components;

const c = (description) => ({
  id: description,
  description,
})

const types = [
  c(SEARCH_TYPES.districtOrSchool),
  c(SEARCH_TYPES.organization),
  c(SEARCH_TYPES.flyer),
  c(SEARCH_TYPES.user),  
]

const Search = ({
  placeholder,
  testId,
  searchQuery,
  onSearch,
  onInputChange,
  onInputKeyDown,
  classes,
  inputType,
  onInputTypeChange,
  hasErrorInputType,
  hasErrorInputSearch,
  inputMaxLength = '128',
}) => (
    <div className={classes.root}>
    <IconButton
      data-testid={`click-search-icon-${testId}`}
      aria-label="Search"
      disableRipple
      color="primary"
      classes={{ colorPrimary: classes.overrides }}
    >
      <SearchIcon />
    </IconButton>    
    <div className={classes.selectInput}>
      <SelectInput
        picker={(type) => [type.description, type.id.toString()]}
        options={types}
        label="Type"
        value={inputType}
        onChange={onInputTypeChange}
        error={hasErrorInputType && "Enter a Type"}
      />
     </div>      
     <TextField
      id="search-input"      
      value={searchQuery}
      placeholder={placeholder}
      className={classes.cssUnderline}
      onChange={onInputChange}
      onKeyDown={onInputKeyDown}
      error={hasErrorInputSearch}
      helperText={hasErrorInputSearch && `Enter an ${placeholder}`}
      inputProps={{
        maxLength: inputMaxLength,
      }}
    />
    <div className={cn.wrapperSearchButton}>
        <ButtonSecondarySmall
          data-testid={`click-search-button-${testId}`}
          onClick={onSearch}
          className={cn.searchButton}
        >
          Search
        </ButtonSecondarySmall>
    </div>    
  </div>
);

const cn = {
  textField: css`
    &:after {
      border-bottom: 2px solid ${colors.prussian} !important;
    }
  `,
  searchButton: css`
    margin-left: 8px !important;
  `,  
  wrapperSearchButton: css`
    height: 36px !important;    
    padding-top: 20px;    
  `,
};

//material overrides
const styles = {
  root: {
    display: 'flex',    
    marginTop: '20px',
  },
  overrides: {
    color: colors.slate,
    padding: '7px',
    paddingTop: '25px',
    cursor: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  cssUnderline: {
    width: '344px',
    height: '36px',   
    marginTop: '20px',
    '&:after': {
      borderBottomColor: colors.prussian,
    },
  },
  selectInput: {
    width: '200px',
    paddingRight: '16px',
  },
  typeField: {
    minWidth: '150px',
    marginRight: '10px'
  },
};

export default withStyles(styles)(Search);
