import React from "react";
import { Mutation } from "react-apollo";
import DisableableSpinningSubmitButton from "./DisableableSpinningSubmitButton";
import bffClient from "../../_middleware/fetch/bffApolloClient";

const DisableableMutationButton = ({
  mutation,
  variables,
  handleError,
  handleSuccess,
  disabled,
  children,
}) => (
  <Mutation
    client={bffClient}
    mutation={mutation}
    variables={variables}
    onError={handleError}
    onCompleted={handleSuccess}
  >
    {(mutate, { data, loading, called, error }) => {
      const props = {
        children,
        disabled,
        error,
        handleClick: () => mutate(),
        loading,
      };

      return <DisableableSpinningSubmitButton {...props} />;
    }}
  </Mutation>
);

export default DisableableMutationButton;
