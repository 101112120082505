import React, { ReactElement } from 'react';
import { css } from 'emotion';

type Props = {
  children: ReactElement[],
};

const TwoColumns = ({ children }: Props) => (
  <div className={styles.container}>
    {children.map((comp, index) => (
      <div className={styles.column} key={`col-${index}`}>
        {comp}
      </div>
    ))}
  </div>
);

const styles = {
  container: css`
    width: 100%;
    display: flex;
  `,
  column: css`
    width: 50%;
  `,
};

export default TwoColumns;
