import React from 'react';
import { get } from 'lodash';
import { History } from 'react-router-dom';
import { TableCell, Tab } from '@material-ui/core';
import { components } from '@peachjar/components';
import { Link } from 'react-router-dom';
import { Transaction } from '../Management';
import { Action } from '../types/Actions';
import ActionSplitButton from './ActionSplitButton';
import { ACTION_BUTTON_MAP } from '../constants';
import { getActionId } from '../utils';

const { ButtonSecondarySmall } = components.Buttons;

type PurchaseAction = {
  type: string,
  details: { purchaseId: string },
};

type InvoiceAction = {
  type: string,
  details: { invoiceId: string },
};
type RemoveCreditsButtonActionProps = {
  buttonText: string,
  setRemoveCreditsState: (removeCreditsState: any) => void,
  transaction: Transaction,
};

const RemoveCreditsButtonAction = ({
  buttonText,
  setRemoveCreditsState,
  transaction,
}: RemoveCreditsButtonActionProps) => {
  const action = transaction.actions.find((a) => a.type === 'remove-credits');
  return (
    <ButtonSecondarySmall
      onClick={e => {
        setRemoveCreditsState({
          transaction,
          action
        });
      }}
    >
      {buttonText}
    </ButtonSecondarySmall>
  );
};

type IssueRefundButtonActionProps = {
  buttonText: string,
  setDeleteCreditsState: (deleteCreditsState: any) => void,
  transaction: Transaction,
};

const IssueRefundButtonAction = ({
  buttonText,
  setDeleteCreditsState,
  transaction,
}: IssueRefundButtonActionProps) => {
  const action = transaction.actions.find((a) => a.type === 'refund-purchase');
  return (
    <ButtonSecondarySmall
      onClick={e => {
        setDeleteCreditsState({
          transaction,
          action
        });
      }}
    >
      {buttonText}
    </ButtonSecondarySmall>
  );
};

type LinkActionProps = {
  purchaseId: string,
  actionPath: string,
  buttonText: string,
};

const LinkAction = ({
  purchaseId,
  actionPath,
  buttonText,
}: LinkActionProps) => {
  return (
    <Link
      to={`/account/transactions/${purchaseId}/${actionPath}`}
      style={{ textDecoration: 'none' }}
    >
      <ButtonSecondarySmall>{buttonText}</ButtonSecondarySmall>
    </Link>
  );
};

type Props = {
  actions: Action[],
  width?: string,
  align?: 'left' | 'right' | 'center',
  formatButtonText: (type: string) => string,
  transaction: Transaction,
  setDeleteCreditsState: (deleteCreditsState) => void,
  setRemoveCreditsState: (removeCreditsState) => void,
  history: History,
};

const ActionTableCell = ({
  actions,
  history,
  width,
  align,
  formatButtonText,
  transaction,
  setDeleteCreditsState,
  setRemoveCreditsState,
}: Props) => {
  /* ****************************************************************************************
   * Temporary approach to action buttons until we need the multi-action split button.      *
   * There are only (2) possible actions you can take -- Return Credits and Remove Credits. *
   **************************************************************************************** */

  if (actions.length) {
    // Return Credits action object
    const returnCreditsAction = actions.find(
      action => action.type === 'return-credits'
    );
    // Remove Credits action object
    const removeCreditsAction = actions.find(
      action => action.type === 'remove-credits'
    );
    // Remove Credits action object
    const issueRefundAction = actions.find(
      action => action.type === 'refund-purchase'
    ) && (transaction.extraInfo.pos.toLowerCase() === 'stripe' || 
    transaction.extraInfo.pos.toLowerCase() === 'paypal');

    // purchaseDetailsAction is only used to get deals purchase id right now. The Credits Returns UI needs the DEALS purchaseId to call getPurchaseOrder query for Campaigns information. In the future, the Returns Credits action object will have the DEALS id and we'll have a View Details UI created.
    const purchaseDetailsAction = actions.find(
      action => action.type === 'view-flyer-purchase-details'
    );

    // Render Return Credits button
    if (!!returnCreditsAction && !!purchaseDetailsAction) {
      // @ts-ignore
      const purchaseId = purchaseDetailsAction.details.purchaseId;
      return (
        <TableCell style={{ padding: '1rem', width }}>
          <Link
            to={`${history.location.pathname}/${purchaseId}/returns`}
            style={{ textDecoration: 'none' }}
          >
            <ButtonSecondarySmall>Return Credits</ButtonSecondarySmall>
          </Link>
        </TableCell>
      );
    }
    // Render Remove Credits button
    if (!!removeCreditsAction) {
      const buttonText = formatButtonText(removeCreditsAction.type);
      return (
        <TableCell style={{ padding: '1rem', width }}>
          <RemoveCreditsButtonAction
            transaction={transaction}
            setRemoveCreditsState={setRemoveCreditsState}
            buttonText={buttonText}
          />
        </TableCell>
      );
    }

    // Render Refund Credits button
    if (!!issueRefundAction) {
      // const buttonText = formatButtonText(issueRefundAction.type);
      const buttonText = 'Issue Refund';
      return (
        <TableCell style={{ padding: '1rem', width }}>
          <IssueRefundButtonAction
            transaction={transaction}
            setDeleteCreditsState={setDeleteCreditsState}
            buttonText={buttonText}
          />
        </TableCell>
      );
    }
  }
  return <TableCell style={{ padding: '1rem', width }} />;
};

export default ActionTableCell;
